<template>
  <v-card flat rounded elevation="0" class="pa-6 ma-3" color="grey lighten-5" :loading="loading">
    <v-form v-model="valid" lazy-validation ref="form">
      <v-card-title>
        <v-col cols="12">
          <v-row align="center" justify="center">
            <h2 class="h6 font-weight-bold grey--text ligthten-2">
              Crear nuevo curso
            </h2>
          </v-row>
        </v-col>
      </v-card-title>

      <v-card-text>
        <v-col cols="12">
          <v-row align="center" justify="center" class="flex-md-nowrap">
            <v-col cols="12" md="3">
              <span class="pa-0 ma-0">{{$t('students.grade')}}</span>
            </v-col>
            <v-select
              :placeholder="$t('common.to_select')"
              solo
              flat
              outlined
              dense
              color="white"
              :items="grades"
              v-model="grade"
              item-value="grade_id"
              item-text="name"
              light
              class="pa-0 ma-0 ml-3"
              hide-details="auto"
              :rules="fieldRequired"
            />
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-row align="center" justify="center" class="pt-5 flex-md-nowrap">
            <v-col cols="12" md="3">
              <span class="pa-0 ma-0">{{$t('students.section')}}</span>
            </v-col>
            <v-select
              :placeholder="$t('common.to_select')"
              solo
              flat
              dense
              outlined
              color="white"
              :items="sections"
              v-model="section"
              item-value="key"
              item-text="value"
              light
              class="pa-0 ma-0 ml-3"
              hide-details="auto"
              :rules="sectionRequired"
            />
          </v-row>
        </v-col>

        <v-row align="center" justify="center" class="mt-9">
          <v-btn
            text
            small
            color="deep-purple lighten-2"
            @click.prevent="onCancel()"
          >
            {{$t('common.cancel')}}

          </v-btn>
          <v-btn
            rounded
            outlined
            small
            color="deep-purple lighten-2"
            @click.prevent="onSave()"
          >
            {{$t('common.to_save')}}
          </v-btn>
        </v-row>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
import LoadingMixin from '../../../mixins/loading';
import courseSectionMixin from '../../../mixins/grade&Course';
import SnackbarMixin from '../../../mixins/snackbar';

import courseService from '../../../services/course.service';

export default {
  name: 'CreateNewCourseModal',
  props: {
    school: { type: [String, Number], default: '' },
    countryId: { type: [String, Number], default: '' },
  },
  data: () => ({
    grade: '',
    section: '',
    valid: true,
  }),
  methods: {
    onCancel() {
      this.$emit('onCancel');
    },
    async onSave() {
      try {
        if (this.$refs.form.validate()) {
          this.loader();
          const { school, section, grade } = this;
          const { data, status } = await courseService.create({
            school,
            section,
            grade,
          });
          console.log(data);
          this.loader();
          this.$emit('onSave', status === 200);
        }
      } catch (e) {
        this.snackbarError(e.message);
      }
    },
  },
  computed: {
    fieldRequired() {
      return [(v) => !!v || this.$t('common.field_required')];
    },
    sectionRequired() {
      return [(v) => v !== null || this.$t('common.field_required')];
    },
  },
  mixins: [LoadingMixin, courseSectionMixin, SnackbarMixin],
};
</script>
