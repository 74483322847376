<template>
  <v-footer app outlined>
    <v-row justify="center" align="center" class="px-0 py-1 ma-0">
      {{$t('footer_cs_love')}}
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: 'Footer',
};

</script>
