<template>
  <v-form lazy-validation v-model="valid" ref="form">
    <v-row>
      <v-col cols="12" md="9">
        <v-card class="d-flex flex-column white rounded pa-9 d-flex items-center justify-start"
                :loading="loading">
          <v-row align="center" justify="space-between">
            <v-col cols="12" md="10" class="d-flex align-items-start">
              <v-card-title class="secondary--text font-weight-bold">
                {{$t('teachers.general_information')}}
              </v-card-title>
            </v-col>
            <v-col cols="12" md="2" class="d-flex align-items-start justify-content-end">
                <v-btn fab small color="deep-purple lighten-1" class="ml-auto" dark
                       :disabled="loading"
                       @click="update()">
                  <v-icon>
                    mdi-content-save
                  </v-icon>
                </v-btn>
            </v-col>
          </v-row>
          <v-col cols="12">
            <v-row align="center" justify="center" class="mb-1">
              <v-col>
                <span>ID: </span>
                <span class="deep-purple--text font-weight-bold">{{teacherId}}</span>
              </v-col>
              <v-col>
                <span>{{$t('students.creation_date')}}: </span>
                <span
                  class="deep-purple--text font-weight-bold">{{created |  moment('MM/D/YYYY')}}</span>
              </v-col>
              <v-col>
                <span>{{$t('students.last_access')}}: </span>
                <span class="deep-purple--text font-weight-bold d-block">
                  {{lastAccessDate || $t('students.without_last_access')}}
                </span>
              </v-col>
            </v-row>
            <v-divider/>
          </v-col>

          <!-- Editable data -->
          <!-- Personal information -->
          <v-row align="center" justify="center">
            <v-col cols="12" md="5">

              <v-row align="start" justify="start" class="d-flex flex-column">
                <span class="body black--text my-1">{{$t('students.country')}}</span>
                <v-autocomplete :items="countries" item-value="id"
                                item-text="name" filled flat dense
                                :placeholder="$t('students.country')"
                                v-model="userToSave.country" :rules="requiredRule('country')">
                  <template v-slot:selection="{ item }">
                    <img
                      :src="`${flagsPath}${item.iso_code}.png`"
                      alt="country flag"
                      width="35vh"
                      height="25vh"
                      class="mr-2"
                    />
                    <span class="">{{ item.name }}</span>
                  </template>
                  <template v-slot:item="{ item }">
                    <template>
                      <img
                        :src="`${flagsPath}${item.iso_code}.png`"
                        alt="country flag"
                        width="35vh"
                        height="25vh"
                        class="mr-2"
                      />
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="capitalize(item.name)"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-row>
              <v-row align="start" justify="start" class="d-flex flex-column mt-3">
                <span class="body black--text my-1">{{ $t('students.dni') }}</span>
                <v-text-field
                  filled
                  flat
                  dense
                  :placeholder="$t('students.dni')"
                  :rules="dniRule"
                  v-model="userToSave.dni"
                />
              </v-row>
              <v-row align="start" justify="start" class="d-flex flex-column">
                <span class="body black--text my-1">{{$t('teachers.name')}}</span>
                <v-text-field
                  filled
                  flat
                  dense
                  :placeholder="$t('teachers.name')"
                  :rules="requiredRule('firstname')"
                  v-model="userToSave.firstname"
                />
              </v-row>
              <v-row align="start" justify="start" class="d-flex flex-column">
                <span
                  class="body black--text my-1">{{$t('students.student_parent_lastname')}}</span>
                <v-text-field
                  dense
                  :placeholder="$t('students.student_parent_lastname')"
                  filled
                  flat
                  v-model="userToSave.lastname"
                  :rules="requiredRule('lastname')"
                />
              </v-row>
              <v-row align="start" justify="start" class="d-flex flex-column">
                <span
                  class="body black--text my-1">{{$t('students.student_mother_lastname')}}</span>
                <v-text-field
                  dense
                  :placeholder="$t('students.student_mother_lastname')"
                  filled
                  flat
                  v-model="userToSave.lastname2nd"
                />
              </v-row>

              <v-row align="start" justify="start" class="d-flex flex-column">
                <span
                  class="body black--text my-1">{{$t('students.email')}}</span>
                <v-text-field
                  dense
                  :placeholder="$t('students.email')"
                  filled
                  flat
                  v-model="userToSave.email"
                />
              </v-row>
              <v-row align="start" justify="start" class="d-flex flex-column">
                <span class="body black--text my-1">{{$t('students.birthdate')}}</span>
                <!-- DATE PICKER -->
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="userToSave.birthDate"
                      append-icon="mdi-calendar"
                      :placeholder="$t('students.birthdate')"
                      readonly
                      filled
                      dense
                      flat
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="userToSave.birthDate"
                    :active-picker.sync="activePicker"
                    :max="
                                      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                                        .toISOString()
                                        .substr(0, 10)
                                    "
                    min="1990-01-01"
                    :locale="$i18n.locale"
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-row>

            </v-col>
            <v-divider vertical class="mx-1"/>

            <!-- College information -->
            <v-col cols="12" md="6" style="margin-top:-280px">
              <v-row align="center" justify="center" class="d-flex flex-column">
                <span class="black--text">{{$t('students.state')}}</span>
                <v-radio-group v-model="userToSave.active" row>
                  <v-radio
                    :label="$t('students.active')"
                    :value="true"
                    color="deep-purple lighten-1"
                  />
                  <v-radio
                    :label="$t('students.inactive')"
                    :value="false"
                    color="deep-purple lighten-1"
                  />
                </v-radio-group>
              </v-row>
              <v-divider/>

              <v-col cols="12" class="pa-3">
                <v-row align="start" justify="center" class="d-flex flex-column">
                  <span class="body black--text my-1">{{$t('students.school')}}</span>
                  <v-autocomplete
                    class="w-100"
                    filled
                    flat
                    dense
                    v-model="userToSave.school"
                    :items="schoolList"
                    item-value="value"
                    item-text="text"
                    :placeholder="$t('students.select_school')"
                    :rules="requiredRule('school')"
                  />
                </v-row>

                <v-row align="start" justify="center" class="d-flex flex-column">
                  <span class="body black--text my-1">{{ $t('students.courses') }}</span>
                  <v-autocomplete
                    filled
                    flat
                    dense
                    multiple
                    :items="courses"
                    v-model="userToSave.courses"
                    item-value="id"
                    item-text="name"
                    :placeholder="$t('students.select_courses')"
                  />
                </v-row>

              </v-col>
            </v-col>

          </v-row>

        </v-card>
      </v-col>

      <v-col cols="12" md="3" class="h-100">
        <v-row align="center" justify="center" class="h-100">
          <v-col cols="12" class="pb-0">
            <v-alert rounded outlined light color="deep-purple" class="pa-2 mb-0">
              <h4 class="text-left deep-purple--text lighten-2 font-weight-semibold">
                Acciones:
              </h4>
             <v-btn
                rounded
                small
                color="deep-purple lighten-1"
                dark
                @click.prevent="openChangePassword()"
              >
                {{$t('students.create_new_information')}}
              </v-btn>
            </v-alert>
            </v-col>
        </v-row>
      </v-col>

      <!-- MODALS FOR THE PAGE -->
      <general-modal-container @onClose="closeChangePassword()" v-if="isChangePasswordModalOpen">
        <student-change-password-modal
          :student-name="teacherInfo.name"
          :student-username="teacherInfo.username"
          :student-id="teacherInfo.id"
          :country-id="teacherInfo.country_id"
          v-model="newPassword"
          :disabled-buttons="modalLoading"
          @onSave="onSavePassword()"
          @onCancel="closeChangePassword()"

        />
      </general-modal-container>
    </v-row>
  </v-form>
</template>

<script>
// Modals
import GeneralModalContainer from '@/components/core/modals/GeneralModalContainer.vue';
import StudentChangePasswordModal from '@/components/students/StudentChangePasswordModal.vue';

import { mapState } from 'vuex';
import LoadingMixin from '../../mixins/loading';
import SnackbarMixin from '../../mixins/snackbar';
import userService from '../../services/user.service';
import courseService from '../../services/course.service';
import sectionService from '../../services/section.service';
import schoolService from '../../services/school.service';
import { FLAGS_URL } from '../../constants/data';

export default {
  name: 'TeacherEdit',
  props: { teacherId: { type: [Number, String], default: 0 } },
  components: {
    GeneralModalContainer,
    StudentChangePasswordModal,
  },
  data: () => ({
    isActive: false,
    status: true,
    isChangePasswordModalOpen: false,
    activePicker: null,
    flagsPath: `${FLAGS_URL}countries_flags/`,
    date: null,
    menu: false,
    dniValidationOutputErrors: [],
    courses: [],
    created: null,
    lastAccessDate: null,
    userToSave: {
      id: null,
      firstname: null,
      lastname: null,
      lastname2nd: null,
      dni: null,
      birthDate: null,
      country: null,
      active: true,
      school: null,
      courses: [],
    },
    grade_id: null,
    school_id: null,
    valid: true,
    newPassword: '',
    modalLoading: false,
    schoolList: [],
  }),
  computed: {
    ...mapState({
      countries: ({ common: { countries } }) => countries,
    }),
    dniRule() {
      return [
        (v) => !!v || this.$t('students.dni_required'),
        (v) => v && v.length >= 4 || this.$t('students.dni_length_valid'),
      ];
    },
    sectionRule() {
      return [
        (v) => (v > -1 || v !== null) || this.$t('students.section_required'),
      ];
    },
    teacherInfo() {
      return this.userToSave ? {
        name: `${this.userToSave.firstname} ${this.userToSave.lastname}`,
        username: this.userToSave.dni,
        id: this.userToSave.id,
        country_id: this.userToSave.country,
      } : null;
    },

  },
  async mounted() {
    this.loader();
    const { status, data } = await userService.findOne(this.teacherId);
    this.sections = [...await this.findSections()];
    if (status !== 200) this.snackbarError(this.$t('students.student_not_found'));
    else {
      const user = { ...data };
      this.userToSave.birthDate = user.birthDate ? this.$moment(user.birthDate).format('YYYY-MM-DD') : '';
      this.userToSave.id = user.id;
      this.userToSave.dni = user.dni;
      this.userToSave.firstname = user.firstname;
      this.userToSave.lastname = user.lastname;
      this.userToSave.lastname2nd = user.lastname2nd;
      this.userToSave.email = user.email;
      this.userToSave.active = user.active;
      this.userToSave.country = user.country?.id;
      this.userToSave.school = user.school_id;
      this.userToSave.courses = user.courseteachers;
      this.created = user.creationDate;
      this.lastAccessDate = user.lastAccessDate ? this.$moment(user.lastAccessDate).format('HH:mm MM/D/YYYY') : null;
    }

    this.loader();
  },
  methods: {
    async findSections() {
      const { status, data } = await sectionService.findAll();
      if (status !== 200) return [];
      return data;
    },
    async getSchools(country) {
      try {
        const { data, status } = await schoolService.getListByCountry(country);
        if (status === 200) this.schoolList = [...data];
        else this.schoolList = [];
      } catch (e) {
        this.snackbarError(e.message);
      }
    },
    async getCourses(schoolId) {
      try {
        const { data, status } = await courseService.findAllBySchool(schoolId);
        if (status === 200) this.courses = [...data];
        else this.courses = [];
      } catch (e) {
        this.snackbarError(e.message);
      }
    },
    async onSavePassword() {
      this.modalLoading = !this.modalLoading;
      if (this.userToSave && this.userToSave.dni) {
        const { dni, country } = this.userToSave;
        const { status } = await userService.changePassword({
          dni,
          country,
          password: this.newPassword,
        });
        if (status !== 200) this.snackbarError(this.$t('students.new_password_not_saved'));
        else this.snackbarSuccess(this.$t('students.new_password_was_set'));
      }

      this.modalLoading = !this.modalLoading;
      this.isChangePasswordModalOpen = false;
    },
    openChangePassword() {
      this.isChangePasswordModalOpen = true;
    },
    closeChangePassword() {
      this.isChangePasswordModalOpen = false;
    },
    // Method for update user
    async update() {
      if (this.$refs.form.validate()) {
        this.loader();
        try {
          const { status } = await userService.update({ ...this.userToSave }, 'TEACHER');

          if (status !== 200) this.snackbarError(this.$t('error.user_not_saved'));
          else this.snackbarSuccess(this.$t('students.user_saved'));
        } catch (error) {
          this.snackbarError(this.$t('error.user_not_saved'));
        } finally {
          this.loader();
        }
      }
    },
    capitalize(name) {
      return name
        .split(' ')
        .map(
          (country) => `${country.charAt(0).toUpperCase()}${country.slice(1).toLowerCase()}`,
        )
        .join(' ');
    },
    requiredRule(field) {
      return [
        (v) => !!v || this.$t(`students.${field}_required`),
      ];
    },
  },
  mixins: [LoadingMixin, SnackbarMixin],
  watch: {
    date(nValue) {
      if (this.user) this.user.birthDate = nValue;
    },
    'userToSave.country': async function (nValue) {
      if (nValue) {
        await this.getSchools(nValue);
      }
    },
    'userToSave.school': async function (nValue) {
      if (nValue) {
        await this.getCourses(nValue);
      }
    },
  },
};
</script>
<style scoped>
.w-100{
  width: 100%;
}
</style>
