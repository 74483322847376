<template>
  <v-dialog
    v-model="dialog"
    max-width="800px"
    @click:outside="closed"
  >
    <v-card :loading="loading" :disabled="loading">
      <v-card-title class="justify-center">
          <span class="text-h5 sp">{{ $t('colleges.create_school_group') }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
         <iframe src="https://beereaders.retool.com/embedded/public/e854ae6a-c49b-4e19-ac9c-f3df460a1405" width="100%" height="500px"></iframe>
        </v-container>

      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'CreateSchoolGroupModal',
  props: {
    value: { type: Boolean, default: false },
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    closed() {
      this.dialog = false
    }
  },
};
</script>

<style scoped>
span.sp{
    font-family: "Space Grotesk" !important;
}
</style>
