<template>
  <v-container fluid>
    <h5 class="grey--text">Vista general</h5>
    <v-row>
        <v-col md="6">
            <h2 class="deep-purple--text lighten-3">{{$t('teachers.all_teachers')}}</h2>
        </v-col>
        <v-col md="6">
            <v-row justify="end" class="my-0 mr-4">
                <v-btn text small color="deep-purple lighten-1" @click.prevent="toggleCreateTeacherModal()">
                    <v-icon size="21" class="mr-1">
                    mdi-plus
                    </v-icon>
                    {{ $t('teachers.create') }}
                </v-btn>
            </v-row>
        </v-col>
    </v-row>
    <teachers-filter v-model="params" @search="search(true)"></teachers-filter>
    <teachers-data-table :table-loading="loading" v-model="page" :items="teachers"
                         :length="totalPages"></teachers-data-table>
    <create-teacher-modal
      v-model="isCreateTeacherModalOpen"
      @onCancel="toggleCreateTeacherModal"
    />
  </v-container>
</template>

<script>
import CreateTeacherModal from '@/components/teachers/modals/CreateTeacherModal.vue';
import { mapState, mapActions } from 'vuex';
import TeachersFilter from '../../components/core/filters/TeachersFilter';
import TeachersDataTable from '../../components/teachers/TeachersDataTable';
import LoadingMixin from '../../mixins/loading.ts';

import userService from '../../services/user.service';
import { defaultTeacherFilter } from '../../constants/filters';

export default {
  name: 'Teachers',
  components: {
    TeachersFilter,
    TeachersDataTable,
    CreateTeacherModal,
  },
  data: () => ({
    page: 1,
    params: { ...defaultTeacherFilter },
    isCreateTeacherModalOpen: false,
  }),
  async mounted() {
    await this.search();
  },
  computed: {
    ...mapState({
      teachers: ({ enrollment: { teachers } }) => teachers,
      totalPages: ({ enrollment: { totalPages } }) => totalPages,
    }),
  },
  methods: {
    ...mapActions('enrollment', ['setTeachersDataTable']),
    async search(reset = false) {
      this.loader();
      if (reset) this.page = 1;
      const {
        data: {
          data,
          total_page,
        },
        status,
      } = await userService.findAllByParameters(this.page, this.params);
      if (status === 200) {
        const teachersWithCourses = data.map((teacher) => {
          const coursesNames = teacher.courseteachers.map((ct) => ct.name).join(', ');
          return {
            ...teacher,
            courses: teacher.courseteachers.length > 1 ? coursesNames.slice(0, 50).concat('...') : coursesNames,
          };
        });
        this.setTeachersDataTable({
          data: teachersWithCourses,
          totalPages: total_page,
        });
      }
      this.loader();
    },
    toggleCreateTeacherModal() {
      this.isCreateTeacherModalOpen = !this.isCreateTeacherModalOpen;
    },
  },
  watch: {
    page() {
      this.search();
    },
  },
  mixins: [LoadingMixin],
};
</script>
