import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Route, NavigationGuardNext } from 'vue-router';
import store from '@/store';
import USER_ROLES from '../../utils/user';

export default (to: Route, from: Route, next: NavigationGuardNext) => {
  const { requiresAuth, roles } = to.meta as { requiresAuth: boolean; roles: string[] };
  const { currentUser, userLogged } = store.state;

  if (to.fullPath === '/login') {
    next();
    return;
  }

  if (!userLogged || !currentUser || typeof currentUser !== 'object') {
    next('/login');
    return;
  }

  if (!to.matched.length) {
    if (currentUser.roles.includes(USER_ROLES.ADMIN)) {
      next('/dashboard');
    } else if (currentUser.roles.includes(USER_ROLES.SCHOOL_GROUP_ADMIN)) {
      next('/school');
    } else {
      next('/login');
    }
    return;
  }

  if (!requiresAuth) {
    next();
    return;
  }
  if (currentUser && typeof currentUser === 'object') {
    const hasRole = roles.some((role) => currentUser.roles?.includes(role));
    if (hasRole) {
      next();
    } else {
      next('/login');
    }
  }
  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      next();
    } else {
      next('/login');
    }
  });
};
