import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import AuthenticationGuard from './guards/require-authentication';
import USER_ROLES from '../utils/user';

const AUTH_META = {
  requiresAuth: true,
};

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "enrollment-admin" */ '@/layouts/BaseLayout.vue'),
    redirect: '/dashboard/colleges',
    children: [
      {
        path: 'login',
        component: () => import(/* webpackChunkName: "login" */ '@/views/LoginView.vue'),
      },
      {
        path: 'register',
        component: () => import(/* webpackChunkName: "login" */ '@/views/RegisterView.vue'),
      },
      {
        path: 'dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/layouts/DashboardLayout.vue'),
        redirect: '/dashboard/colleges',
        meta: { ...AUTH_META, roles: [USER_ROLES.ADMIN] },
        children: [
          {
            path: 'colleges',
            component: () => import('@/views/dashboard/Colleges.vue'),
            meta: { ...AUTH_META, roles: [USER_ROLES.ADMIN] },

          },
          {
            path: 'students',
            component: () => import('@/views/dashboard/Students.vue'),
            meta: { ...AUTH_META, roles: [USER_ROLES.ADMIN] },
          },
          {
            path: 'teachers',
            component: () => import('@/views/dashboard/Teachers.vue'),
            meta: { ...AUTH_META, roles: [USER_ROLES.ADMIN] },
          },
          {
            path: 'parents',
            component: () => import('@/views/dashboard/Parents.vue'),
            meta: { ...AUTH_META, roles: [USER_ROLES.ADMIN] },
          },
          {
            path: 'reports',
            component: () => import('@/views/dashboard/Reports.vue'),
            meta: { ...AUTH_META, roles: [USER_ROLES.ADMIN] },
          },
        ],
      },
      {
        path: 'edit',
        component: () => import(/* webpackChunkName: "edition" */ '@/layouts/EditionLayout.vue'),
        meta: { ...AUTH_META, roles: [USER_ROLES.ADMIN] },
        children: [
          {
            path: 'students/:studentId',
            component: () => import('@/views/students/StudentEdition.vue'),
            props: true,
            meta: { ...AUTH_META, roles: [USER_ROLES.ADMIN] },
          },
          {
            path: 'teachers/:teacherId',
            component: () => import('@/views/teachers/TeacherEdit.vue'),
            props: true,
            meta: { ...AUTH_META, roles: [USER_ROLES.ADMIN] },
          },
          {
            path: 'colleges/:collegeId',
            component: () => import('@/views/colleges/CollegeEdition.vue'),
            meta: { ...AUTH_META, roles: [USER_ROLES.ADMIN] },
          },
        ],
      },
      {
        path: 'detail',
        component: () => import(/* webpackChunkName: "detail" */ '@/layouts/DetailLayout.vue'),
        meta: { ...AUTH_META, roles: [USER_ROLES.ADMIN] },
        children: [
          {
            path: 'colleges/:collegeId',
            component: () => import('@/views/colleges/CollegeDetail.vue'),
            redirect: 'colleges/:collegeId/students',
            props: true,
            meta: { ...AUTH_META, roles: [USER_ROLES.ADMIN] },
            children: [
              {
                path: 'students',
                component: () => import('@/views/colleges/detail/CollegeStudentsDetail.vue'),
                meta: { ...AUTH_META, roles: [USER_ROLES.ADMIN] },
              },
              {
                path: 'teachers',
                component: () => import('@/views/colleges/detail/CollegeTeachersDetail.vue'),
                meta: { ...AUTH_META, roles: [USER_ROLES.ADMIN] },
              },
              {
                path: 'directives',
                component: () => import('@/views/colleges/detail/CollegeDirectivesDetail.vue'),
                meta: { ...AUTH_META, roles: [USER_ROLES.ADMIN] },
              },
            ],
          },
        ],
      },
      {
        path: 'school',
        component: () => import(/* webpackChunkName: "school" */ '@/layouts/SchoolAdminLayout.vue'),
        redirect: '/school/admin',
        meta: { ...AUTH_META, roles: [USER_ROLES.SCHOOL_GROUP_ADMIN, USER_ROLES.ADMIN] },
        children: [
          {
            path: 'admin',
            component: () => import('@/views/schooladmin/Schools.vue'),
            meta: { ...AUTH_META, roles: [USER_ROLES.SCHOOL_GROUP_ADMIN, USER_ROLES.ADMIN] },
          },
        ],
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(AuthenticationGuard);

export default router;
