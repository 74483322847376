<template>
  <v-container fluid>
    <v-data-table
      v-model="collegesSelected"
      :headers="headers"
      :items="schools"
      :single-select="singleSelect"
      item-key="id"
      show-select
      class="elevation-0"
      checkbox-color="deep-purple"
      hide-default-footer
      :loading="loading"
      :items-per-page="schoolsPerPage"
    >

      <template v-slot:item.name="{ item }">
        <router-link :to="`/detail/colleges/${item.id}`" class="mx-1">
          <span>{{ item.name }}</span>
        </router-link>
      </template>
      <template v-slot:item.baseCountryId="{ item }">
        <v-img
          :src="`${flagsPath}${item.country.iso_code}.png`"
          alt="country flag"
          width="30"
          class="d-inline-flex mx-2"
        />
        <span>{{ item.country.name }}</span>
      </template>
      <template v-slot:item.active="{ item }">
        <span v-if="item.active" class="green--text font-weight-bold">
          {{ $t('colleges.active') }}
        </span>
        <span v-else class="orange--text darken-2 font-weight-bold">
          {{ $t('colleges.inactive') }}
        </span>
      </template>
      <template v-slot:item.edit="{ item }">
        <router-link :to="`/edit/colleges/${item.id}`" class="mx-1">
          <v-icon>mdi-pencil-outline</v-icon>
        </router-link>
        <router-link :to="`/detail/colleges/${item.id}`"  class="mx-1">
          <span class="text-decoration-underline">{{ $t('colleges.detail') }}</span>
        </router-link>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        @input="setValue($event)"
        :length="length"
        :value="value"
        circle
        @next="next"
        @previous="previous"
        :disabled="length<1"
        :total-visible="7"
      />
    </div>

    <v-row align="center" justify="center" class="pt-6" v-if="collegesSelected.length">
      <v-btn
        color="deep-purple lighten-1"
        dark
        rounded
        elevation="0"
        @click.prevent="migrateCollegesOfPeriod()"
        class="mx-3"
      >
        {{ $t('colleges.migrate_period') }}
      </v-btn>
      <v-btn
        color="orange"
        dark
        rounded
        elevation="0"
        @click.prevent="disableColleges()"
        class="mx-3"
      >
        {{ $t('colleges.disable_college') }}
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable no-param-reassign */
import { FLAGS_URL } from '@/constants/data';

const headerBuilder = (text, value, sortable = true) => ({
  text,
  value,
  sortable,
  align: 'center',
});

export default {
  name: 'CollegesDataTable',
  props: {
    loading: { type: Boolean, default: true },
    schools: { type: Array, default: () => [] },
    value: { type: [String, Number], default: 1 },
    length: { type: [String, Number], default: 1 },
    showReports: { type: Boolean, default: false },
  },
  data: () => ({
    singleSelect: false,
    collegesSelected: [],
    schoolsPerPage: 15,
    flagsPath: `${FLAGS_URL}countries_flags/`,
  }),
  computed: {
    headers() {
      if (this.showReports) {
        return [
          headerBuilder('ID', 'id'),
          headerBuilder(this.$t('colleges.school_name'), 'name'),
          headerBuilder(this.$t('colleges.country'), 'baseCountryId'),
          headerBuilder(this.$t('colleges.status'), 'active'),
        ];
      }
      return [
        headerBuilder('ID', 'id'),
        headerBuilder(this.$t('colleges.school_name'), 'name'),
        headerBuilder(this.$t('colleges.country'), 'baseCountryId'),
        headerBuilder(this.$t('colleges.status'), 'active'),
        headerBuilder(this.$t('colleges.edit'), 'edit', false),
      ];
    },
  },
  methods: {
    next(page) {
      this.$emit('next', page);
    },
    previous(page) {
      this.$emit('previous', page);
    },
    setValue(value) {
      this.$emit('input', value);
    },
    disableColleges() {
      this.collegesSelected.forEach((college) => {
        college.state = false;
      });
      this.collegesSelected = [];
    },
    migrateCollegesOfPeriod() {
      this.collegesSelected.forEach((college) => {
        college.state = false;
      });
      this.collegesSelected = [];
    },
  },
};
</script>
