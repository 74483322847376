const colleges = {
  create: 'Create new School',
  active: 'Active',
  inactive: 'Inactive',
  detail: 'Detail',
  migrate_period: 'Migrate period',
  disable_college: 'Disable college(s)',
  filters: 'Filters',
  clean_up: 'Clean up',
  search: 'Search',
  status: 'Status',
  country: 'Country',
  school_name: 'School name',
  analytics: '📊 Analytics Panel 🎓  ',
  edit: 'Edit',
  general_information: 'General information',
  creation: 'Creation',
  sale: 'Sale',
  commune: 'Commune',
  contact: 'Contact',
  phone: 'Phone',
  current_period_of_operation: 'Current period of operation',
  success_edition_msg: 'The school was successfully edited.',
  error_edition_msg: 'Something went wrong! Try to perform this same action later.',
  students_migrated: 'Students have been migrated',
  massive_load: 'Massive Load',
  new_course: 'New course',
  course_saved_success: 'Course saved successfully',
  school_id: 'School ID',
  payment_identifier: 'Payment identifier',
  excel_format: 'Excel Format',
  see_an_example: 'See an example',
  users_have_DNI_label: 'Have users DNI?',
  users_have_DNI_yes: 'Yes',
  users_have_DNI_no: 'No',
  dissable_old_data_label: 'Disable old data?',
  dissable_old_data_yes: 'Yes',
  dissable_old_data_no: 'No',
  file: 'File',
  file_placeholder: 'Choose a file...',
  error_list: 'Error list',
  successful_upload: 'Successful upload!',
  save_as_txt: 'Save as txt',
  massive_load_warning: 'Students that are not in the document will be deactivated.',
  select_group: 'Select school group',
  reports: 'Reports',
  reports_see_column: 'See',
  school_arrangement: "School Arrangement Details",
  arrangement_name: 'Arrangement name',
  total_licenses: 'Total of licenses',
  arrangement_range_dates: 'Arrangement range dates',
  enrollment_type: 'Arrangement type',
  select_enrollment_type: 'Select an arrangement type',
  create_school_group: "Create School group",
  school_group: "School Group",
  select_school_group: "Select a school group",
  school_arrangement_is_invalid: "School arrangement data is invalid"
};

export default colleges;
