<template>
  <v-dialog v-model="value" max-width="90vw">
    <v-card :loading="loading" :disabled="loading">
      <v-card-title class="justify-center">
        <span class="text-h5 sp">{{ $t('colleges.analytics') }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col class="col-3">
              <span class="ml-1 deep-purple&#45;&#45;text font-weight-bold">
            <a target="_blank" :href="'https://beereaders.retool.com/embedded/public/f411be99-4490-49ff-80fe-41524293273d?schoolId='+encodedId">
              Ir a Retool
            </a>
            <span>Contraseña: 9xco1iycddn</span>
          </span>
            </v-col>
            <v-col>
              <button style="margin-bottom: 12px;" class="deep-purple--text v-btn v-btn--outlined v-btn--rounded theme--light elevation-0 v-size--small white--text" @click="copyText('https://beereaders.retool.com/embedded/public/f411be99-4490-49ff-80fe-41524293273d?schoolId='+encodedId+'\n Contraseña: 9xco1iycddn')">
                Click para copiar link y contraseña
              </button>
              <v-tooltip
                v-model="showTooltip"
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                  </v-btn>
                </template>
                <span>Link copiado al portapapeles!</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <iframe :src="'https://beereaders.retool.com/embedded/public/0b312c03-4de0-4909-b183-fbe6bb5a8f96?schoolId='+encodedId" width="100%" height="500px"></iframe>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
const hashSecret = 'ce47ca3e0h9ce';

export default {
  name: 'CreateSchoolModal',
  props: {
    value: { type: Boolean, default: false },
    school: { type: Object, default() { return {}; } },
  },
  data: () => ({
    loading: false,
    showTooltip: false,
  }),
  computed: {
    encodedId() {
      return btoa(hashSecret + this.school.id + hashSecret);
    },
  },
  methods: {
    copyText(text) {
      navigator.clipboard.writeText(text);
      this.showTooltip = !this.showTooltip;
    },
    close() {
      this.$emit('onCancel');
    },
  },
};
</script>

<style scoped>
span.sp{
  font-family: "Space Grotesk" !important;
  font-weight: bolder;
}
</style>
