<template>
  <v-navigation-drawer
    v-model="drawer"
    :mini-variant="mini"
    permanent
    floating
    fixed
    app
    mini-variant-width="60"
    width="180"
  >
    <v-row align="center" class="bg-gray h-100 pa-0 ma-0 branded">
      <v-col cols="12" class="bg-white py-12 px-0 mx-0 my-12 pt-0">
        <v-list flat>
          <v-list-item>
            <v-icon @click.prevent="mini = !mini">
              mdi-text
            </v-icon>
          </v-list-item>

          <div>
            <v-list-item
              v-for="item in items"
              :key="item.title"
              link
              :to="item.path"
              :disabled="item.disabled"
              class="w-100 clickable"
              active-class="enrollment__nav-item--selected"
            >
              <v-list-item-content class="pl-3 text-uppercase">
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-title
                      v-bind="attrs"
                      v-on="on">
                      {{ item.title }}
                    </v-list-item-title>
                  </template>
                  <span>{{ item.title }}</span>
                </v-tooltip>

              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list>
      </v-col>
    </v-row>
  </v-navigation-drawer>
</template>

<script>

export default {
  name: 'NavigationDrawer',
  props: {
    items: Array,
  },
  data: () => ({
    drawer: true,
    mini: true,
  }),
  created() {
    if (location.pathname.startsWith('/detail')) {
      this.mini = false;
    }
  },
};
</script>

<style lang="scss">
  .h-100 {
    height: 100%;
  }
  .w-100 {
    width: 100%;
  }
  .branded{
    opacity: 0.9;
    background-image: url("https://www.beereaders.com/img/latam-launch-event/frame.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
  .clickable :hover{
    cursor: pointer;
    text-decoration: underline;
  }
  .bg-white {
    background-color: white;
  }
  .bg-gray {
    background-color: #F4F3F6;
  }
  .enrollment {
    &__nav-item {
      &--selected {
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 6px;
          box-shadow: 0 0 12px 3px rgba(0,0,0,.21);
          width: 15px;
          height: 100%;
          background-color: #7656D6;
        }
      }
    }
  }
</style>
