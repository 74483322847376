import error from '@/i18n/en/error';
import colleges from '@/i18n/en/colleges';
import students from '@/i18n/en/students';
import reports from '@/i18n/en/reports';

export default {
  hello: 'Hello',
  footer_cs_love: 'With ❤️ for the Customer Success team',
  login: {
    title: 'Achool administrator',
    subtitle: 'Enter your username and password',
    user_input: 'Username',
    password_input: 'Password',
    login_btn: 'Log in',
    register_btn: 'Registrar',
    password_required: 'Password required',
    email_required: 'Email is required',
    email_format_validation: 'Email format is wrong',
    dont_have_account: "Don't have an account?",
    register_link: 'Register here',
    login_button: 'Login',
  },
  register: {
    title: 'Register',
    name: 'Name',
    surname: 'Surname',
    email: 'Email',
    password_input: 'Password',
    repeat_password_input: 'Confirm your password',
    back: 'Back',
    password_not_match: 'Password does not match',
    password_length: 'Password must be at least 8 characters long',
    school_group: 'Select the school group',
  },
  layout: {
    colleges: 'Colleges',
    students: '🎓 Students',
    teachers: '🧑‍🏫 Teachers',
    edition: 'Edition',
  },
  all_colleges: 'All Schools',
  error,
  colleges,
  students,
  reports,
  teachers: {
    name: 'Name',
  },
  common: {
    filter: 'Filters',
    filter_by: 'Filter by',
    to_disable: 'Disable',
    to_enable: 'Enable',
    student: 'Student',
    disable_message: 'By performing this action the account will be disabled. Are you sure you want to perform this action?',
    enable_message: 'By performing this action the account will be enabled. Are you sure you want to perform this action?',
    cancel: 'Cancel',
    close: 'Close',
    send: 'Send',
    to_save: 'Save',
    to_select: 'Select',
    to_transfer: 'Transfer',
    field_required: 'This field is required',
    an_error_ocurred: 'An error has occurred',
    support_text: 'Unexpected error in the application.',
    support_desc: 'Please take a screenshot of this bug and send it to the technology team.',
  },
  subscription: {
    overdue: 'Overdue',
  },
  navigation: {
    colleges: 'Colleges',
    students: '🎓 Students',
    teachers: 'Teachers',
    parents: 'Parents',
    reports: 'Reports',
    school_admin: 'School Admin',
  },
  app_header: {
    school_administration: 'Schools Administration',
    user_administration: 'Users Administration 👥',
  },
  general: {
    error_msg: "Oops! Something went wrong!. Please contact support team",
    loading: 'Loading...',
    only_positive_number: "Please enter a positive number",
    is_required: "This field is required",
    valid_dates: "Please enter valid dates",
  },
};
