import mapParameters from '@/utils/map-parameters';
import BaseService from './base.service';

const baseService = new BaseService('klprior');

const reportBaseService = new BaseService('priorRegistration', process.env.VUE_APP_BEEREADERS_API_URL);

export default {
  findOneById: (priorId: number | string) => baseService.get(`findOne/${priorId}`),
  findOneByDNI: (dni: string) => baseService.get(`findOneByDni/${dni}`),

  getSalesAndActivationReport: (
    dateFrom: string,
    dateTo: string,
    excludingDemoData: boolean,
  ) => {
    const params = mapParameters({
      dateFrom,
      dateTo,
      excludingDemoData,
    });
    return reportBaseService.getFile('getSalesAndActivationReportByDateRange', params);
  },
};
