<template>
  <v-card flat rounded elevation="0" class="pa-6 ma-3" color="grey lighten-5" :loading="disabledButtons">
    <v-card-title>
      Creación de nueva contraseña
    </v-card-title>

    <v-card-text>
      <v-row align="center" justify="center">
        <v-col cols="6">
          <v-row align="center" justify="center" class="d-flex flex-column">
            <span>
              Usuario
            </span>
            <p class="pa-0 ma-0">{{ studentUsername }}</p>
          </v-row>
        </v-col>

        <v-col cols="6">
          <v-row align="center" justify="center" class="d-flex flex-column">
            <span>Nombre</span>
            <p class="ma-0 pa-0">{{ studentName }}</p>
          </v-row>
        </v-col>
      </v-row>

      <v-row align="center" justify="center" class="pt-5">
        <span class="pa-0 ma-0">contraseña</span>
        <v-text-field
          placeholder="Nueva contraseña"
          rounded
          solo
          flat
          dense
          color="white"
          light
          class="pa-0 ma-0 ml-3"
          hide-details
          @input="handleInput"
        />
      </v-row>

      <v-row align="center" justify="center">
        <span class="text-center caption pa-3">
          * Sin números consecutivos, almenos una mayúscula
        </span>
      </v-row>

      <v-row align="center" justify="center">
        <v-btn text small color="deep-purple lighten-2" @click.prevent="handleCancel()" :disabled="disabledButtons">
          Cancelar
        </v-btn>
        <v-btn rounded outlined small color="deep-purple lighten-2" @click="savePassword()" :disabled="disabledButtons">
          Guardar
        </v-btn>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'StudentChangePasswordModal',
  props: {
    studentName: {
      type: String,
      default: '',
      required: true,
    },
    studentUsername: {
      type: String,
      default: '',
      required: true,
    },
    studentId: {
      type: [String, Number],
      default: '',
      required: true,
    },
    countryId: {
      type: [String, Number],
      default: '',
      required: true,
    },
    value: {
      type: String,
      default: '',
    },
    disabledButtons: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    savePassword() {
      this.$emit('onSave');
    },
    handleCancel() {
      this.$emit('onCancel');
    },
    handleInput(value) {
      this.$emit('input', value);
    },
  },
};
</script>
