const LoadingMixin = {
  data: () => ({
    loading: false,
  }),
  methods: {
    loader() {
      // @ts-ignore
      this.loading = !this.loading;
    },
  },
};

export default LoadingMixin;
