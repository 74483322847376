<template>
  <v-col cols="4">
    <v-card @click="setSelected" class="mx-4 pa-4" height="200" hover>
      <p class="text-center font-weight-black">{{ data.title }}</p>
      <p class="pt-6">{{ data.description }}</p>
    </v-card>
  </v-col>
</template>

<script>
import { DEFAULT_REPORT_LIST_ITEM } from '@/constants/data';

export default {
  name: 'ReportListItem',
  props: {
    data: { type: Object, default: () => ({ ...DEFAULT_REPORT_LIST_ITEM }) },
    index: { type: Number, default: 0 },
  },
  methods: {
    setSelected() {
      this.$emit('selected', this.index);
    },
  },
};
</script>
