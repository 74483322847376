export const defaultStudentFilter = {
  firstname: '',
  lastname: '',
  schoolId: null,
  username: '',
  active: 1,
  country: null,
  dni: '',
  userType: 0,
};

export const defaultTeacherFilter = {
  firstname: '',
  lastname: '',
  schoolId: null,
  username: '',
  active: 1,
  country: null,
  dni: '',
  userType: 2,
};

export const defaultSchoolFilter = {
  country: null,
  schoolName: '',
  id: null,
  active: 1,
};

export const defaultCourseSchoolFilter = {
  grades: [],
  sections: [],
};

export const defaultSchoolListParams = {
  id: '',
  baseCountry_id: '',
  name: '',
  active: '',
};
