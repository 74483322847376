<template>
  <div class="deep-purple lighten-5">
    <enrollment-header />
    <v-main class="pa-0">
      <v-row align="start" justify="center">
      <v-col class="pb-0">
        <v-breadcrumbs
          class="pb-0"
          divider="/"
          :items="breadcrumbItems"
        >
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item
              :href="prevRoute ? prevRoute : item.href"
              :disabled="item.disabled"
            >
              <v-icon
                v-if="item.text.toLowerCase() === $t(`layout.${currentCategory}`).toLowerCase()"
                class="deep-purple--text mr-2"
              >
                mdi-arrow-left
              </v-icon>
              <span
                v-if="item.text.toLowerCase() === $t(`layout.${currentCategory}`).toLowerCase()"
                class="text-capitalize deep-purple--text font-weight-bold"
              >
                {{ item.text }}
              </span>
              <span v-else>
                {{ item.text }}
              </span>
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </v-col>
      </v-row>
      <v-row align="start" justify="center" class="px-3 px-md-10">
        <v-col align="center" justify="center">
          <v-container fluid>
            <router-view></router-view>
          </v-container>
        </v-col>
      </v-row>
    </v-main>
    <enrollment-footer />
  </div>
</template>

<script>
import Header from '@/components/core/Header.vue';
import Footer from '@/components/core/Footer.vue';

export default {
  name: 'EditionLayout',
  components: {
    EnrollmentHeader: Header,
    EnrollmentFooter: Footer,
  },
  data: () => ({
    breadcrumbItems: [],
    currentCategory: '',
    prevRoute: null,
  }),
  beforeMount() {
    /**
     * The routes for the enrollment dashboard edition must follow always the next pattern:
     * /edit/{category}/{id of the element}
     * category -> It refers to the category that we are working on, like students, colleges, etc.
     * id of the element -> It refers to the unique identificator of the element
     */
    const pathSegments = window.location.pathname.split('/');
    // eslint-disable-next-line prefer-destructuring
    this.currentCategory = pathSegments[2];
    this.breadcrumbItems.push({
      text: this.$t(`layout.${this.currentCategory}`),
      disabled: false,
      href: this.prevRoute ? this.prevRoute : `/dashboard/${this.currentCategory}`,
    });
    this.breadcrumbItems.push({
      text: this.$t('layout.edition'),
      disabled: true,
    });
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from.path;
      console.log('from', from.path);
    });
  },
};
</script>
