<template>
  <v-container fluid class="general-modal-container" >
    <div class="general-modal-container-bg" @click="closeModal()"/>
    <v-col cols="12" class="h-100">
      <v-row align="center" justify="center" class="h-100">
        <div class="general-modal-container-content">
          <slot />
        </div>
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'GeneralModalContainer',
  methods: {
    ...mapActions({
      closeGeneralModal: 'enrollment/closeModal',
    }),
    closeModal() {
      this.$emit('onClose');
      this.closeGeneralModal();
    },
  },
};
</script>

<style lang="scss">
  .general-modal-container {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 90;

    &-bg {
      position: absolute;
      width: 100%;
      height: 100vh;
      left: 0;
      top: 0;
      background: black;
      opacity: .75;
      z-index: 99;
    }

    &-content {
      z-index: 990;
    }
}
</style>
