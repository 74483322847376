<template>
  <v-dialog
    v-model="value"
    max-width="800px"
    class="select-school-group-modal"
  >
    <v-card :loading="loading" :disabled="loading">
      <v-card-title class="justify-center">
          <span class="text-h5 sp">{{ $t('colleges.select_group') }}</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" lazy-validation v-model="valid">
          <v-select
            dense
            :placeholder="$t('common.to_select')"
            :items="schoolGroups"
            item-text="text"
            item-value="value"
            v-model="schoolGroupId"
            @change="schoolGroupId = $event"
            :rules="fieldRequired"
            class="mt-8"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="orange darken-3"
          rounded
          outlined
          @click="accept"
        >
          {{ $t('common.to_save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import schoolGroupService from '@/services/schoolGroup.service';
import SnackbarMixin from '@/mixins/snackbar';

export default {
  name: 'SelectSchoolGroupModal',
  props: {
    value: { type: Boolean, default: false },
  },
  data: () => ({
    loading: false,
    schoolGroups: [],
    schoolGroupId: null,
    valid: false,
  }),
  async mounted() {
    this.loading = true;
    await this.getSchoolGroups();
    this.loading = false;
  },
  methods: {
    accept() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.$emit('onSelectSchoolGroup', this.schoolGroupId);
    },
    async getSchoolGroups() {
      try {
        this.schoolGroups = await schoolGroupService.findList();
      } catch (error) {
        this.snackbarError(error.message);
      }
    },
  },
  mixins: [SnackbarMixin],
  computed: {
    fieldRequired() {
      return [(v) => !!v || this.$t('common.field_required')];
    },
  },
};
</script>

<style scoped>
.select-school-group-modal {
  height: 90vh;
}
</style>
