<template>
  <v-alert rounded outlined light color="deep-purple" class="pa-1 py-3">
    <v-card-text>
      <v-row align="start" justify="start" class="d-flex flex-column mb-1">
        <h6 class="deep-purple--text lighten-2 font-weight-semibold">
          Registro último pago
        </h6>
        <v-row align="start" justify="space-between" class="w-100 pa-0 ma-0">
          <span class="black--text">
           {{lastPayment}}
          </span>
          <v-icon color="green lighten-2">
            mdi-currency-usd
          </v-icon>
        </v-row>
      </v-row>

      <!-- Validity -->
      <v-row align="start" justify="start" class="d-flex flex-column mb-1">
        <h6 class="deep-purple--text lighten-2 font-weight-semibold">
          Numero de orden MP
        </h6>
        <a
          :href="paymentUrl"
          class="blue--text text-decoration-underline font-weight-bold"
          v-if="mercadoPagoPaymentId"
          target="_blank"
        >
          {{mercadoPagoPaymentId}}
        </a>
        <router-link
          to="#"
          class="blue--text text-decoration-underline font-weight-bold"
          v-else
        >
          Sin registro
        </router-link>
      </v-row>

      <!-- Validity -->
      <v-row align="start" justify="start" class="d-flex flex-column mb-1">
        <h6 class="deep-purple--text lighten-2 font-weight-semibold">
          Vigencia
        </h6>
        <v-row align="start" justify="space-between" class="w-100 pa-0 ma-0" v-if="validity">
          <span class="black--text">
            {{ license }}
          </span>
          <v-icon color="green" class="ml-2">
            mdi-check-circle-outline
          </v-icon>
        </v-row>
        <v-row align="start" justify="space-between" class="w-100 pa-0 ma-0" v-else>
          <span class="black--text">
            {{ license }}
          </span>
          <v-icon color="red" class="ml-2">
            mdi-close-circle-outline
          </v-icon>
        </v-row>
      </v-row>
    </v-card-text>
  </v-alert>
</template>

<script>
export default {
  name: 'StudentPaymentRegisterStatus',
  props: {
    registerDate: {
      type: String,
      // required: false,
      default: null,
    },
    mercadoPagoPaymentId: {
      type: String,
      // required: false,
      default: null,
    },
    validity: {
      type: [Boolean, String],
      // required: true,
      default: false,
    },
    paymentUrl: { type: String, default: '#' },
  },
  methods: {
    isValidDate(date) {
      const aDate = this.$moment(this.registerDate, 'YYYY-MM-DD', true);
      return aDate.isValid();
    },
  },
  computed: {
    lastPayment() {
      if (this.isValidDate(this.registerDate)) return this.$moment(this.registerDate).format('DD-MM-YYY');
      return this.registerDate;
    },
    license() {
      if (!this.validity) return this.$t('subscription.overdue');

      if (this.isValidDate(this.validity)) return this.$moment(this.registerDate).format('MMM YYYY');

      return this.validity;
    },
  },
};
</script>
