<template>
  <v-col cols="12">
    <v-card class="pa-12" elevation="24">
      <v-row justify="center" class="font-weight-black text-h5">
        {{ $t('reports.payments_report') }}
      </v-row>
      <v-row class="mt-12" justify="center">
        <v-col cols="6" md="3">
          <v-menu
            v-model="dateFromMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateFrom"
                :label="$t('reports.from_date')"
                prepend-inner-icon="mdi-calendar"
                readonly
                outlined
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker v-model="dateFrom" @input="dateFromMenu = false" />
          </v-menu>
          <v-menu
            v-model="dateToMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateTo"
                :label="$t('reports.to_date')"
                prepend-inner-icon="mdi-calendar"
                readonly
                outlined
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker v-model="dateTo" @input="dateToMenu = false" />
          </v-menu>
        </v-col>
      </v-row>
      <v-row justify="center" class="my-0 py-0">
        <v-checkbox v-model="excludingDemoData" :label="$t('reports.excluding_demo_data')" />
      </v-row>
      <v-row justify="center">
        <v-btn color="deep-purple" x-large @click="download" class="white--text">
          {{ $t('reports.download') }}
        </v-btn>
      </v-row>
      <v-overlay :value="loading" absolute>
        <v-progress-circular indeterminate size="64" />
      </v-overlay>
    </v-card>
  </v-col>
</template>

<script>
import registrationService from '../../services/priorRegistration.service';

export default {
  name: 'ReportListItem',
  data: () => ({
    dateFrom:
      (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    dateTo:
      (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    dateFromMenu: false,
    dateToMenu: false,
    modal: false,
    menu2: false,
    reportName: 'ReportePreRegistro',
    loading: false,
    excludingDemoData: false,
  }),
  methods: {
    async download() {
      this.loading = true;
      const response = await registrationService.getSalesAndActivationReport(
        this.dateFrom,
        this.dateTo,
        this.excludingDemoData,
      )
        .catch((error) => console.error(error));
      this.loading = false;
      if (response) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${this.reportName}_${this.dateFrom}_${this.dateTo}.xlsx`);
        document.body.appendChild(link);
        link.click();
      }
    },
  },
};
</script>
