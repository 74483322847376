import BaseService from './base.service';

const baseService = new BaseService('course');

export default {
  findAll: () => baseService.get('findAll'),
  findAllTeachersByCourse: (id: number | string | any) => baseService.get(`teachers/findAll/${id}`),
  create: (data = {}) => baseService.post('create', data),
  findAllBySchool: (schoolId: number | string) => baseService.get(`findAllBySchool/${schoolId}`),
};
