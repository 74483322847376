<template>
  <v-card flat outlined rounded class="actions-card">
    <v-card-text>
      <v-row align="center" justify="center">
        <v-btn text small color="deep-purple lighten-1" @click.prevent="onMassiveUpload()">
          <v-icon size="21" class="mr-1">
            mdi-upload
          </v-icon>
          {{ $t('colleges.massive_load') }}
        </v-btn>
      </v-row>
      <v-row align="center" justify="center" class="mt-4 mb-0">
        <v-btn
          elevation="0"
          outlined
          rounded
          light
          small
          color="white"
          class="deep-purple--text ligthen-1"
          @click.prevent="onCreateNewCourse()"
        >
          {{ $t('colleges.new_course') }}
        </v-btn>
      </v-row>
      <v-row align="center" justify="center">
          <vue-json-to-csv :json-data="this.dataForExport"
            :labels="csvHeaders"
            :csv-title="csvTitle"
            separator=";"
            @error="error => console.log(error)">
            <button class="deep-purple--text ligthen-1 v-btn v-btn--outlined v-btn--rounded theme--light elevation-0 v-size--small white--text">
              Exportar CSV
            </button>
          </vue-json-to-csv>
      </v-row>
      <v-row align="center" justify="center" class="mt-6 mb-2">
        <v-btn text small color="deep-purple lighten-1" outlined rounded @click.prevent="toggleCreateStudentModal()">
            <v-icon size="21" class="mr-1">
              mdi-plus
            </v-icon>
            {{ isStudentView ? $t('students.create') : $t('teachers.create') }}
        </v-btn>
      </v-row>
    </v-card-text>
    <create-student-modal
      v-model="isCreateStudentModalOpen"
      :schoolId="schoolId"
      :countryId="countryId"
      :isStudent="isStudentView"
      @onCancel="toggleCreateStudentModal"
    />
  </v-card>
</template>

<script>
import VueJsonToCsv from 'vue-json-to-csv';
import CreateStudentModal from '@/components/students/modals/CreateStudentModal.vue';

export default {
  name: 'CollegeCourseActions',
  props: {
    dataForExport: { type: Array },
    csvTitle: { type: String, default: 'export.csv' },
    csvHeaders: { type: Object },
    schoolId: { type: String },
    countryId: { type: String },
  },
  data: () => ({
    isCreateStudentModalOpen: false,
    isStudentView: window.location.pathname.endsWith('students'),
  }),
  methods: {
    onCreateNewCourse() {
      this.$emit('onCreate');
    },
    onMassiveUpload() {
      this.$emit('onUpload');
    },
    toggleCreateStudentModal() {
      this.isCreateStudentModalOpen = !this.isCreateStudentModalOpen;
    },
  },
  components: {
    VueJsonToCsv,
    CreateStudentModal,
  },
};
</script>
<style scoped>
.actions-card{
  height: 160px;
}
</style>
