<template>
  <v-expansion-panels v-model="isOpen" multiple flat>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <v-row
          align="center"
          justify="end"
          class="px-3 deep-purple--text lighten-3 font-weight-bold"
        >
          {{ $t('common.filter') }}
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-card
          color="deep-purple lighten-3"
          dark
          elevation="6"
          class="pa-4"
        >
          <v-row align="center" justify="space-between">
            <v-col cols="12" lg="2" v-if="filters.includes('country')">
              <v-autocomplete
                :placeholder="$t('colleges.country')"
                flat
                solo
                light
                rounded
                dense
                :items="countries"
                item-value="id"
                item-text="name"
                v-model="value.country"
                color="white"
                single-line
                hide-details
              >
                <template v-slot:selection="{ item }">
                  <img
                    :src="`${flagsPath}${item.iso_code}.png`"
                    alt="country flag"
                    width="35vh"
                    height="25vh"
                    class="mr-2"
                  />
                  <span>{{ item.name }}</span>
                </template>
                <template v-slot:item="{ item }">
                  <template>
                    <img
                      :src="`${flagsPath}${item.iso_code}.png`"
                      alt="country flag"
                      width="35vh"
                      height="25vh"
                      class="mr-2"
                    />
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name | capitalize }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" lg="3" v-if="filters.includes('schoolName')">
              <v-text-field
                :placeholder="$t('colleges.school_name')"
                solo
                dense
                flat
                light
                rounded
                hide-details
                v-model="value.schoolName"
              />
            </v-col>

            <v-col cols="12" lg="2" v-if="filters.includes('id')">
              <v-text-field
                placeholder="ID"
                solo
                dense
                flat
                light
                rounded
                hide-details
                type="number"
                min="0"
                v-model.number="value.id"
              />
            </v-col>

            <v-col cols="12" lg="5">
              <v-row align="center" justify="space-between">
                <v-col cols="12" md="6" v-if="filters.includes('status')">
                  <span>{{ $t('colleges.status') }}:</span>
                  <v-btn-toggle
                    v-model="value.active"
                    dense
                    class="mx-3"
                    rounded
                    light
                  >
                    <v-btn :value="1">
                      {{ $t('colleges.active') }}
                    </v-btn>
                    <v-btn :value="0">
                      {{ $t('colleges.inactive') }}
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
                <v-col cols="12" md="6">
                  <v-btn
                    text
                    color="white"
                    class="text-decoration-underline"
                    small
                    rounded
                    @click="reset"
                  >
                    {{ $t('colleges.clean_up') }}
                  </v-btn>
                  <v-btn
                    outlined
                    rounded
                    small
                    @click="search"
                  >
                    {{ $t('colleges.search') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>

</template>

<script>
import { FLAGS_URL } from '@/constants/data';
import { defaultSchoolFilter } from '@/constants/filters';

export default {
  name: 'CollegesFilter',
  props: {
    countries: { type: Array, default: () => [] },
    value: { type: Object, default: () => ({ ...defaultSchoolFilter }) },
    filters: { type: Array, default: () => ['country', 'schoolName', 'id', 'status'] },
  },
  data: () => ({
    isOpen: [],
    isStatusActive: true,
    flagsPath: `${FLAGS_URL}countries_flags/`,
    schools: [],
  }),
  methods: {
    reset() {
      // eslint-disable-next-line array-callback-return
      Object.entries(this.value).map(([key]) => {
        this.value[key] = defaultSchoolFilter[key];
      });
      this.$emit('reset');
    },
    search() {
      this.$emit('search');
    },
  },
};
</script>
