import { MAX_PER_PAGE, RECORDS_PER_PAGE } from '@/constants/data';
import BaseService from './base.service';

const baseService = new BaseService('bookUser');

export default {
  findAllByParameters: (page: number, params: any) => baseService.post(`findAllByParameters/?page=${page}`, { ...params }),
  findAllBySchool: (page: number, school_id: number, max?: boolean) => baseService.get(`findAllBySchool/${school_id}/?page=${page}&perPage=${max ? MAX_PER_PAGE : RECORDS_PER_PAGE}`),
  update: (user: any, userType: string) => baseService.put(`update?userType=${userType}`, { ...user }),
  studentCourseTransfer: (params: any) => baseService.put('studentCourseTransfer', { ...params }),
  studentSchoolTransfer: (params: any) => baseService.put('studentSchoolTransfer', { ...params }),
  updateStatus: (params: any) => baseService.put('updateStatus', { ...params }),
  findOne: (id: number | string | any) => baseService.get(`findOne/${id}`),
  changePassword: (params: any) => baseService.put('changePassword', { ...params }),
  findAllByCourse: ({ school, grade, section }: any, page: number | string) => baseService.get(`findAllByCourse/${school}/${grade}/${section}?page=${page}`),
  findAllTeachersBySchool: (school_id: number | string, page: number | string, max?: boolean) => baseService.get(`findAllTeachersBySchool/${school_id}?page=${page}&perPage=${max ? MAX_PER_PAGE : RECORDS_PER_PAGE}`),
  findAllDirectivesBySchool: (page: number, school_id: number, max?: boolean) => baseService.get(`findAllDirectivesBySchool/${school_id}/?page=${page}&perPage=${max ? MAX_PER_PAGE : RECORDS_PER_PAGE}`),
};
