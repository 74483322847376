<template>
  <div class="grey lighten-5">
    <enrollment-header />
    <enrollment-navigation-drawer :items="getCurrentPaths" v-if="getCurrentPaths" />
    <v-main class="pl-0 pt-0">
      <!-- Breadcrumbs -->
      <v-col cols="12">
        <v-breadcrumbs divider="/" :items="breadcrumbItems">
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
              <v-icon
                v-if="item.text.toLowerCase() === currentCategory.toLowerCase()"
                class="deep-purple--text mr-2"
              >
                mdi-arrow-left
              </v-icon>
              <span
                v-if="item.text.toLowerCase() === currentCategory.toLowerCase()"
                class="text-capitalize deep-purple--text font-weight-bold"
              >
                {{ item.text }}
              </span>
              <span v-else>
                {{ item.text }}
              </span>
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </v-col>

      <v-container fluid class="ma-0 pa-0">
        <router-view />
      </v-container>
    </v-main>
    <enrollment-footer />
  </div>
</template>

<script>
import Header from '@/components/core/Header.vue';
import NavigationDrawer from '@/components/core/NavigationDrawer.vue';
import Footer from '@/components/core/Footer.vue';

export default {
  name: 'DetailLayout',
  components: {
    EnrollmentHeader: Header,
    EnrollmentNavigationDrawer: NavigationDrawer,
    EnrollmentFooter: Footer,
  },
  data: () => ({
    breadcrumbItems: [],
    currentCategory: '',
    // Menu data
    /**
     * The paths doesn't need an obligatory property for each section (teachers, parents, etc),
     * it depends if the current section detail have a submenu of routes like college does.
     * If the current section doesn't have a submenu of paths, it should not display
     * the navigation bar.
     */
    paths: {
      colleges: [
        { title: '📖 Estudiantes', path: 'students' },
        { title: '👨‍🏫 Profesores', path: 'teachers' },
        { title: '🧳 Directivos', path: 'directives' },
        { title: '📚 Libros Asociados', path: 'books', disabled: true },
        { title: '📁 Descarga Masivas', path: 'download', disabled: true },
      ],
    },
  }),
  computed: {
    getCurrentPaths() {
      return this.paths[this.currentCategory] || null;
    },
  },
  beforeMount() {
    /**
     * The routes for the enrollment dashboard detail must follow always the next pattern:
     *
     * /edit/{category}/{id of the element}
     *
     * category -> It refers to the category that we are working on, like students, colleges, etc.
     * id of the element -> It refers to the unique identificator of the element
     */
    const pathSegments = window.location.pathname.split('/');
    // eslint-disable-next-line prefer-destructuring
    this.currentCategory = pathSegments[2];

    this.breadcrumbItems.push({
      text: this.$t('colleges.all_colleges'),
      disabled: false,
      href: `/dashboard/${this.currentCategory}`,
    });
    this.breadcrumbItems.push({
      text: this.$t('colleges.detail'),
      disabled: true,
    });
  },
};
</script>
