import error from '@/i18n/es/error';
import colleges from '@/i18n/es/colleges';
import students from '@/i18n/es/students';
import reports from '@/i18n/es/reports';

export default {
  hello: 'Hola',
  footer_cs_love: 'Con mucho ❤️ para el equipo de Éxito al Cliente',
  login: {
    title: 'Administrador de colegios',
    subtitle: 'Ingresa tu usuario y contraseña',
    user_input: 'Usuario',
    password_input: 'Contraseña',
    login_btn: 'Acceder',
    register_btn: 'Registrar',
    password_required: 'Contraseña requerida',
    email_required: 'El correo electrónico es requerido.',
    email_format_validation: 'Formato de correo electrónico incorrecto.',
    dont_have_account: '¿No tienes una cuenta?',
    register_link: 'Registrate aquí',
    login_button: 'Iniciar Sesión',
  },
  register: {
    title: 'Registro',
    name: 'Nombre',
    surname: 'Apellido',
    email: 'Correo electrónico',
    password_input: 'Contraseña',
    repeat_password_input: 'Confirma tu contraseña',
    back: 'Atrás',
    password_not_match: 'Las contraseñas no coinciden',
    password_length: 'La contraseña debe tener al menos 8 caracteres',
    school_group: 'Selecciona el grupo de colegios',
  },
  layout: {
    colleges: 'Colegios',
    students: '🎓 Estudiantes',
    teachers: '🧑‍🏫 Profesores',
    edition: 'Edición',
  },
  error,
  colleges,
  students,
  reports,
  teachers: {
    label: 'profesores',
    name: 'Nombre',
    create: 'Crear Nuevo Profesor',
    general_information: '✨ Información del Profesor ✨',
    all_teachers: '🧑‍🏫 Todos los profesores',
  },
  common: {
    filter: 'Filtros',
    filter_by: 'Filtrar por',
    to_disable: 'Deshabilitar',
    to_enable: 'Habilitar',
    student: 'Alumno',
    disable_message: 'Al realizar esta acción la cuenta quedará inhabilitada. ¿Estás seguro de querer realizar esta acción?',
    enable_message: 'Al realizar esta acción la cuenta quedará habilitada. ¿Estás seguro de querer realizar esta acción?',
    cancel: 'Cancelar',
    close: 'Cerrar',
    send: 'Enviar',
    to_save: 'Guardar',
    to_select: 'Seleccionar',
    to_transfer: 'Transferir',
    field_required: 'Este campo es requerido',
    an_error_ocurred: 'Ha ocurrido un error',
    support_text: 'Error inesperado en la aplicación.',
    support_desc: 'Por favor tome captura de este error y envie al equipo de tecnología.',
  },
  subscription: {
    overdue: 'Atrasada',
  },
  navigation: {
    colleges: '🚸 Colegios',
    students: '🎓 Estudiantes',
    teachers: '🧑‍🏫 Profesores',
    parents: '👩‍👧 Padres',
    reports: '📊 Reportes',
    school_admin: 'Admin de Colegios',
  },
  app_header: {
    school_administration: 'Administración de Colegios',
    user_administration: 'Administración de usuarios 👥',
  },
  general: {
    error_msg: "Ha ocurrido un error. Por favor intertarlo nuevamente. Si el error persiste, pedir apoyo al equipo de soporte",
    loading: 'Cargando...',
    only_positive_number: "Sólo ingresar números positivos, por favor",
    is_required: "Este campo es requerido",
    valid_dates: "Por favor ingresar fechas válidas",
  },
};
