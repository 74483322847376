<template>
  <v-alert rounded outlined light color="deep-purple" class="pa-1 py-3" style="opacity: 0.6">
    <v-card-title class="ma-0 py-2 pl-1">
      <h6 class="deep-purple--text lighten-2 font-weight-semibold">
        App de familia
      </h6>
    </v-card-title>

    <v-card-text>
      <v-row align="center" justify="space-between" class="w-100" v-if="isAssociated">
        <span class="black--text font-weight-light">
          Cuenta asociada
          <v-icon color="green lighten-1" size="24">
            mdi-check-circle-outline
          </v-icon>
        </span>
      </v-row>
      <v-row align="center" justify="space-between" class="w-100" v-else>
        <span class="black--text font-weight-light">
          Sin asociar
        </span>
        <v-icon color="orange lighten-1" size="24">
          mdi-alert-circle-outline
        </v-icon>
      </v-row>
    </v-card-text>
  </v-alert>
</template>

<script>
export default {
  name: 'StudentFamilyState',
  props: {
    isAssociated: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
