<template>
  <v-row>
    <v-col cols="12" md="10">
      <v-card elevation="0" :loading="loading">
        <v-row class="white rounded pa-9">
          <v-row class="mb-3 pa-3">
            <span class="black--text">{{ $t('colleges.general_information') }}</span>
          </v-row>

          <v-col cols="12">
            <v-row class="my-0">
              <v-col cols="2">
                <span class="mr-2">ID</span>
                <span class="deep-purple--text font-weight-bold">{{ school.id }}</span>
              </v-col>
              <v-col cols="4">
                <span class="mr-2">{{ $t('colleges.creation') }}</span>
                <span class="deep-purple--text font-weight-bold">
                  {{ school.creationDate | date({ date: true, hour: false }) }}
                </span>
              </v-col>
              <v-col cols="4">
                <span class="mr-2">{{ $t('colleges.sale') }}</span>
                <span class="deep-purple--text font-weight-bold">{{ getPaymentByType() }}</span>
              </v-col>
              <v-col cols="2">
                <v-btn fab x-small color="deep-purple lighten-1" dark @click="save">
                  <v-icon>mdi-content-save</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-divider class="mb-12" />
          </v-col>

          <v-row align="center" justify="center">
            <v-col cols="12" md="5">
              <v-row align="start" class="d-flex flex-column">
                <span class="body black--text my-1">{{ $t('colleges.school_name') }}</span>
                <v-text-field
                  class="w-80"
                  filled
                  flat
                  dense
                  v-model="school.name"
                />
              </v-row>
              <v-row align="start" class="d-flex flex-column">
                <span class="body black--text my-1">{{ $t('colleges.country') }}</span>
                <v-autocomplete
                  class="w-80 mb-5"
                  filled
                  solo
                  dense
                  :items="countries"
                  item-value="id"
                  item-text="name"
                  v-model="school.baseCountry_id"
                  single-line
                  hide-details
                >
                  <template v-slot:selection="{ item }">
                    <img
                      :src="`${flagsPath}${item.iso_code}.png`"
                      alt="country flag"
                      width="35vh"
                      height="25vh"
                      class="mr-2"
                    />
                    <span class="">{{ item.name }}</span>
                  </template>
                  <template v-slot:item="{ item }">
                    <template>
                      <img
                        :src="`${flagsPath}${item.iso_code}.png`"
                        alt="country flag"
                        width="35vh"
                        height="25vh"
                        class="mr-2"
                      />
                      <v-list-item-content>
                        <v-list-item-title>{{ item.name | capitalize }}</v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-row>
              <v-row align="start" class="d-flex flex-column">
                <span align="start" class="body black--text my-1">{{ $t('colleges.school_group') }}</span>
                <v-select
                  v-model="school.school_group_id"
                  :label="$t('colleges.select_school_group')"
                  :items="schoolGroups"
                  single-line
                  hide-details
                  filled
                  solo
                  dense
                  @input="schoolGroupSelected($event)"
                  class="w-80 mb-5"
                />
              </v-row>
              <v-row align="start" class="d-flex flex-column">
                <span class="body black--text my-1">{{ $t('colleges.commune') }}</span>
                <v-autocomplete
                  class="w-80 mb-5"
                  filled
                  solo
                  dense
                  :items="counties"
                  v-model="school.county_id"
                  single-line
                  hide-details
                />
              </v-row>

              <v-row align="start" class="d-flex flex-column">
                <span class="body black--text my-1">RBD</span>
                <v-text-field class="w-80" filled flat dense v-model="school.rbd" />
              </v-row>

              <v-row align="start" class="d-flex flex-column">
                <span class="body black--text my-1">{{ $t('colleges.payment_identifier') }}</span>
                <v-autocomplete
                  class="w-80 mb-5"
                  filled
                  solo
                  dense
                  :items="formatPaymentByTypeIdentifierOptions"
                  v-model="school.payment_by_type_id"
                  single-line
                  hide-details
                />
              </v-row>

              <v-row align="start" class="d-flex flex-column">
                <span class="black--text">{{ $t('colleges.status') }}</span>
                <v-radio-group v-model="school.active" row>
                  <v-radio
                    :label="$t('colleges.active')"
                    :value="true"
                    color="deep-purple lighten-1"
                  />
                  <v-radio
                    :label="$t('colleges.inactive')"
                    :value="false"
                    color="deep-purple lighten-1"
                  />
                </v-radio-group>
              </v-row>

              <v-row align="center" justify="center" class="d-flex flex-column mt-8 mb-4">
                <span class="body black--text my-1">
                  {{ $t('colleges.current_period_of_operation') }}
                </span>
                <div>
                  <span class="deep-purple--text lighten-1 font-weight-bold body-1">
                    {{ school.operationYear}}
                  </span>
                  <v-btn text small class="ma-0 pa-0" @click="migrate">
                    <span class="black--text font-weight-bold text-decoration-underline">
                      {{ $t('colleges.migrate_period') }}
                    </span>
                  </v-btn>
                </div>
              </v-row>
            </v-col>
            <v-divider vertical class="mx-1" />

            <v-col v-if="schoolArrangement" cols="12" md="5">
              <school-arrangement-details :schoolArrangement="schoolArrangement" :readOnly="readOnly" />
              <v-col cols="12" class="pa-3" />
            </v-col>
          </v-row>

          <v-col cols="12">
            <v-divider />
            <v-row class="pa-3">
              <v-col cols="12" md="2">
                <v-icon size="60" color="deep-purple lighten-1">mdi-contacts</v-icon>
              </v-col>
              <v-col cols="12" md="4" class="d-flex align-start flex-column">
                <span>{{ $t('colleges.contact') }}</span>
                <v-text-field
                  class="ma-0 pa-0 w-80"
                  filled
                  flat
                  dense
                  v-model="school.contact"
                  hide-details
                />
              </v-col>
              <v-col cols="12" md="4" class="d-flex align-start flex-column">
                <span>{{ $t('colleges.phone') }}</span>
                <v-text-field class="w-80" filled flat dense v-model="school.phone" hide-details />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'

import { FLAGS_URL, DEFAULT_SCHOOL_DETAIL, PAYMENT_BY_TYPE } from '@/constants/data';
import { formatPaymentByTypeIdentifierOptions } from '@/constants/data/FormatInputMassiveLoad.json';
import SnackbarMixin from '@/mixins/snackbar';
import countryService from '@/services/country.service';
import countyService from '@/services/county.service';
import schoolService from '@/services/school.service';
import schoolGroupService from '@/services/schoolGroup.service';

import SchoolArrangementDetails from './schoolArragment/SchoolArrangementDetails.vue';
import enrollmentService from '@/services/enrollment.service'

export default {
  name: 'CollegeEdition',
  components: {
    SchoolArrangementDetails,
  },
  data: () => ({
    loading: true,
    isActive: false,
    status: true,
    flagsPath: `${FLAGS_URL}countries_flags/`,
    countries: [],
    counties: [],
    school: { ...DEFAULT_SCHOOL_DETAIL },
    formatPaymentByTypeIdentifierOptions,
    schoolArrangement: null,
    schoolGroups: [],
  }),
  mixins: [SnackbarMixin],
  computed: {
    ...mapState({
      currentUser: ({ currentUser }) => currentUser,
    }),
    readOnly() {
      return !!this.school.school_group_id
    }
  },
  async mounted() {
    const responses = await Promise.all(
      [countryService.findAll(), schoolService.findOne(this.$route.params.collegeId),
      ],
    )
      .catch((error) => console.error(error));

    if (responses[0] && responses[0].data) {
      this.countries = responses[0].data;
    }
    if (responses[1] && responses[1].data) {
      this.school = responses[1].data;
    }

    this.schoolArrangement = await this.getSchoolArrangement()
    await this.getSchoolGroups()
    this.loading = false;
  },
  methods: {
    migrate() {
      console.log('migrate');
    },
    async save() {
      this.loading = true

      try {
        const result = await schoolService.update(this.school)
                              .catch((error) => console.error(error))

        if (result && result.status == 200) {
          if(!this.readOnly ) {
            if (this.isValidSchoolArrangement()) {
              this.upsertSchoolArrangement(this.schoolArrangement)
            } else {
              this.snackbarError(this.$t('colleges.school_arrangement_is_invalid'))
              return
            }
          }
          this.snackbarSuccess(this.$t('colleges.success_edition_msg'))
        } else {
          this.snackbarError(this.$t('colleges.error_edition_msg'))
        }
      } catch {
        this.snackbarError(this.$t('colleges.error_edition_msg'))
      } finally {
        this.loading = false
      }
    },
    getPaymentByType() {
      const paymentByType = PAYMENT_BY_TYPE.find(
        (item) => item.id === this.school.payment_by_type_id,
      );
      return paymentByType ? paymentByType.name : '';
    },
    async getSchoolArrangement() {
      try {
       const currentSchool = this.$route.params.collegeId
        const schoolGroupId = this.school.school_group_id
        let result

        const { status, data } = this.readOnly
          ? await enrollmentService.findSchoolArrangementBySchoolGroupId(schoolGroupId)
          : await enrollmentService.findSchoolArrangementBySchoolId(currentSchool)

        if (status !== 200) {
          this.snackbarWarning(this.$t('general.error_msg'))
          return
        }

        result = {
          id: data.content.id,
          name: data.content.name,
          arrangementType: data.content.arrangementType,
          totalLicenses: data.content.totalLicenses,
          startDate: data.content.startDate,
          endDate: data.content.endDate,
        }

        return result
      } catch (error) {
        this.snackbarError(error.message);
      }
    },
    async upsertSchoolArrangement(schoolArrangementObj) {
      try {
        const currentSchool = this.$route.params.collegeId
        const payload = { ...schoolArrangementObj, schoolId: currentSchool, emailCustomerCs: this.currentUser.email }

        const { status, data } = await enrollmentService.upsertSchoolArrangement(payload)

        if (status !== 200) {
          this.snackbarWarning(this.$t('general.error_msg'))
          return
        }

        this.schoolArrangement = await this.getSchoolArrangement()

        return data.content
      } catch (error) {
        this.snackbarError(error.message);
      }
    },
    isValidSchoolArrangement() {
      return !!this.schoolArrangement.totalLicenses &&
      !!this.schoolArrangement.startDate && !!this.schoolArrangement.endDate &&
      this.isValidDates(this.schoolArrangement.startDate, this.schoolArrangement.endDate) &&
      !!this.schoolArrangement.arrangementType
    },
    async getSchoolGroups() {
      try {
        this.schoolGroups = await schoolGroupService.findList();
      } catch (error) {
        this.snackbarError(error.message);
      }
    },
    async schoolGroupSelected(schoolGroupId) {
      const { status, data } = await enrollmentService.findSchoolArrangementBySchoolGroupId(schoolGroupId)

      if (status !== 200) {
        this.snackbarWarning(this.$t('general.error_msg'))
        return
      }

      this.schoolArrangement = {
        id: data.content.id,
        name: data.content.name,
        arrangementType: data.content.arrangementType,
        totalLicenses: data.content.totalLicenses,
        startDate: data.content.startDate,
        endDate: data.content.endDate,
      }
    },
    isValidDates(startDate, endDate) {
      return startDate <= endDate
    }
  },
  watch: {
    'school.baseCountry_id': async function (countryId) {
      if (!countryId) {
        return;
      }
      this.loading = true;
      const response = await countyService.findListByCountryId(countryId)
        .catch((error) => console.error(error))
        .finally(() => { this.loading = false; this.school.county_id = null; });

      if (response && response.data) {
        this.counties = response.data;
      } else {
        this.counties = [];
      }
    },
  },
};
</script>

<style scoped>
.w-80 {
  width: 80%;
}
</style>
