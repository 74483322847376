<template>
  <v-container fluid class="ma-0 pa-0">
    <v-col cols="12" class="ma-0 pa-0">
      <v-row align="start" justify="start" justify-md="center" class="ma-0 pa-0" v-if="schoolData.id">
        <v-col cols="12" md="8" class="ma-0 pa-0">
          <college-course-card
            :loading="loading"
            :school="schoolData"
            :counts="schoolCounts"
            :resource-type="$t('students.label')"
            :country-name="_country.name"
            :country-id="_country.id"
            :country-flag="`${flagsPath}${_country.iso_code}.png`"
          />
        </v-col>
        <v-col cols="12" md="4" class="ma-0 pa-0">
          <college-course-actions
            @onCreate="openCreateNewCourseModal()"
            @onUpload="openMassiveLoadModal()"
            :schoolId="schoolData.id"
            :countryId="_country.id.toString()"
            :dataForExport="studentsListAsExport"
            :csvTitle="csvTitle"
            :csvHeaders="csvHeaders"
          />

        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12" class="px-3">
      <v-row v-if="schoolData.id">
        <college-course-student-table
          :list="studentsList"
          v-model="page"
          :courses="courses"
          :page-count="totalPage"
          :country-id="_country.id"
          :school-id="schoolData.id"
        />
          <!-- @reSearch="reSearch" -->
      </v-row>
    </v-col>

    <!------- ACTIONS MODALS ------->
    <massive-load-modal
      :school="schoolData"
      v-model="isMassiveLoadModalOpen"
      @onCancel="closeMassiveLoadModal"
    />

    <!-- Create new course modal -->
    <general-modal v-if="isCreateNewCourseModalOpen">
      <college-create-new-course-modal
        :country-id="country"
        :school="schoolData.id"
        @onSave="onCreateNewCourse"
        @onCancel="closeCreateNewCourseModal()"
      />
    </general-modal>

    <!-- Disable course modal -->
    <general-modal v-if="isDisableCourseModalOpen">
      <college-disable-course-modal
        @onDisable="onDisableCourse()"
        @onCancel="closeDisableCourseModal()"
      />
    </general-modal>
  </v-container>
</template>

<script>
import MassiveLoadModal from '@/components/colleges/modals/MassiveLoadModal.vue';
import CollegeCourseCard from '../../../components/colleges/courses/CollegeCourseCard.vue';
import CollegeCourseActions
  from '../../../components/colleges/courses/CollegeCourseActions.vue';
import CollegeCourseStudentTable
  from '../../../components/colleges/courses/CollegeCourseStudentTable.vue';
import GeneralModal from '../../../components/core/modals/GeneralModalContainer.vue';
import CollegeDisableCourseModal
  from '../../../components/colleges/modals/DisableCourseModal.vue';
import CollegeCreateNewCourseModal
  from '../../../components/colleges/modals/CreateNewCourseModal.vue';

import LoadingMixin from '../../../mixins/loading';
import SnackbarMixin from '../../../mixins/snackbar';
import schoolService from '../../../services/school.service';
import userService from '../../../services/user.service';
import courseService from '../../../services/course.service';
import { FLAGS_URL, RECORDS_PER_PAGE } from '../../../constants/data';
import { defaultCourseSchoolFilter } from '../../../constants/filters';

export default {
  name: 'CollegeCourseDetail',
  components: {
    CollegeCourseCard,
    CollegeCourseActions,
    CollegeCourseStudentTable,
    MassiveLoadModal,
    GeneralModal,
    CollegeDisableCourseModal,
    CollegeCreateNewCourseModal,
  },
  async mounted() {
    this.loader();
    const { collegeId } = this.$route.params;
    const { status, data } = await schoolService.findOne(collegeId);
    if (status === 200) {
      this.schoolData = { ...data };
      this.country = this.schoolData.baseCountry_id;
      this.csvTitle = `${this.schoolData.id} ${this.schoolData.name} - Listado de estudiantes`;
      const {
        data: allStudentsData,
        status: allUsersStatus,
      } = await userService.findAllBySchool(this.page, collegeId, true);
      if (allUsersStatus === 200) {
        this.studentsList = [...allStudentsData];
        this.studentsListAsExport = this.studentsList.map((st) => {
          const studentToExport = {};
          Object.keys(this.csvHeaders).forEach((header) => {
            studentToExport[header] = st[header];
          });
          return {
            ...studentToExport,
            password: st.passwordTmp,
            course: st.course ? st.course.name : null,
            active: st.active ? 'Activo' : 'Inactivo',
            lastname: `${st.lastname} ${st.lastname2nd}`,
          };
        });
        this.totalPage = parseInt(allStudentsData.length / RECORDS_PER_PAGE, 10);
        this.getCourses(collegeId);
      }
    }
    this.loader();
  },
  data: () => ({
    isDisableModalOpen: false,
    isCreateNewCourseModalOpen: false,
    isMassiveLoadModalOpen: false,
    isDisableCourseModalOpen: false,
    country: 0,
    school: 0,
    section: 0,
    grade: 0,
    schoolData: {},
    flagsPath: `${FLAGS_URL}countries_flags/`,
    params: { ...defaultCourseSchoolFilter },
    studentsList: [],
    courses: [],
    page: 1,
    totalPage: 0,
    itemsLength: 0,
    studentsListAsExport: [],
    csvTitle: '',
    csvHeaders: {
      id: { title: 'ID' },
      dni: { title: 'DNI' },
      firstname: { title: 'Nombre' },
      lastname: { title: 'Apellidos' },
      course: { title: 'Curso' },
      active: { title: 'Estado' },
      password: { title: 'Contraseña ' },
      email: { title: 'Correo' },
      username: { title: 'Nombre de usuario' },
      creationDate: { title: 'Fecha de creación' },
      birthDay: { title: 'Fecha de nacimiento' },
      lastAccessDate: { title: 'Fecha último ingreso' },
    },
  }),
  computed: {
    _country() {
      if (this.schoolData.country) return this.schoolData.country;
      return {};
    },
    schoolCounts() {
      return {
        active: this.studentsList.filter((student) => student.active == true).length,
        total: this.studentsList.length,
        totalCourses: this.courses.length,
      };
    },
  },
  methods: {
    // Create new course
    onCreateNewCourse(success) {
      if (success) {
        this.snackbarSuccess(this.$t('colleges.course_saved_success'));
        this.closeCreateNewCourseModal();
      } else this.snackbarError(this.$t('common.an_error_ocurred'));
    },
    openCreateNewCourseModal() {
      this.isCreateNewCourseModalOpen = true;
    },
    openMassiveLoadModal() {
      this.isMassiveLoadModalOpen = true;
    },
    closeCreateNewCourseModal() {
      this.isCreateNewCourseModalOpen = false;
    },
    closeMassiveLoadModal() {
      this.isMassiveLoadModalOpen = false;
    },
    // Disable course
    onDisableCourse() {
      console.log('TODO');
    },
    openDisableCourseModal() {
      this.isDisableCourseModalOpen = true;
    },
    closeDisableCourseModal() {
      this.isDisableCourseModalOpen = false;
    },
    async getCourses(schoolId) {
      try {
        const { data, status } = await courseService.findAllBySchool(schoolId);
        if (status === 200) this.courses = [...data];
        else this.courses = [];
      } catch (e) {
        console.log(e);
      }
    },

  },
  mixins: [LoadingMixin, SnackbarMixin],
};
</script>
