export const FLAGS_URL = process.env.VUE_APP_RESOURCES_URL;

export const PAYMENT_BY_TYPE = [
  { id: 1, name: 'PPFF', type: 0 },
  { id: 2, name: 'Colegio', type: 1 },
  { id: 3, name: 'Distribuidor', type: 2 },
];

export const DEFAULT_SCHOOL_DETAIL = {
  active: false,
  baseCountry_id: 0,
  contact: '',
  county_id: '',
  creationDate: '',
  id: '',
  name: '',
  operationYear: '',
  owner_id: '',
  payment_by_type_id: '',
  phone: '',
  price: '',
  rbd: '',
  school_group_id: '',
  sequence_name: '',
};

export const TRANSFER_COURSE_FORM = { grade: '', section: null };
export const TRANSFER_COLLEGE_FORM = {
  grade: '', section: null, school: '', country: '',
};

export const DEFAULT_REPORT_LIST_ITEM = {
  title: '',
  description: '',
};

export const COUNTRIES_CUSTOMER_ID = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 16, 32, 34];

export const MAX_PER_PAGE = 5000;
export const RECORDS_PER_PAGE = 15;
