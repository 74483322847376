<template>
  <v-card flat rounded elevation="0" class="pa-6 ma-3" max-width="420" color="grey lighten-5" :loading="loading">
    <v-form lazy-validation v-model="valid" ref="form">

      <v-card-title>
        <v-col cols="12">
          <v-row align="center" justify="center">
            <h2 class="font-weight-bold grey--text ligthten-2">
              Transferencia de colegio
            </h2>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-row align="center" justify="center">
            <p class="body-2 font-weight-light grey--text ligthten-2">
              Selecciona el nuevo colegio destino.
            </p>
          </v-row>
          <div class="py-1">
            <p class="error--text disclaimer">* Para obtener el listado de los colegios y grados
              debe
              seleccionar un País</p>
          </div>
        </v-col>
      </v-card-title>

      <v-card-text>
        <v-col cols="12">
          <v-row align="center" justify="center" class="flex-md-nowrap">
            <v-col cols="12" md="3">
              <span class="pa-0 ma-0">{{$t('students.country')}}</span>
            </v-col>
            <CountryInput :placeholder="$t('common.to_select')" :countries="countries" solo
                          flat
                          dense
                          color="white"
                          light
                          outlined
                          class="pa-0 ma-0 ml-3"
                          :rounded="false"
                          v-model="value.country"
                          @input="onInput('country')"
                          :rules="fieldRequired"
                          hide-details="auto"></CountryInput>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-row align="center" justify="center" class="pt-5 flex-md-nowrap">
            <v-col cols="12" md="3">
              <span class="pa-0 ma-0">Colegio</span>
            </v-col>
            <v-autocomplete
              :placeholder="$t('common.to_select')"
              solo
              flat
              dense outlined
              color="white"
              :items="schools"
              v-model="value.school"
              @input="onInput('school')"
              item-value="value"
              item-text="text"
              light
              class="pa-0 ma-0 ml-3"
              :rules="fieldRequired"
              hide-details="auto"
            />
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-row align="center" justify="center" class="pt-5 flex-md-nowrap">
            <v-col cols="12" md="3">
              <span class="pa-0 ma-0">Grado</span>
            </v-col>
            <v-autocomplete
              :placeholder="$t('common.to_select')"
              solo
              flat
              dense
              color="white"
              light
              outlined
              :items="grades"
              v-model="value.grade"
              @input="onInput('grade')"
              item-value="grade_id"
              item-text="name"
              class="pa-0 ma-0 ml-3"
              :rules="fieldRequired"
              hide-details="auto"
            />
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-row align="center" justify="center" class="pt-5 flex-md-nowrap">
            <v-col cols="12" md="3">
              <span class="pa-0 ma-0">{{$t('students.section')}}</span>
            </v-col>
            <v-autocomplete
              :placeholder="$t('common.to_select')"
              solo
              flat
              dense
              outlined
              color="white"
              :items="sections"
              v-model="value.section"
              @input="onInput('section')"
              item-value="key"
              item-text="value"
              light
              class="pa-0 ma-0 ml-3"
              :rules="sectionRequired"
              hide-details="auto"
            />
          </v-row>
        </v-col>

        <v-row align="center" justify="center" class="mt-9">
          <v-btn
            text
            small
            color="deep-purple lighten-2"
            @click="onCancel()"
          >
            {{ $t('common.cancel') }}
          </v-btn>
          <v-btn
            rounded
            outlined
            small
            color="deep-purple lighten-2"
            @click="onTransfer()"
          >
            {{ $t('common.to_transfer') }}
          </v-btn>
        </v-row>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
import CountryInput from '../../core/fields/CountryInput';
import countryService from '../../../services/country.service';
import schoolService from '../../../services/school.service';
import gradeService from '../../../services/courseGrade.service';
import sectionService from '../../../services/section.service';

export default {
  name: 'CollegeTransferenceModal',
  components: { CountryInput },
  props: {
    countryId: { type: [String, Number], default: 0 },
    value: { type: Object, default: () => ({}) },
    loading: { type: Boolean, default: false },
  },
  async mounted() {
    const responseArray = await Promise.all([countryService.findAll(), sectionService.findAll()]);
    if (responseArray[0]) this.countries = [...responseArray[0].data];
    if (responseArray[1]) this.sections = ([...responseArray[1].data]);

    await this.getSchools(this.value.country);
    await this.getGrades(this.value.country);
  },
  data: () => ({
    countries: [],
    schools: [],
    grades: [],
    sections: [],
    params: {
      country: '', school: '', grade: '', section: '',
    },
    valid: true,
  }),
  computed: {
    fieldRequired() {
      return [(v) => !!v || this.$t('common.field_required')];
    },
    sectionRequired() {
      return [(v) => v !== null || this.$t('common.field_required')];
    },
  },
  methods: {
    onCancel() {
      this.$emit('onCancel');
    },
    onTransfer() {
      if (this.$refs.form.validate()) this.$emit('onTransfer');
    },
    async getGrades(country) {
      if (country) {
        const { status, data } = await gradeService.findAllByCountry(country);
        if (status === 200) {
          this.grades = [...data];
        } else this.grades = [];
        return;
      }
      this.grades = [];
    },
    async getSchools(country) {
      if (country) {
        const { status, data } = await schoolService.getListByCountry(country);
        if (status === 200) {
          this.schools = [...data];
        } else this.schools = [];
        return;
      }
      this.schools = [];
    },
    onInput(key = '') {
      const _value = { ...this.value, [key]: this.value[key] };
      this.$emit('input', _value);
    },
  },
  watch: {
    'value.country': async function (nValue) {
      await this.getGrades(nValue);
      await this.getSchools(nValue);
    },
  },
};
</script>
<style scoped>
  .disclaimer {
    font-size: 12px;
    line-height: 14px;
  }
</style>
