<template>
  <v-col>
    <v-row class="d-flex flex-column mb-4">
      <h3 align="start" class="body black--text my-1">{{ $t('colleges.school_arrangement') }}</h3>
    </v-row>
    <v-row class="d-flex flex-column">
      <span align="start" class="body black--text my-1">{{ $t('colleges.arrangement_name') }}</span>
      <v-text-field
        v-model="schoolArrangement.name"
        class="w-4/5"
        filled
        flat
        dense
        :readonly="readOnly"
      />
    </v-row>

  <v-row class="d-flex flex-column mb-4">
    <span align="start" class="body black--text my-1">{{ $t('colleges.enrollment_type') }}*</span>
    <v-select
      v-model="schoolArrangement.arrangementType"
      :label="$t('colleges.select_enrollment_type')"
      :items="enrollmentTypes"
      single-line
      hide-details
      filled
      solo
      dense
      required
      :readonly="readOnly"
      @input="validateEnrollmentType($event)"
    />
    <span v-if="showErrorInEnrollmentType" align="start" class="body red--text my-1">{{ $t('general.is_required') }}</span>
  </v-row>

    <v-row class="d-flex flex-column">
      <span align="start" class="body black--text my-1">{{ $t('colleges.total_licenses') }}*</span>
      <v-text-field
        v-model="schoolArrangement.totalLicenses"
        class="w-80"
        filled
        flat
        dense
        required
        type="number"
        min="0"
        :readonly="readOnly"
        @input="validateLicensesInput($event)"
      />
      <span v-if="showError" align="start" class="body red--text my-1">{{ errorMessage }}</span>
    </v-row>

    <v-row align="start" class="d-flex flex-column mb-10">
      <span class="body black--text my-1">{{ $t('colleges.arrangement_range_dates') }}*</span>
      <v-col class="d-flex flex-column">
        <v-col>
          <v-menu
            v-model="menuStartDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="schoolArrangement.startDate"
                label="Escoger fecha de Inicio"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="schoolArrangement.startDate"
              @input="menuStartDate = false"
              :disabled="readOnly"
              @change="validateDateInRange($event, 'startDate')"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col>
          <v-menu
            v-model="menuEndDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="schoolArrangement.endDate"
                label="Escoger fecha de Finalización"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="schoolArrangement.endDate"
              @input="menuEndDate = false"
              :disabled="readOnly"
              @change="validateDateInRange($event, 'endDate')"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-col>
      <span v-if="showErrorInDates" align="start" class="body red--text my-1">{{ $t('general.valid_dates') }}</span>
    </v-row>
    <v-row class="mb-10"/>
    <v-row class="mb-10"/>
  </v-col>
</template>

<script>

export default {
  name: 'SchoolArrangementDetails',
  props: {
    schoolArrangement: { type: Object, default: () => {} },
    readOnly: { type: Boolean, default: false },
  },
  data() {
    return {
      menuStartDate: false,
      menuEndDate: false,
      showError: false,
      errorMessage: null,
      showErrorInDates: false,
      showErrorInEnrollmentType: false,
    }
  },
  computed: {
    enrollmentTypes() {
      return [
        { text: 'Trial', value: 'Trial' },
        { text: 'Cliente', value: 'Client' },
      ]
    },
  },
  methods: {
    validateLicensesInput(value) {
      if(!!!value) {
        this.showError = true
        this.errorMessage = this.$t('general.is_required')
        return
      }

      if(Number(value) < 0) {
        this.showError = true
        this.errorMessage = this.$t('general.only_positive_number')
        return
      }

      this.showError = false
    },
    validateDateInRange(value, unit) {
      if(unit === 'startDate') {
        this.showErrorInDates =  this.schoolArrangement.startDate > this.schoolArrangement.endDate
      }

      if(unit === 'endDate') {
        this.showErrorInDates =  this.schoolArrangement.endDate < this.schoolArrangement.startDate
      }
    },
    validateEnrollmentType(value) {
      this.showErrorInEnrollmentType = !!!value
    },
  },
}

</script>
