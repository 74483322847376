const colleges = {
  all_colleges: '🚸 Todos los colegios',
  create: 'Crear Nuevo Colegio',
  active: 'Activo',
  inactive: 'Inactivo',
  detail: 'Detalle',
  migrate_period: 'Migrar periodo',
  disable_college: 'Deshabilitar colegio(s)',
  filters: 'Filtros',
  clean_up: 'Limpiar',
  search: 'Buscar',
  status: 'Estatus',
  country: 'País',
  school_name: 'Nombre del colegio',
  edit: 'Editar',
  general_information: 'Información general',
  creation: 'Creación',
  sale: 'Venta',
  commune: 'Comuna',
  contact: 'Contacto',
  phone: 'Teléfono',
  analytics: '📊 Panel de Estadísticas 🎓  ',
  current_period_of_operation: 'Periodo actual de operación',
  success_edition_msg: 'El colegio se editó con éxito.',
  error_edition_msg: '¡Algo salió mal! Intenta realizar esta misma acción más tarde.',
  students_migrated: 'Los estudiantes han sido migrados',
  massive_load: 'Carga Masiva',
  new_course: 'Crear curso',
  course_saved_success: 'Curso guardado de manera exitosa',
  school_id: 'ID del colegio',
  payment_identifier: 'Tipo de pagador',
  excel_format: 'Formato del Excel',
  see_an_example: 'Ver ejemplo',
  users_have_DNI_label: '¿Los usuarios tienen DNI?',
  users_have_DNI_yes: 'Sí',
  users_have_DNI_no: 'No',
  dissable_old_data_label: '¿Deshabilitar los datos viejos?',
  dissable_old_data_yes: 'Sí',
  dissable_old_data_no: 'No',
  file: 'Archivo',
  file_placeholder: 'Elija un archivo...',
  error_list: 'Lista de errores',
  successful_upload: '¡Carga exitosa!',
  save_as_txt: 'Guardar como txt',
  massive_load_warning: 'Se desactivarán los estudiantes que no estén en el documento.',
  select_group: 'Selecciona grupo de colegios',
  reports: 'Reportes',
  reports_see_column: 'Ver',
  school_arrangement: "Información del Acuerdo con el Colegio",
  arrangement_name: 'Nombre del Acuerdo',
  total_licenses: 'Total de licencias',
  arrangement_range_dates: 'Fechas del Acuerdo',
  enrollment_type: 'Tipo de Acuerdo',
  select_enrollment_type: 'Selecciona el tipo de acuerdo',
  create_school_group: "Crear conglomerado",
  school_group: "Conglomerado",
  select_school_group: "Selecciona un conglomerado",
  school_arrangement_is_invalid: "La información del acuerdo con el colegio es inválida",
};

export default colleges;
