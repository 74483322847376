<template>
  <v-dialog v-model="value" max-width="70vw">
    <v-card :loading="loading" :disabled="loading">
      <v-card-title class="justify-center">
          <span class="text-h5 sp">{{ $t('teachers.create') }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <iframe :src="retoolURL" width="100%" height="500px"></iframe>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
const RETOOL_CREATE_TEACHER = 'https://beereaders.retool.com/embedded/public/31e327bb-df0c-4d5d-9198-b9bd748ea122';

export default {
  name: 'CreateTeacherModal',
  props: {
    value: { type: Boolean, default: false },
  },
  data: () => ({
    loading: false,
    retoolURL: RETOOL_CREATE_TEACHER,
  }),
  methods: {
    close() {
      this.errorResponse = false;
      this.file = null;
      this.errorsList = null;
      this.$emit('onCancel');
    },
  },
};
</script>

<style scoped>
span.sp{
    font-family: "Space Grotesk" !important;
}
</style>
