<template>
  <v-app-bar
    flat
    app
    color="gray"
  >
    <v-row align="center" justify="space-between" class="pa-6">
      <v-img class="clickable" @click="goHome()" src="https://www.beereaders.com/logo.svg" width="90" max-width="50" />
      <v-spacer />
      <p class="title">{{ appHeader }}</p>
      <v-spacer />
      <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on }">
            <v-card flat>
              <v-row align="center" justify="center" v-on="on">
                <v-card-subtitle class="user-name" v-if="currentUser">
                  {{currentUser.displayName}}
                </v-card-subtitle>
                <v-avatar color="primary" size="30" >
                  <img :src="currentUser.photoURL" alt="">
                </v-avatar>
              </v-row>
            </v-card>
            </template>

            <v-list>
              <v-list-item class="clickable">
                <v-list-item-title @click="logout">Cerrar sesión</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

    </v-row>
  </v-app-bar>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'Header',
  props: {
    header: {
      type: String,
    },
  },
  computed: {
    ...mapState({
      currentUser: ({ currentUser }) => currentUser,
    }),
    appHeader() {
      if (this.header) {
        return this.header;
      }

      return this.$t('app_header.user_administration');
    },
  },
  methods: {
    ...mapActions(['signOut']),
    logout() {
      this.signOut();
      // this.$router.replace('/login');
    },
    goHome() {
      this.$router.push('/');
    },
  },
};

</script>

<style lang="scss" scoped>
.title {
  font-family: "Space Grotesk", sans-serif !important;
  margin-top: 15px;
}
.user-name{
  border-radius: 100%;
  cursor: pointer;
  &:hover{
    color: #1876d2;
  }
}

.clickable{
  cursor: pointer;
}
</style>
