import { AxiosError } from 'axios';
import BaseService from './base.service';

const baseService = new BaseService('firebase');

export interface SaveFirebaseUser {
  displayName: string;
  email: string;
  password?: string;
  schoolGroupId?: number;
  fromGoogle?: boolean;
}

export default {
  registerUser: async (userData: SaveFirebaseUser) => {
    try {
      const { data } = await baseService.post('register_user', userData);
      return data;
    } catch (error) {
      const axiosError = error as AxiosError;
      throw axiosError.response?.data || axiosError;
    }
  },
  getUser: async (email: string, withToken = false) => {
    try {
      const { data } = await baseService.get(`get_user/${email}?withToken=${withToken}`);
      return data;
    } catch (error) {
      const axiosError = error as AxiosError;
      throw axiosError.response?.data || axiosError;
    }
  },
  updateUser: async (userData: SaveFirebaseUser) => {
    try {
      const { data } = await baseService.put('update_user', userData);
      return data;
    } catch (error) {
      const axiosError = error as AxiosError;
      throw axiosError.response?.data || axiosError;
    }
  },
};
