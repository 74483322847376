import Vue from 'vue';
import Vuex from 'vuex';
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  updateProfile,
  signInWithCustomToken,
} from 'firebase/auth';
import VuexPersist from 'vuex-persist';
import enrollment from './enrollment';
import { ALL_ERRORS, findError } from '../constants/errors';
import common from './common';
import snackbar from './snackbar';
import firebaseService from '../services/firebase.service';

Vue.use(Vuex);

const vuexPersist = new VuexPersist({
  // Al cambiar esta llave debe cambiarse también en "@/utils/request" para poder obtener el token
  key: 'beereaders-enrollment-admin',
  storage: window.localStorage,
  reducer: (state) => ({
    // @ts-ignore
    currentUser: state.currentUser,
    // @ts-ignore
    userLogged: state.userLogged,
    // @ts-ignore
    common: state.common,
    // @ts-ignore
    enrollment: state.enrollment,
    // @ts-ignore
    userToken: state.userToken,
  }),
});

export default new Vuex.Store({
  state: {
    currentUser: null,
    userLogged: false,
    registerSuccess: null,
    loginError: null,
    registerError: null,
    loading: false,
    userToken: null,
    showSchoolGroupDialog: false,
  },
  mutations: {
    SET_CURRENT_USER(state: any, payload: any) {
      state.currentUser = { ...payload };
    },
    SET_USER_TOKEN(state: any, payload: any) {
      state.userToken = payload;
    },
    SET_USER_LOGGED(state: any, payload: any) {
      state.userLogged = payload;
    },
    SET_REGISTER_SUCCESS(state: any, payload: any) {
      state.registerSuccess = payload;
    },
    SET_LOGIN_ERROR(state: any, payload: any) {
      state.loginError = payload;
    },
    SET_REGISTER_ERROR(state: any, payload: any) {
      state.registerError = payload;
    },
    SET_LOADING(state: any, payload: boolean) {
      state.loading = payload;
    },
    SHOW_SCHOOL_GROUP_DIALOG(state: any, payload: boolean) {
      state.showSchoolGroupDialog = payload;
    },
  },
  actions: {
    async register(
      { commit },
      {
        name,
        surname,
        email,
        password,
        schoolGroupId,
      },
    ) {
      commit('SET_LOADING', true);
      const auth = getAuth();
      await firebaseService.registerUser({
        displayName: `${name} ${surname}`,
        email,
        password,
        schoolGroupId,
        fromGoogle: false,
      })
        .then(async (user) => {
          await signInWithCustomToken(auth, user.token);
          return user;
        })
        .then((user) => {
          commit('SET_CURRENT_USER', user);
          commit('SET_USER_LOGGED', user !== null);
          commit('SET_REGISTER_SUCCESS', true);
          commit('SET_USER_TOKEN', user.token);
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          const errorKey = findError(errorCode);
          commit('SET_REGISTER_ERROR', { errorCode, errorMessage, error: errorKey?.key });
          commit('SET_USER_LOGGED', false);
          commit('SET_CURRENT_USER', null);
        });
      commit('SET_LOADING', false);
    },
    async signInCredentials({ commit }, { email, password }) {
      commit('SET_LOADING', true);
      const auth = getAuth();
      await signInWithEmailAndPassword(auth, email, password)
        .then(() => firebaseService.getUser(email, true))
        .then((user) => {
          if (!user.email?.endsWith('@beereaders.com') && !user.schoolGroupId) {
            commit('SHOW_SCHOOL_GROUP_DIALOG', true);
          }
          commit('SET_CURRENT_USER', user);
          commit('SET_USER_LOGGED', user !== null);
          commit('SET_USER_TOKEN', user.token);
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          const errorKey = findError(errorCode);
          commit('SET_LOGIN_ERROR', { errorCode, errorMessage, error: errorKey?.key });
          commit('SET_USER_LOGGED', false);
          commit('SET_CURRENT_USER', null);
        });
      commit('SET_LOADING', false);
    },
    async signIn({ commit, dispatch }) {
      commit('SET_LOADING', true);
      const auth = getAuth();
      const provider = new GoogleAuthProvider();
      provider.addScope('https://www.googleapis.com/auth/userinfo.email');
      await signInWithPopup(auth, provider)
        .then(async (result) => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const firebaseUser = await firebaseService.getUser(result.user.email!);
          if (firebaseUser) {
            if (!firebaseUser.email?.endsWith('@beereaders.com') && !firebaseUser.schoolGroupId) {
              commit('SHOW_SCHOOL_GROUP_DIALOG', true);
            }
            return {
              firebaseUser,
              result,
            };
          }

          await dispatch('signOut');
          const registeredUser = await firebaseService.registerUser({
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            displayName: result.user.displayName!,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            email: result.user.email!,
            fromGoogle: true,
          });
          if (!result.user.email?.endsWith('@beereaders.com')) {
            commit('SHOW_SCHOOL_GROUP_DIALOG', true);
          }
          return {
            firebaseUser: registeredUser,
            result,
          };
        })
        .then(async ({ result, firebaseUser }) => {
          const credential = GoogleAuthProvider.credentialFromResult(result);
          const token = credential?.accessToken;
          commit('SET_CURRENT_USER', firebaseUser);
          commit('SET_USER_LOGGED', firebaseUser !== null);
          commit('SET_USER_TOKEN', token);
        }).catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The AuthCredential type that was used.
          const credential = GoogleAuthProvider.credentialFromError(error);
          commit('SET_LOGIN_ERROR', { errorCode, errorMessage, error: credential });
          commit('SET_USER_LOGGED', false);
          commit('SET_CURRENT_USER', null);
        });
      commit('SET_LOADING', false);
    },
    signOut({ commit }) {
      const auth = getAuth();
      signOut(auth).then(() => {
        commit('SET_CURRENT_USER', null);
        commit('SET_USER_LOGGED', false);
        commit('SET_USER_TOKEN', null);
      }).catch(({ code, message }) => {
        const error = findError(code);
        commit('SET_LOGIN_ERROR', { code, message, error: error?.key });
        commit('SET_USER_LOGGED', false);
        commit('SET_CURRENT_USER', null);
      });
    },
    setShowSchoolGroupDialog({ commit }, payload) {
      commit('SHOW_SCHOOL_GROUP_DIALOG', payload);
    },
    async updateUser({ commit, state, dispatch }, { schoolGroupId }) {
      commit('SET_LOADING', true);
      const { currentUser } = state;
      if (!currentUser) {
        return;
      }
      await firebaseService.updateUser({
        ...currentUser,
        schoolGroupId,
      })
        .then((updatedUser) => {
          commit('SET_CURRENT_USER', updatedUser);
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          const errorKey = findError(errorCode);
          commit('SHOW_SCHOOL_GROUP_DIALOG', true);
          dispatch('snackbar/showError', { errorCode, errorMessage, error: errorKey?.key }, { root: true });
        });
      commit('SET_LOADING', false);
    },
  },
  modules: {
    enrollment,
    common,
    snackbar,
  },
  plugins: [vuexPersist.plugin],
});
