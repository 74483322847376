<template>
  <v-expansion-panels
    v-model="isOpen"
    multiple
    flat
  >
    <v-expansion-panel>
      <v-expansion-panel-header>
        <v-row align="center" justify="end"
               class="px-3 deep-purple--text lighten-3 font-weight-bold">
          {{ $t('common.filter') }}
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-card
          color="deep-purple lighten-3"
          elevation="6"
          class="pa-2"
        >
          <v-form>
            <div>
              <v-row align="center" justify="space-between">
                <v-col class="d-flex flex-row align-items-center justify-content-start" cols="12">
                  <v-col cols="3">
                    <CountryInput v-model="value.country" :placeholder="$t('students.country')"
                                  :countries="countries"
                                  @input="manageInput('country')"
                                  hide-details
                                  single-line
                                  :filled="false"
                    ></CountryInput>
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-autocomplete
                      placeholder="Colegio"
                      flat
                      solo
                      light
                      rounded
                      dense
                      :items="schoolList"
                      v-model="value.schoolId"
                      @input="manageInput('school')"
                      color="white"
                      single-line
                      hide-details
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-text-field
                      placeholder="Nombre"
                      solo
                      dense
                      flat
                      light
                      rounded
                      hide-details
                      v-model="value.firstname"
                      @input="manageInput('firstname')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      placeholder="Apellidos"
                      solo
                      dense
                      flat
                      light
                      rounded
                      hide-details
                      v-model="value.lastname"
                      @input="manageInput('lastname')"
                    ></v-text-field>
                  </v-col>
                </v-col>
                <v-col class="d-flex flex-row align-items-center justify-content-start" cols="12">
                  <v-col cols="12" md="3">
                    <v-text-field
                      placeholder="Nombre de Usuario"
                      solo
                      dense
                      flat
                      light
                      rounded
                      hide-details
                      v-model="value.username"
                      @input="manageInput('username')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      placeholder="DNI"
                      solo
                      dense
                      flat
                      light
                      rounded
                      hide-details
                      v-model="value.dni"
                      @input="manageInput('dni')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6"
                         class="d-flex flex-column flex-md-row align-items-center justify-content-start ">
                    <span>Estatus:</span>

                    <v-btn-toggle v-model="value.active" @change="manageInput('active')" dense
                                  class="mx-3" rounded>
                      <v-btn :value="1">
                        Activo
                      </v-btn>
                      <v-btn :value="0">
                        Inactivo
                      </v-btn>
                    </v-btn-toggle>
                  </v-col>
                </v-col>
              </v-row>
              <v-row class="px-2">

                <v-col cols="12"
                       class="d-flex flex-column justify-content-end flex-md-row ml-auto px-2">
                  <v-btn
                    text
                    color="white"
                    class="text-decoration-underline"
                    small
                    rounded
                    @click="reset"
                  >
                    Limpiar
                  </v-btn>
                  <v-btn
                    class="search-btn"
                    outlined
                    rounded
                    small
                    @click="search"
                  >
                    Buscar 🔍
                  </v-btn>
                </v-col>

              </v-row>
            </div>
          </v-form>

        </v-card>
      </v-expansion-panel-content>

    </v-expansion-panel>
  </v-expansion-panels>

</template>

<script>
import { mapActions, mapState } from 'vuex';
import countryService from '../../../services/country.service';
import schoolService from '../../../services/school.service';
import { FLAGS_URL, COUNTRIES_CUSTOMER_ID } from '../../../constants/data';
import { defaultStudentFilter } from '../../../constants/filters';

import CountryInput from '../fields/CountryInput.vue';
import SnackbarMixin from '../../../mixins/snackbar';

export default {
  name: 'StudentsFilter',
  components: { CountryInput },
  props: {
    value: { type: Object, default: () => ({ ...defaultStudentFilter }) },
  },
  data: () => ({
    isOpen: [],
    isStatusActive: true,
    flagsPath: `${FLAGS_URL}countries_flags/`,
    country_id: null,
  }),
  async mounted() {
    const responseArray = await Promise.all([countryService.findAll()]);
    if (responseArray[0]) this.setCountries([...responseArray[0].data]);
  },
  watch: {
    'value.country': {
      async handler(nValue) {
        // do stuff
        this.country_id = nValue;
        await this.getSchoolList(nValue);
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions('common', ['setCountries', 'setSchools', 'setSchoolList']),
    manageInput(key) {
      const _value = { ...this.value, [key]: this.value[key] };
      this.$emit('input', _value);
    },
    async getSchoolList(country) {
      try {
        if (country) {
          const { status, data } = await schoolService.getListByCountry(country);
          if (status === 200) this.setSchoolList([...data]);
          else this.setSchoolList([]);
        }
      } catch (e) {
        this.snackbarError(e.message);
      }
    },
    capitalize(name) {
      return name
        .split(' ')
        .map(
          (country) => `${country.charAt(0).toUpperCase()}${country.slice(1).toLowerCase()}`,
        )
        .join(' ');
    },
    reset() {
      Object.entries(this.value).map(([key, value]) => this.value[key] = defaultStudentFilter[key]);
    },
    search() {
      this.$emit('search');
    },
  },
  computed: {
    ...mapState({
      countries: ({ common: { countries } }) => countries.filter((country) => COUNTRIES_CUSTOMER_ID.includes(country.id)),
      schoolList: ({ common: { schoolList } }) => schoolList,
    }),
  },
  mixins: [SnackbarMixin],
};
</script>
<style scoped>
.search-btn{
  height: 40px;
  top: -6px;
  width: 180px;
  background: #4b398a;
  color: white;
}
</style>
