<template>
  <v-form lazy-validation v-model="valid" ref="form">
    <v-row>
      <v-col cols="12" md="9">
        <v-card class="d-flex flex-column white rounded pa-6 d-flex items-center justify-start"
                :loading="loading">
          <v-row align="center" justify="space-between" class="mb-0">
            <v-col cols="12" md="10" class="d-flex align-items-start">
              <v-card-title class="secondary--text font-weight-bold">
                {{$t('students.general_information')}}
              </v-card-title>
            </v-col>
            <v-col cols="12" md="2" class="d-flex align-items-start justify-content-end">
                <v-btn fab small color="deep-purple lighten-1" class="ml-auto" dark
                       :disabled="loading"
                       @click="save">
                  <v-icon>
                    mdi-content-save
                  </v-icon>
                </v-btn>
            </v-col>
          </v-row>
          <v-col cols="12" class="pa-0">
            <v-row align="center" justify="center" class="py-3">
              <v-col>
                <span>ID </span>
                <span class="deep-purple--text font-weight-bold">{{studentId}}</span>
              </v-col>
              <v-col>
                <span>{{$t('students.creation_date')}} </span>
                <span
                  class="deep-purple--text font-weight-bold">{{created |  moment('MM/D/YYYY')}}</span>
              </v-col>
              <v-col>
                <span>{{$t('students.last_access')}}: </span>
                <span class="deep-purple--text font-weight-bold d-block">
                  {{lastAccessDate || $t('students.without_last_access')}}
                </span>
              </v-col>
            </v-row>
            <v-divider/>
          </v-col>

          <!-- Editable data -->
          <!-- Personal information -->
          <v-row align="center" justify="center" class="pa-2">
            <v-col cols="12" md="5">
              <v-row align="start" justify="start" class="d-flex flex-column">
                <span class="body black--text my-1">{{$t('students.country')}}</span>
                <v-autocomplete :items="countries" item-value="id"
                                item-text="name" filled flat dense
                                :placeholder="$t('students.country')"
                                v-model="userToSave.country" :rules="requiredRule('country')">
                  <template v-slot:selection="{ item }">
                    <img
                      :src="`${flagsPath}${item.iso_code}.png`"
                      alt="country flag"
                      width="35vh"
                      height="25vh"
                      class="mr-2"
                    />
                    <span class="">{{ item.name }}</span>
                  </template>
                  <template v-slot:item="{ item }">
                    <template>
                      <img
                        :src="`${flagsPath}${item.iso_code}.png`"
                        alt="country flag"
                        width="35vh"
                        height="25vh"
                        class="mr-2"
                      />
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="capitalize(item.name)"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-row>
              <v-row align="start" justify="start" class="d-flex flex-column mt-3">
                <span class="body black--text my-1">{{ $t('students.dni') }}</span>
                <v-text-field
                  filled
                  flat
                  dense
                  :placeholder="$t('students.dni')"
                  :rules="dniRule"
                  v-model="userToSave.dni"
                />
              </v-row>
              <v-row align="start" justify="start" class="d-flex flex-column mt-3">
                <span class="body black--text my-1">{{ $t('students.email') }}</span>
                <v-text-field
                  filled
                  flat
                  dense
                  type="email"
                  :placeholder="$t('students.email')"
                  :rules="validEmail()"
                  v-model="userToSave.email"
                />
              </v-row>
              <v-row align="start" justify="start" class="d-flex flex-column">
                <span class="body black--text my-1">{{$t('students.student_name')}}</span>
                <v-text-field
                  filled
                  flat
                  dense
                  :placeholder="$t('students.student_name')"
                  :rules="requiredRule('firstname')"
                  v-model="userToSave.firstname"
                />
              </v-row>
              <v-row align="start" justify="start" class="d-flex flex-column">
                <span
                  class="body black--text my-1">{{$t('students.student_parent_lastname')}}</span>
                <v-text-field
                  dense
                  :placeholder="$t('students.student_parent_lastname')"
                  filled
                  flat
                  v-model="userToSave.lastname"
                  :rules="requiredRule('lastname')"
                />
              </v-row>
              <v-row align="start" justify="start" class="d-flex flex-column">
                <span
                  class="body black--text my-1">{{$t('students.student_mother_lastname')}}</span>
                <v-text-field
                  dense
                  :placeholder="$t('students.student_mother_lastname')"
                  filled
                  flat
                  v-model="userToSave.lastname2nd"
                  :rules="requiredRule('lastname2nd')"
                />
              </v-row>

              <v-row align="start" justify="start" class="d-flex flex-column">
                <span class="body black--text my-1">{{$t('students.birthdate')}}</span>
                <!-- DATE PICKER -->
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="userToSave.birthDate"
                      append-icon="mdi-calendar"
                      :placeholder="$t('students.birthdate')"
                      readonly
                      filled
                      dense
                      flat
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="userToSave.birthDate"
                    :active-picker.sync="activePicker"
                    :max="
                                      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                                        .toISOString()
                                        .substr(0, 10)
                                    "
                    min="1990-01-01"
                    :locale="$i18n.locale"
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-row>
            </v-col>
            <v-divider vertical class="mx-1"/>

            <!-- College information -->
            <v-col cols="12" md="6" style="margin-top:-180px">
              <v-row align="center" justify="center" class="d-flex flex-column">
                <span class="black--text">{{$t('students.state')}}</span>
                <v-radio-group v-model="userToSave.active" row>
                  <v-radio
                    :label="$t('students.active')"
                    :value="true"
                    color="deep-purple lighten-1"
                  />
                  <v-radio
                    :label="$t('students.inactive')"
                    :value="false"
                    color="deep-purple lighten-1"
                  />
                </v-radio-group>
              </v-row>
              <v-divider/>

              <v-col cols="12" class="pa-3">
                <v-row align="start" justify="center" class="d-flex flex-column">
                  <span class="body black--text my-1">{{$t('students.school')}}</span>
                  <v-autocomplete
                    filled
                    flat
                    dense
                    v-model="userToSave.school"
                    :items="schoolList"
                    item-value="value"
                    item-text="text"
                    :placeholder="$t('students.select_school')"
                    :rules="requiredRule('school')"
                  />
                </v-row>

                <v-row align="start" justify="center" class="d-flex flex-column">
                  <span class="body black--text my-1">{{ $t('students.grade') }}</span>
                  <v-autocomplete
                    filled
                    flat
                    dense
                    :items="grades"
                    v-model="userToSave.grade"
                    item-value="grade_id"
                    item-text="name"
                    :placeholder="$t('students.select_grade')"
                    :rules="requiredRule('grade')"
                  />
                </v-row>

                <v-row align="start" justify="center" class="d-flex flex-column">
                  <span class="body black--text my-1">{{$t('students.section')}}</span>
                  <v-autocomplete
                    filled
                    flat
                    dense
                    :items="sections"
                    v-model="userToSave.section"
                    item-value="key"
                    item-text="value"
                    :placeholder="$t('students.select_section')"
                    :rules="sectionRule"
                  />
                </v-row>
              </v-col>
            </v-col>
          </v-row>

        </v-card>
      </v-col>

      <v-col cols="12" md="3" class="h-100">
        <v-row align="center" justify="center" class="h-100">
          <v-col cols="12">
           <v-alert rounded outlined light color="deep-purple" class="pa-2 mb-3">
              <h4 class="text-left deep-purple--text lighten-2 font-weight-semibold">
                Acciones:
              </h4>
             <v-btn
                rounded
                small
                color="deep-purple lighten-1"
                dark
                @click.prevent="openChangePassword()"
              >
                {{$t('students.create_new_information')}}
              </v-btn>
            </v-alert>
            <StudentSubscriptionType :isCollege="isCollege"/>
            <StudentPaymentRegisterStatus v-if="isCollege == false" :validity="registrationProfile.validity"
                                          :register-date="registrationProfile.date"
                                          :mercado-pago-payment-id="registrationProfile.order_number"
                                          :payment-url="registrationProfile.url_mp"/>
            <StudentFamilyStatus :isAssociated="false"/>
          </v-col>
        </v-row>
      </v-col>

      <!-- MODALS FOR THE PAGE -->
      <general-modal-container @onClose="closeChangePassword()" v-if="isChangePasswordModalOpen">
        <student-change-password-modal
          :student-name="studentInfo.name"
          :student-username="studentInfo.username"
          :student-id="studentInfo.id"
          :country-id="studentInfo.country_id"
          v-model="newPassword"
          :disabled-buttons="modalLoading"
          @onSave="onSavePassword()"
          @onCancel="closeChangePassword()"

        />
      </general-modal-container>
    </v-row>
  </v-form>
</template>

<script>
import { mapState } from 'vuex';
import StudentFamilyStatus from '@/components/students/StudentFamilyStatus.vue';
import StudentPaymentRegisterStatus from '@/components/students/StudentPaymentRegisterStatus.vue';
import StudentSubscriptionType from '@/components/students/StudentSubscriptionType.vue';

// Modals
import GeneralModalContainer from '@/components/core/modals/GeneralModalContainer.vue';
import StudentChangePasswordModal from '@/components/students/StudentChangePasswordModal.vue';

import LoadingMixin from '../../mixins/loading';
import SnackbarMixin from '../../mixins/snackbar';
import userService from '../../services/user.service';
import courseGradeService from '../../services/courseGrade.service';
import sectionService from '../../services/section.service';
import schoolService from '../../services/school.service';
import registrationService from '../../services/priorRegistration.service';
import { FLAGS_URL } from '../../constants/data';

export default {
  name: 'StudentEdition',
  props: { studentId: { type: [Number, String], default: 0 } },
  components: {
    StudentFamilyStatus,
    StudentPaymentRegisterStatus,
    StudentSubscriptionType,
    GeneralModalContainer,
    StudentChangePasswordModal,
  },
  data: () => ({
    isActive: false,
    status: true,
    isChangePasswordModalOpen: false,
    activePicker: null,
    flagsPath: `${FLAGS_URL}countries_flags/`,
    date: null,
    menu: false,
    dniValidationOutputErrors: [],
    sections: [],
    grades: [],
    created: null,
    lastAccessDate: null,
    userToSave: {
      id: null,
      firstname: null,
      lastname: null,
      lastname2nd: null,
      email: null,
      dni: null,
      birthDate: null,
      country: null,
      active: true,
      school: null,
      grade: null,
      section: null,
    },
    grade_id: null,
    school_id: null,
    section: null,
    valid: true,
    registrationProfile: {},
    paymentType: null,
    newPassword: '',
    modalLoading: false,
    schoolList: [],
  }),
  computed: {
    ...mapState({
      countries: ({ common: { countries } }) => countries,
    }),
    dniRule() {
      return [
        (v) => !!v || this.$t('students.dni_required'),
        (v) => (v && v.length >= 5) || this.$t('students.dni_length_valid'),
        (v) => (/^[^\s]+$/g.test(v)) || this.$t('students.dni_with_blank_spaces'),
      ];
    },
    sectionRule() {
      return [
        (v) => (v > -1 || v !== null) || this.$t('students.section_required'),
      ];
    },
    isCollege() {
      if (this.paymentType === null) return null;
      return (this.paymentType.name === 'Pagador COLEGIO');
    },
    studentInfo() {
      return this.userToSave ? ({
        name: `${this.userToSave.firstname} ${this.userToSave.lastname}`,
        username: this.userToSave.dni,
        id: this.userToSave.id,
        country_id: this.userToSave.country,
      }) : null;
    },
  },
  async mounted() {
    this.loader();
    const { status, data } = await userService.findOne(this.studentId);
    this.sections = [...await this.findSections()];
    if (status !== 200) this.snackbarError(this.$t('students.student_not_found'));
    else {
      const user = { ...data };
      this.userToSave.birthDate = user.birthDate ? this.$moment(user.birthDate).format('YYYY-MM-DD') : '';
      this.userToSave.id = user.id;
      this.userToSave.dni = user.dni;
      this.userToSave.email = user.email;
      this.userToSave.firstname = user.firstname;
      this.userToSave.lastname = user.lastname;
      this.userToSave.lastname2nd = user.lastname2nd;
      this.userToSave.active = user.active;
      this.userToSave.country = user.country?.id;
      this.userToSave.school = user.school_id;
      this.userToSave.section = user.course?.section;
      this.userToSave.grade = user.course?.grade_id;
      this.created = user.creationDate;
      this.lastAccessDate = user.lastAccessDate ? this.$moment(user.lastAccessDate).format('HH:mm MM/D/YYYY') : null;
      this.paymentType = user.school ? user.school.payment_type : null;
      this.registrationProfile = await this.findRegistration(this.userToSave.dni);
    }
    this.loader();
  },
  methods: {
    async findSections() {
      const { status, data } = await sectionService.findAll();
      if (status !== 200) return [];
      return data;
    },
    async getSchools(country) {
      try {
        const { data, status } = await schoolService.getListByCountry(country);
        if (status === 200) this.schoolList = [...data];
        else this.schoolList = [];
      } catch (e) {
        this.snackbarError(e.message);
      }
    },
    async getGrades(country) {
      try {
        const { data, status } = await courseGradeService.findAllByCountry(country);
        if (status === 200) this.grades = [...data];
        else this.grades = [];
      } catch (e) {
        this.snackbarError(e.message);
      }
    },
    async findRegistration(dni) {
      const { status, data } = await registrationService.findOneByDNI(dni);
      if (status !== 200) return {};
      return { ...data };
    },
    async onSavePassword() {
      this.modalLoading = !this.modalLoading;
      if (this.userToSave && this.userToSave.dni) {
        const { dni, country } = this.userToSave;
        const { status } = await userService.changePassword({
          dni,
          country,
          password: this.newPassword,
        });
        if (status !== 200) this.snackbarError(this.$t('students.new_password_not_saved'));
        else this.snackbarSuccess(this.$t('students.new_password_was_set'));
      }
      this.modalLoading = !this.modalLoading;
      this.isChangePasswordModalOpen = false;
    },
    openChangePassword() {
      this.isChangePasswordModalOpen = true;
    },
    closeChangePassword() {
      this.isChangePasswordModalOpen = false;
    },
    // Method for date picker input
    async save() {
      if (this.$refs.form.validate()) {
        this.loader();
        const cleanedUser = {};
        Object.keys(this.userToSave).forEach((objKey) => {
          cleanedUser[objKey] = this.userToSave[objKey] === '' ? null : this.userToSave[objKey];
        });
        const { status } = await userService.update({ ...cleanedUser }, 'STUDENT');

        if (status !== 200) this.snackbarError(this.$t('error.user_not_saved'));
        else this.snackbarSuccess(this.$t('students.user_saved'));
        this.loader();
      }
    },
    capitalize(name) {
      return name
        .split(' ')
        .map(
          (country) => `${country.charAt(0).toUpperCase()}${country.slice(1).toLowerCase()}`,
        )
        .join(' ');
    },
    requiredRule(field) {
      return [
        (v) => !!v || this.$t(`students.${field}_required`),
      ];
    },
    validEmail() {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return [
        // (value) => (value && value.length >= 7) || this.$t('students.dni_length_valid'),
        (value) => {
          let validationResponse = true;
          if (value) {
            validationResponse = pattern.test(value) || this.$t('students.email_invalid');
          }
          return validationResponse;
        },
      ];
    },
  },
  mixins: [LoadingMixin, SnackbarMixin],
  watch: {
    date(nValue) {
      if (this.user) this.user.birthDate = nValue;
    },
    'userToSave.country': async function (nValue) {
      if (nValue) {
        await this.getGrades(nValue);
        await this.getSchools(nValue);
      }
    },
  },
};
</script>
