<template>
  <v-alert rounded outlined light color="deep-purple" class="pa-2" v-if="isCollege !== null">
    <v-row justify="start" align="start" class="pa-0">
      <v-col cols="12" class="pb-0">
        <v-card-title class="ma-0 w-100 pa-0">
          <h6 class="deep-purple--text lighten-2 font-weight-semibold">
            Tipo de suscripción
          </h6>
        </v-card-title>
      </v-col>
      <v-col cols="12" class="pt-0">
        <v-card-text>
          <v-row align="start" justify="space-between" class="w-100" v-if="isCollege">
        <span class="black--text font-weight-light">
          Colegio
        </span>
            <v-icon color="green lighten-1" size="24">
              mdi-school-outline
            </v-icon>
          </v-row>
          <v-row align="start" justify="space-around" class="w-100" v-else>
        <span class="black--text font-weight-light">
          Padre de familia
        </span>
            <v-icon color="green lighten-1" size="24">
              mdi-account-child
            </v-icon>
          </v-row>
        </v-card-text>
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
export default {
  name: 'StudentSubscriptionType',
  props: {
    isCollege: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
