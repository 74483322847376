<template>
  <v-container fluid>
    <v-data-table
      dense
      v-model="studentsSelected"
      :headers="headers"
      :items="items"
      :single-select="singleSelect"
      item-key="id"
      :loading="tableLoading"
      show-select
      class="elevation-0"
      checkbox-color="deep-purple"
      hide-default-footer
      :items-per-page="studentsPerPage"
    >
      <template v-slot:item.school="{ item }">
        <div v-if="item.school" class="d-flex justify-start align-center">
          <img v-if="item.country"
            :src="`${flagsPath}${item.country.iso_code}.png`"
            alt="country flag"
            width="35vh"
            height="25vh"
            class="mr-2"
          />
          <router-link :to="`/detail/colleges/${item.school.id}`" class="mx-1">
            <span>{{item.school.name}}</span>
          </router-link>
        </div>
      </template>
      <template v-slot:item.register_date="{ item }">
        <span v-if="item.creationDate" class="font-weight-bold">{{ item.creationDate | moment('MM/D/YYYY') }}</span>
        <span v-else class="orange--text darken-2 font-weight-bold">Vacio</span>
      </template>
      <template v-slot:item.state="{ item }">
        <span v-if="item.active"
              class="green--text font-weight-bold">{{ $t('students.active')}}</span>
        <span v-else
              class="orange--text darken-2 font-weight-bold">{{ $t('students.inactive')}}</span>
      </template>
      <template v-slot:item.edit="{ item }">
        <router-link :to="`/edit/students/${item.id}`">
          <v-icon>
            mdi-pencil-outline
          </v-icon>
        </router-link>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        @input="setValue($event)"
        :length="length"
        :value="value"
        circle
        @next="next"
        @previous="previous"
        :disabled="length<1"
        :total-visible="7"
      ></v-pagination>
    </div>
    <v-row v-if="studentsSelected.length > 0" align="center" justify="space-around"
           class="w-100 mx-auto mt-6">
      <v-btn color="success" dark rounded elevation="0"
             @click.prevent="disableOrEnableStudents(true)" :loading="loading">
        {{ $t('students.enable_user_btn') }}

      </v-btn>
      <v-btn color="orange" dark rounded elevation="0"
             @click.prevent="disableOrEnableStudents(false)" :loading="loading">
        {{ $t('students.disable_user_btn') }}
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>

import { FLAGS_URL } from '../../constants/data';
import LoadingMixin from '../../mixins/loading';
import userService from '../../services/user.service';
import userEnrollmentService from '../../services/userEnrollment.service';
import snackbarMixin from '../../mixins/snackbar';

export default {
  props: {
    items: { type: Array, default: () => ([]) },
    tableLoading: { type: Boolean, default: false },
    value: { type: [String, Number], default: 1 },
    length: { type: [String, Number], default: 1 },
  },
  data: () => ({
    singleSelect: false,
    studentsSelected: [],
    studentsPerPage: 20,
    flagsPath: `${FLAGS_URL}countries_flags/`,
  }),
  computed: {
    headers() {
      return [
        { text: 'ID', value: 'id' },
        { text: this.$t('students.firstname'), value: 'firstname' },
        { text: this.$t('students.lastname'), value: 'lastname' },
        { text: 'DNI', value: 'dni' },
        { text: this.$t('students.course'), value: 'course.name', sortable: false },
        { text: this.$t('students.school'), value: 'school', sortable: false },
        {
          text: this.$t('students.register_date'),
          value: 'register_date',
          sortable: false,
        },
        { text: this.$t('students.state'), value: 'state', sortable: false },
        { text: this.$t('students.edit'), value: 'edit', sortable: false },
      ];
    },
  },
  methods: {
    async disableOrEnableStudents(statusToSet) {
      if (!this.studentsSelected.length) {
        this.snackbarError(this.$t('error.select_must_one_student'));
        return;
      }

      this.loader();
      const { status } = await userService.updateStatus({
        status: statusToSet,
        studentIds: this.studentsSelected.map(({ id }) => (id)),
      }).catch(() => this.$t('students.users_updated'));

      // ⚠️ ¡Algo salió mal! Intenta realizar esta misma acción más tarde.
      if (status !== 200) {
        this.snackbarWarning(this.$t('error.error_on_save'));
        return;
      }

      this.studentsSelected.map(async (student) => {
        await userEnrollmentService.updateStatusByBookUserId(student.id)
      })

      this.studentsSelected.forEach((student) => {
        student.active = statusToSet;
      });
      this.studentsSelected = [];
      this.snackbarSuccess(statusToSet ? this.$t('students.users_enabled') : this.$t('students.users_disabled'));
      this.loader();
    },
    next(page) {
      this.$emit('next', page);
    },
    previous(page) {
      this.$emit('previous', page);
    },
    setValue(value) {
      this.$emit('input', value);
    },
  },
  mixins: [LoadingMixin, snackbarMixin],
};
</script>
