<template>
  <v-card
    flat
    rounded
    elevation="0"
    class="pa-6 ma-3"
    max-width="420"
    color="grey lighten-5"
    :loading="loading"
  >
    <v-card-title>
      <v-col cols="12">
        <v-row align="center" justify="center">
          <h2 class="h6 font-weight-bold grey--text ligthten-2">
            {{ _title }}
          </h2>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-row align="center" justify="center">
          <v-icon size="60" color="deep-purple lighten-1">
            mdi-alert-outline
          </v-icon>
        </v-row>
      </v-col>
    </v-card-title>

    <v-card-text>
      <v-col cols="12">
        <v-row align="center" justify="center" class="flex-md-nowrap">
          <p class="text-center">
            {{ warnText }}
          </p>
        </v-row>
      </v-col>

      <v-row align="center" justify="center" class="mt-3">
        <v-btn text small color="deep-purple lighten-2" @click="onCancel()">
          {{ $t('common.cancel') }}
        </v-btn>
        <v-btn
          rounded
          outlined
          small
          color="deep-purple lighten-2"
          @click="onDisable()"
        >
          {{ btnText }}
        </v-btn>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'DisableStudentModal',
  props: {
    status: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    titleText: { type: String, default: '' },
    messageText: { type: String, default: '' },
  },
  methods: {
    onCancel() {
      this.$emit('onCancel');
    },
    onDisable() {
      this.$emit('onDisable');
    },
  },
  computed: {
    btnText() {
      return this.status ? this.$t('common.to_enable') : this.$t('common.to_disable');
    },
    warnText() {
      return this.$t(`common.${this.status ? 'enable' : 'disable'}_message`);
    },
    _title() {
      return `${this.btnText} ${this.titleText || this.$t('common.student')}`;
    },
  },
};
</script>
