<template>
  <v-container fluid>
    <v-row align="center" justify="end" class="mx-2 mb-0">
      <v-col md="6" v-if="countryId && courses.length > 0">
        <college-course-filter
          :school-courses="courses"
          :country-id="countryId"
           @input="setCourseFilter"
           >
        </college-course-filter>
      </v-col>
      <v-col md="6">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Filtrar"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>

    <v-data-table
      :search="search"
      v-model="teachersSelected"
      :headers="headers"
      :items="teachersToShow"
      :single-select="singleSelect"
      item-key="id"
      show-select
      class="elevation-0"
      checkbox-color="deep-purple"
      :items-per-page="teachersPerPage"
      >
      <template v-slot:item.id="{ item }">
        <router-link :to="`/edit/teachers/${item.id}`">{{item.id}}</router-link>
      </template>
      <template v-slot:item.course="{ item }">
        <span>{{getCoursesByName(item.courseteachers)}}</span>
      </template>
      <template v-slot:item.state="{ item }">
        <span v-if="item.active"
              class="green--text font-weight-bold">{{$t('students.active')}}</span>
        <span v-else
              class="orange--text darken-2 font-weight-bold">{{$t('students.inactive')}}</span>
      </template>

    </v-data-table>

    <v-row v-if="teachersSelected.length > 0" align="center" justify="center" class="ma-3">
      <v-btn
        class="ma-3"
        color="deep-purple lighten-1"
        dark
        rounded
        elevation="0"
        @click.prevent="openCourseAssignModal"
      >
        Asignar Curso
      </v-btn>
      <v-btn
        class="ma-3"
        color="deep-purple lighten-1"
        dark
        rounded
        elevation="0"
        @click.prevent="openCollegeTransferModal"
      >
        Transferir de Colegio
      </v-btn>
      <v-btn
        class="ma-3"
        color="orange"
        dark
        rounded
        elevation="0"
        @click.prevent="openDisableStudentModal()"
      >
        {{ $t('students.disable_user_btn') }}
      </v-btn>
      <v-btn
        class="ma-3"
        color="success"
        dark
        rounded
        elevation="0"
        @click.prevent="openDisableStudentModal(true)"
      >
        {{ $t('students.enable_user_btn') }}
      </v-btn>
    </v-row>

    <!----- ACTION MODALS ----->
    <!-- College transfer modal -->
    <general-modal v-if="isCollegeTransferModalOpen">
      <CollegeTransferenceModal
        :country-id="countryId"
        :school-id="schoolId"
        v-model="transferCollege"
        :loading="loading"
        @onTransfer="collegeTransferStudents()" @onCancel="closeCollegeTransfer()"
      />
    </general-modal>

    <!-- College course modal -->
    <general-modal v-if="isCollegeCourseTransferModalOpen">
      <CourseTransferenceModal :country-id="countryId" v-model="transferCourse" :loading="loading"
                               @onCancel="closeCollegeCourseTransfer()"
                               @onTransfer="transferToCourse"/>
    </general-modal>

    <!-- College disable student -->
    <general-modal v-if="isDisableStudentModalOpen">
      <CollegeDisableStudentModal
        :status="statusToSet"
        :loading="loading"
        @onDisable="onDisableStudents()"
        @onCancel="closeDisableStudentModal()"
      />
    </general-modal>
  </v-container>
</template>

<script>
import CollegeCourseFilter from '@/components/colleges/courses/CollegeCourseFilter.vue';
import GeneralModal from '@/components/core/modals/GeneralModalContainer.vue';
import CollegeTransferModal from '@/components/colleges/modals/CollegeTransferenceModal.vue';
import CollegeCourseTransferModal
  from '@/components/colleges/modals/CourseTransferenceModal.vue';
import CollegeDisableStudentModal from '@/components/colleges/modals/DisableStudentModal.vue';
import LoadingMixin from '../../../mixins/loading';
import SnackbarMixin from '../../../mixins/snackbar';
import userService from '../../../services/user.service';
import userEnrollmentService from '../../../services/userEnrollment.service';
import courseService from '../../../services/course.service';
import CourseTransferenceModal from '../modals/CourseTransferenceModal';
import CollegeTransferenceModal from '../modals/CollegeTransferenceModal';
import { TRANSFER_COLLEGE_FORM, TRANSFER_COURSE_FORM } from '../../../constants/data';
import { defaultCourseSchoolFilter } from '../../../constants/filters';

export default {
  name: 'CollegeTeachersTable',
  components: {
    CollegeTransferenceModal,
    CourseTransferenceModal,
    GeneralModal,
    CollegeTransferModal,
    CollegeCourseTransferModal,
    CollegeDisableStudentModal,
    CollegeCourseFilter,
  },
  props: {
    list: { type: Array, default: () => [] },
    value: { type: [String, Number], default: '' },
    pageCount: { type: [String, Number], default: '' },
    countryId: { type: [String, Number], default: '' },
    schoolId: { type: [Number, String], default: '' },
    statusToSet: false,
    courses: { type: Array, default: () => [] },
  },
  mounted() {
    if (this.countryId) {
      this.transferCollege = {
        ...this.transferCollege,
        country: this.countryId,
      };
    }
  },
  data: () => ({
    search: '',
    singleSelect: false,
    teachersPerPage: 15,
    isDisableStudentModalOpen: false,
    isCollegeTransferModalOpen: false,
    isCollegeCourseTransferModalOpen: false,
    teachersSelected: [],
    transferCourse: { ...TRANSFER_COURSE_FORM },
    transferCollege: { ...TRANSFER_COLLEGE_FORM },
    courseFilters: { ...defaultCourseSchoolFilter },
  }),
  computed: {
    getAmountOfActiveTeachers() {
      return this.list.filter((student) => student.active == true).length;
    },
    headers() {
      return [
        { text: 'ID', value: 'id' },
        { text: this.$t('students.firstname'), value: 'firstname', sortable: true },
        { text: this.$t('students.lastname'), value: 'lastname', sortable: true },
        { text: this.$t('students.lastname2nd'), value: 'lastname2nd', sortable: true },
        { text: 'DNI', value: 'dni' },
        { text: this.$t('students.courses'), value: 'course' },
        { text: this.$t('students.state'), value: 'state' },
      ];
    },
    transferCourseParams() {
      return { ...this.transferCourse, school: this.schoolId };
    },
    teachersToShow() {
      return this.list.filter((teacher) => {
        let passFilter = true;
        if (this.courseFilters.grades.length > 0) {
          passFilter = teacher.courseteachers.some((ct) => this.courseFilters.grades.includes(ct.grade_id));
          if (this.courseFilters.sections.length > 0 && passFilter) {
            passFilter = teacher.courseteachers.some((ct) => this.courseFilters.sections.includes(ct.section));
          }
        } else if (this.courseFilters.sections.length > 0) {
          passFilter = teacher.courseteachers.some((ct) => this.courseFilters.sections.includes(ct.section));
        }
        return passFilter;
      });
    },
  },
  methods: {
    // -------------- Transfer college
    async collegeTransferStudents() {
      try {
        this.loader();
        const studentIds = this.teachersSelected.map(({ id }) => id);
        const { school, grade, section } = this.transferCourseParams
        const { collegeId } = this.$route.params

        const payload = {
          bookUserIds: studentIds,
          newCourseId: await this.getCourseInformation(school, grade, section),
          newSchoolId: collegeId,
          currentSchoolId: school,
        }

        const { status } = await userEnrollmentService.switchUserEnrollment(payload)

        if (status === 200) {
          const { status } = await userService.studentSchoolTransfer({
            ...this.transferCollege,
            studentIds,
          })

          if (status !== 200) {
            this.snackbarWarning(this.$t('error.error_on_save'));
            return
          }
          this.snackbarSuccess(this.$t('college.students_migrated'))
        } else {
          this.snackbarError(this.$t('common.an_error_ocurred'))
        }

        this.teachersSelected = [];
        this.transferCollege = { ...TRANSFER_COLLEGE_FORM };
        this.$emit('reSearch');
        this.closeCollegeTransfer();
      } catch (e) {
        this.snackbarError(e.message);
      }
      this.loader();
    },
    getCoursesByName(courses = []) {
      if (courses.length) {
        return courses.map((course) => course.name).join(', ');
      }
      return '';
    },
    openCollegeTransferModal() {
      console.log('TODO Transference Modal');
      // this.isCollegeTransferModalOpen = true;
    },
    closeCollegeTransfer() {
      this.isCollegeTransferModalOpen = false;
    },
    // -------------- Transfer college course
    openCourseAssignModal() {
      console.log('TODO Assign Course Modal');
      // this.isCollegeCourseTransferModalOpen = true;
    },
    closeCollegeCourseTransfer() {
      this.isCollegeCourseTransferModalOpen = false;
    },
    async transferToCourse() {
      this.loader();

      try {
        const studentIds = this.teachersSelected.map(({ id }) => id);

        const { school, grade, section } = this.transferCourseParams
        const { collegeId } = this.$route.params

        const payload = {
          bookUserIds: studentIds,
          newCourseId: await this.getCourseInformation(school, grade, section),
          newSchoolId: collegeId,
          currentSchoolId: school,
        }

        const { status } = await userEnrollmentService.switchUserEnrollment(payload)

        if (status === 200) {
          const { status } = await userService.studentCourseTransfer({
            ...this.transferCourseParams,
              studentIds,
            })

          if (status !== 200) {
            this.snackbarWarning(this.$t('error.error_on_save'));
            return
          }
          this.snackbarSuccess(this.$t('college.students_migrated'))
        } else {
          this.snackbarError(this.$t('common.an_error_ocurred'))
        }

        this.teachersSelected = [];
        this.transferCourse = { ...TRANSFER_COURSE_FORM };
        this.$emit('reSearch');
        this.closeCollegeCourseTransfer();
      } catch (e) {
        this.snackbarError(e.message);
      }
      this.loader();
    },
    // -------------- Disable student
    async onDisableStudents() {
      const vm = this;

      if (!this.teachersSelected.length) {
        this.snackbarError(this.$t('error.select_must_one_student'));
        return;
      }

      this.loader();
      const { status } = await userService.updateStatus({
        status: this.statusToSet,
        studentIds: this.teachersSelected.map(({ id }) => (id)),
      }).catch(() => this.$t('students.users_updated'));

      // ⚠️ ¡Algo salió mal! Intenta realizar esta misma acción más tarde.
      if (status !== 200) {
        this.snackbarWarning(this.$t('error.error_on_save'));
        return;
      }

      this.teachersSelected.forEach(async (student) => {
        student.active = vm.statusToSet;
        await userEnrollmentService.updateStatusByBookUserId(student.id)
      });
      this.teachersSelected = [];
      this.snackbarSuccess(this.statusToSet ? this.$t('students.users_enabled') : this.$t('students.users_disabled'));
      this.loader();
      this.closeDisableStudentModal();
    },
    openDisableStudentModal(statusToSet = false) {
      this.statusToSet = statusToSet;
      this.isDisableStudentModalOpen = true;
    },
    closeDisableStudentModal() {
      this.isDisableStudentModalOpen = false;
    },
    next(page) {
      this.$emit('next', page);
    },
    previous(page) {
      this.$emit('previous', page);
    },
    setValue(value) {
      this.$emit('input', value);
    },
    setCourseFilter(value) {
      this.courseFilters = value;
    },
    async getCourseInformation(school, grade, section) {
      const existingCourse = this.courses.find((course) => course.grade_id === grade && course.school_id === school && course.section === section)

      if (existingCourse) {
        return existingCourse.id
      } else {
        const { data } = await courseService.create({
          school,
          section,
          grade,
        })

        return data.id
      }
    }
  },
  mixins: [LoadingMixin, SnackbarMixin],
};
</script>

<style scoped>

</style>
