import BaseService from './base.service'

const baseService = new BaseService('enrollment/userEnrollment', process.env.VUE_APP_BEEREADERS_API_URL)

const params = {
  headers: {
    'Content-Type': 'application/json',
  },
  transformRequest: (data : any, headers: { common: { Authorization: any } }) => {
    // @ts-ignore
    delete headers.common.Authorization
    return data
  },
}

export default {
  switchUserEnrollment: (data = {}) => baseService.bpost(`switchUserEnrollment`, JSON.stringify(data), params),
  updateStatusByBookUserId: (bookUserId: number | string) => baseService.bput(`updateStatusByBookUserId?bookUserId=${bookUserId}`, JSON.stringify({}), params),
}
