<template>
  <v-container fluid>
    <v-row justify="center" class="mr-15" v-if="selected===null">
      <ReportListItem
        v-for="(report, index) in items"
        :key="index"
        :data="report"
        :index="index"
        @selected="showSelected"
      />
    </v-row>
    <v-row justify="center" class="mr-15" v-else>
      <v-col cols="12">
        <v-btn text @click="showSelected(null)">
          <v-icon class="deep-purple--text mr-2" small>
            mdi-arrow-left
          </v-icon>
          <span class="text-capitalize deep-purple--text font-weight-bold">
            {{ this.$t('navigation.reports') }}
          </span>
        </v-btn>
      </v-col>
      <PaymentsReport v-if="selected===0" @selected="showSelected" />
    </v-row>
  </v-container>
</template>

<script>
import ReportListItem from '@/components/reports/ReportListItem.vue';
import PaymentsReport from '@/components/reports/PaymentsReport.vue';

export default {
  name: 'Reports',
  components: {
    ReportListItem,
    PaymentsReport,
  },
  data: () => ({
    selected: null,
  }),
  computed: {
    items() {
      return [
        {
          title: this.$t('reports.payments_report'),
          description: this.$t('reports.payments_report_desc'),
        },
      ];
    },
  },
  methods: {
    showSelected(selected) {
      this.selected = selected;
    },
  },
};
</script>
