import axios from 'axios';

const localStore = JSON.parse(localStorage.getItem('beereaders-enrollment-admin') || '{}');
// Set config defaults when creating the instance
axios.defaults.headers.common.Authorization = `Bearer ${localStore.userToken}`;
axios.defaults.headers.common['X-Client-ID'] = process.env.VUE_APP_CONTENT_MANAGER_CLIENT_ID;

export default class BaseService {
  baseUrl = ''

  constructor(baseRoute = '', baseUri = process.env.VUE_APP_CONTENT_MANAGER_API_URL) {
    this.baseUrl = `${baseUri}${baseRoute}`;
  }

  get = (url: string, params: any = {}) => axios.get(`${this.baseUrl}/${url}`, params)

  post = (url: string, data: any = {}) => axios.post(`${this.baseUrl}/${url}`, data)

  put = (url: string, data: any = {}) => axios.put(`${this.baseUrl}/${url}`, data)

  del = (url: string, data: any = {}) => axios.delete(`${this.baseUrl}/${url}`, data)

  form = (url: string, data: any = {}) => {
    const formData = new FormData();
    formData.append('file', data);
    return axios.post(`${this.baseUrl}${url}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      transformRequest: (data, headers) => {
        // @ts-ignore
        delete headers.common.Authorization;
        return data;
      },
    });
  }

  getFile = (url: string, params: any = {}) => axios.get(`${this.baseUrl}/${url}`, { ...params, responseType: 'blob' })

  bpost = (url: string, data: any = {}, params: any = {}) => axios.post(`${this.baseUrl}/${url}`, data, params)
  bput = (url: string, data: any = {}, params: any = {}) => axios.put(`${this.baseUrl}/${url}`, data, params)
}
