<template>
  <AuthLayout content-align="start">
    <v-form ref="form" lazy-validation v-model="valid">
      <v-row align="start" justify="center" class="ma-3">
        <v-img
          src="@/assets/logos/logo.svg"
          max-width="120"
          class="ma-7"
        />
      </v-row>
      <v-row align="center" justify="center">
        <h2>{{ $t('register.title') }}</h2>
      </v-row>
      <v-row align="center" justify="center" class="mt-3 mx-12 px-12">
        <v-col cols="6">
          <v-text-field
            :placeholder="$t('register.name')"
            class="enrollment-login-input"
            single-line
            full-width
            color="gray lighten-5"
            :rules="fieldRequired"
            v-model="name"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            :placeholder="$t('register.surname')"
            class="enrollment-login-input"
            single-line
            full-width
            color="gray lighten-5"
            :rules="fieldRequired"
            v-model="surname"
          />
        </v-col>
      </v-row>
      <v-row align="center" justify="center" class="mt-3 mx-12 px-12">
        <v-col cols="12">
          <v-text-field
            :placeholder="$t('register.email')"
            class="enrollment-login-input"
            single-line
            full-width
            color="gray lighten-5"
            :rules="emailRule"
            v-model="email"
          />
        </v-col>
      </v-row>
      <v-row align="center" justify="center" class="mb-7 mx-12 px-12">
        <v-col cols="6">
          <v-text-field
            :placeholder="$t('register.password_input')"
            class="enrollment-login-input"
            single-line
            full-width
            type="password"
            :rules="passwordRule"
            v-model="password"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            :placeholder="$t('register.repeat_password_input')"
            class="enrollment-login-input"
            single-line
            full-width
            type="password"
            :rules="confirmPasswordRule"
            v-model="confirmPassword"
          />
        </v-col>
      </v-row>
      <v-row align="center" justify="center" class="mb-7 mx-12 px-12">
        <v-col cols="12">
          <v-select
            dense
            :placeholder="$t('register.school_group')"
            :items="schoolGroups"
            item-text="text"
            item-value="value"
            v-model="schoolGroupId"
            @change="schoolGroupId = $event"
            :rules="fieldRequired"
          ></v-select>
        </v-col>
      </v-row>
      <v-row justify="center">
        <div class="d-flex flex-column w-50">
          <v-btn
            rounded
            outlined
            min-width="150"
            class="text-capitalize"
            color="orange darken-3"
            @click="registerUser"
            :loading="loading"
          >
            {{ $t('login.register_btn') }}
          </v-btn>
          <v-btn
            rounded
            text
            min-width="150"
            class="text-capitalize mt-5 "
            color="orange darken-3"
            to="/login"
            :disabled="loading"
          >
            {{ $t('register.back') }}
          </v-btn>
        </div>

      </v-row>
    </v-form>
  </AuthLayout>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import schoolGroupService from '@/services/schoolGroup.service';
import AuthLayout from '../layouts/AuthLayout';
import SnackbarMixin from '../mixins/snackbar';

export default {
  name: 'RegisterView',
  components: {
    AuthLayout,
  },
  data: () => ({
    name: '',
    surname: '',
    email: '',
    password: '',
    confirmPassword: '',
    schoolGroupId: null,
    schoolGroups: [],
    valid: true,
  }),
  mixins: [SnackbarMixin],
  mounted() {
    this.getSchoolGroups();
  },
  methods: {
    ...mapActions(['register']),
    validateEmail(email) {
      return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
    },
    registerUser() {
      if (this.$refs.form.validate()) {
        this.register({
          name: this.name,
          surname: this.surname,
          email: this.email,
          password: this.password,
          schoolGroupId: this.schoolGroupId,
        });
      }
    },
    async getSchoolGroups() {
      try {
        this.schoolGroups = await schoolGroupService.findList();
      } catch (error) {
        this.snackbarError(error.message);
      }
    },
  },
  computed: {
    ...mapState({
      registerError: ({ registerError }) => registerError,
      userLogged: ({ userLogged }) => userLogged,
      loading: ({ loading }) => loading,
    }),
    fieldRequired() {
      return [(v) => !!v || this.$t('common.field_required')];
    },
    emailRule() {
      return [
        (v) => !!v || this.$t('login.email_required'),
        (v) => (v && this.validateEmail(v)) || this.$t('login.email_format_validation'),
      ];
    },
    passwordRule() {
      return [
        (v) => !!v || this.$t('login.password_required'),
        (v) => (v.length >= 8) || this.$t('register.password_length'),
      ];
    },
    confirmPasswordRule() {
      return [
        (v) => !!v || this.$t('login.password_required'),
        (v) => (v === this.password) || this.$t('register.password_not_match'),
      ];
    },
  },
  watch: {
    registerError(nValue) {
      if (nValue) this.$toast.error(this.$t(`error.${nValue.error}`));
    },
  },
};
</script>

<style scoped>

</style>
