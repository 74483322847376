<template>
  <v-container fluid>
    <h5 class="grey--text">Vista general</h5>
    <v-row>
        <v-col md="6">
            <h2 class="deep-purple--text lighten-3">{{$t('students.all_students')}}</h2>
        </v-col>
        <v-col md="6">
            <v-row justify="end" class="my-0 mr-4">
              <v-btn text small color="deep-purple lighten-1" @click.prevent="toggleCreateStudentModal()">
                <v-icon size="21" class="mr-1">
                  mdi-plus
                </v-icon>
                {{ $t('students.create') }}
              </v-btn>
            </v-row>
        </v-col>
    </v-row>
    <students-filter v-model="params" @search="search(true)"></students-filter>
    <students-data-table :table-loading="loading" v-model="page" :items="students"
                         :length="totalPages"></students-data-table>
    <create-student-modal
      v-model="isCreateStudentModalOpen"
      @onCancel="toggleCreateStudentModal"
      :isStudent="true"
    />
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import CreateStudentModal from '@/components/students/modals/CreateStudentModal.vue';
import StudentsFilter from '../../components/core/filters/StudentsFilter.vue';
import StudentsDataTable from '../../components/students/StudentsDataTable.vue';
import LoadingMixin from '../../mixins/loading';

import userService from '../../services/user.service';
import { defaultStudentFilter } from '../../constants/filters';

export default {
  name: 'Students',
  components: {
    StudentsFilter,
    StudentsDataTable,
    CreateStudentModal,
  },
  data: () => ({
    page: 1,
    params: { ...defaultStudentFilter },
    isCreateStudentModalOpen: false,
  }),
  async mounted() {
    await this.search();
  },
  computed: {
    ...mapState({
      students: ({ enrollment: { students } }) => students,
      totalPages: ({ enrollment: { totalPages } }) => totalPages,
    }),
  },
  methods: {
    ...mapActions('enrollment', ['setStudentDataTable']),
    async search(reset = false) {
      this.loader();
      if (reset) this.page = 1;
      const {
        data: {
          data,
          total_page: totalPages,
        },
        status,
      } = await userService.findAllByParameters(this.page, this.params);
      if (status === 200) {
        this.setStudentDataTable({ data, totalPages });
      }
      this.loader();
    },
    toggleCreateStudentModal() {
      this.isCreateStudentModalOpen = !this.isCreateStudentModalOpen;
    },
  },
  watch: {
    page() {
      this.search();
    },
  },
  mixins: [LoadingMixin],
};
</script>
