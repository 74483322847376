import BaseService from './base.service';

const baseService = new BaseService('school');

export default {
  findAllByParams: (page: number, params: never) => baseService.post(`findallparams?page=${page}`, params),
  findOne: (school_id: number | string) => baseService.get(`findOne/${school_id}`),
  update: (data: never) => baseService.put('update', data),
  getListByCountry: (country_id: number | string) => baseService.get(`getlistbycountry/${country_id}`),
};
