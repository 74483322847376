<template>
  <v-autocomplete :items="countries" item-value="id"
                  item-text="name"
                  :filled="filled"
                  :flat="flat"
                  :outlined="outlined"
                  :dense="dense"
                  :solo="solo"
                  :rounded="rounded"
                  :placeholder="placeholder"
                  :hide-details="hideDetails"
                  :single-line="singleLine"
                  @change="onInput"
                  color="white"
                  :value="value"
                  :rules="rules">
    <template v-slot:selection="{ item }">
      <img
        :src="`${flagsPath}${item.iso_code}.png`"
        alt="country flag"
        width="35vh"
        height="25vh"
        class="mr-2"
      />
      <span class="">{{ item.name }}</span>
    </template>
    <template v-slot:item="{ item }">
      <template>
        <img
          :src="`${flagsPath}${item.iso_code}.png`"
          alt="country flag"
          width="35vh"
          height="25vh"
          class="mr-2"
        />
        <v-list-item-content>
          <v-list-item-title
            v-html="capitalize(item.name)"
          ></v-list-item-title>
        </v-list-item-content>
      </template>
    </template>
  </v-autocomplete>
</template>

<script>
import { FLAGS_URL } from '../../../constants/data';

export default {
  name: 'CountryInput',
  props: {
    value: { type: [String, Number], default: '' },
    rules: { type: Array, default: () => [] },
    countries: { type: Array, default: () => ([]) },
    label: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    outlined: { type: Boolean, default: false },
    filled: { type: Boolean, default: true },
    flat: { type: Boolean, default: true },
    dense: { type: Boolean, default: true },
    rounded: { type: Boolean, default: true },
    solo: { type: Boolean, default: true },
    hideDetails: { type: [Boolean, String], default: 'auto' },
    singleLine: { type: Boolean, default: true },
  },
  data: () => ({
    flagsPath: `${FLAGS_URL}countries_flags/`,
  }),
  methods: {
    onInput(val) {
      this.$emit('input', val);
    },
    capitalize(name) {
      return name
        .split(' ')
        .map(
          (country) => `${country.charAt(0).toUpperCase()}${country.slice(1).toLowerCase()}`,
        )
        .join(' ');
    },
  },
};
</script>

<style scoped>

</style>
