<template>
  <v-main class="mt-0 pt-0" style="height: 100%;">
    <enrollment-header :header="appHeader" />
    <enrollment-navigation-drawer v-if="isAdmin" :items="items" />
    <router-view />
  </v-main>
</template>

<script>
import { mapState } from 'vuex';
import Header from '@/components/core/Header.vue';
import NavigationDrawer from '@/components/core/NavigationDrawer.vue';

export default {
  name: 'DashboardLayout',
  components: {
    EnrollmentHeader: Header,
    EnrollmentNavigationDrawer: NavigationDrawer,
  },
  computed: {
    ...mapState({
      currentUser: ({ currentUser }) => currentUser,
    }),
    isAdmin() {
      return this.currentUser?.roles?.includes('admin');
    },
    appHeader() {
      return this.$t('app_header.school_administration');
    },
    items() {
      return [
        { title: this.$t('navigation.colleges'), path: '/dashboard/colleges' },
        { title: this.$t('navigation.students'), path: '/dashboard/students' },
        { title: this.$t('navigation.teachers'), path: '/dashboard/teachers' },
        { title: this.$t('navigation.parents'), path: '/dashboard/parents', disabled: true },
        { title: this.$t('navigation.reports'), path: '/dashboard/reports', disabled: true },
        { title: this.$t('navigation.school_admin'), path: '/school/admin' },
      ];
    },
  },
};
</script>
