import BaseService from './base.service';

const baseService = new BaseService('massiveUserLoad', process.env.VUE_APP_BEEREADERS_API_URL);

export default {
  loadDirectivesTeachersAndStudents: (
    schoolId: number,
    excelFormat: string,
    forceChangeSchool: boolean,
    file: any,
    usersHaveDNI: boolean,
    disableOldData: boolean,
  ): any => {
    const params = `?schoolId=${schoolId}&excelFormat=${excelFormat}&forceChangeSchool=${forceChangeSchool}&usersHaveDNI=${usersHaveDNI}&disableOldData=${disableOldData}`;
    return baseService.form(`/loadDirectivesTeachersAndStudents${params}`, file);
  },
};
