<template>
  <v-container fluid>
    <h5 class="grey--text">Vista general</h5>
    <v-row>
        <v-col md="6">
            <h2 class="deep-purple--text lighten-3">{{$t('colleges.all_colleges')}}</h2>
        </v-col>
        <v-col md="6">
          <v-row justify="end" class="my-0 mr-4">
            <v-btn text small color="deep-purple lighten-1" @click.prevent="toggleCreateSchoolModal()">
              <v-icon size="21" class="mr-1">
                mdi-plus
              </v-icon>
              {{ $t('colleges.create') }}
            </v-btn>
            <v-btn text small color="deep-purple lighten-1" @click.prevent="toggleCreateSchoolGroupModal()">
              <v-icon size="21" class="mr-1">
                mdi-plus
              </v-icon>
              {{ $t('colleges.create_school_group') }}
            </v-btn>
            <!-- <v-btn text small color="deep-purple lighten-1" @click.prevent="openMassiveLoadModal()">
              <v-icon size="21" class="mr-1">
                mdi-upload
              </v-icon>
              {{ $t('colleges.massive_load') }}
            </v-btn> -->
          </v-row>
        </v-col>
    </v-row>

    <colleges-filter
      v-model="searchParams"
      :countries="countries"
      @reset="reset"
      @search="search"
    />
    <colleges-data-table
      :loading="loading"
      :schools="schools"
      :length="totalPages"
      v-model="page"
    />
    <massive-load-modal
      v-model="isMassiveLoadModalOpen"
      @onCancel="closeMassiveLoadModal"
    />
    <create-school-modal
      v-model="isCreateSchoolModalOpen"
      @onCancel="toggleCreateSchoolModal"
    />
    <create-school-group-modal
      v-model="isCreateSchoolGroupModalOpen"
      @onCancel="toggleCreateSchoolGroupModal"
    />
  </v-container>
</template>

<script>
import { defaultSchoolFilter, defaultSchoolListParams } from '@/constants/filters';
import countryService from '@/services/country.service';
import schoolService from '@/services/school.service';
import { COUNTRIES_CUSTOMER_ID } from '@/constants/data';
import CollegesFilter from '@/components/core/filters/CollegesFilter.vue';
import CollegesDataTable from '@/components/colleges/CollegesDataTable.vue';
import MassiveLoadModal from '@/components/colleges/modals/MassiveLoadModal.vue';
import CreateSchoolModal from '@/components/colleges/modals/CreateSchoolModal.vue';
import CreateSchoolGroupModal from '@/components/colleges/modals/CreateSchoolGroupModal.vue';

export default {
  name: 'Colleges',
  components: {
    CollegesFilter,
    CollegesDataTable,
    MassiveLoadModal,
    CreateSchoolModal,
    CreateSchoolGroupModal,
  },
  data: () => ({
    countries: [],
    loading: true,
    page: 1,
    totalPages: 1,
    schools: [],
    searchParams: { ...defaultSchoolFilter },
    defaultParams: { ...defaultSchoolListParams },
    isMassiveLoadModalOpen: false,
    isCreateSchoolModalOpen: false,
    isCreateSchoolGroupModalOpen: false,
  }),
  async mounted() {
    const responses = await Promise.all(
      [countryService.findAll(), schoolService.findAllByParams(this.page, this.defaultParams)],
    )
      .catch((error) => console.error(error));

    if (responses[0] && responses[0].data) {
      this.countries = responses[0].data.filter((country) => COUNTRIES_CUSTOMER_ID.includes(country.id));
    }
    if (responses[1].data && responses[1].status === 200) {
      this.schools = responses[1].data.data;
      this.totalPages = responses[1].data.total_page;
    }
    this.loading = false;
  },
  methods: {
    async reset() {
      this.loading = true;
      this.page = 1;
      const response = await schoolService.findAllByParams(this.page, this.defaultParams)
        .catch((error) => console.error(error));

      if (response.data && response.status === 200) {
        this.schools = response.data.data;
        this.totalPages = response.data.total_page;
      }
      this.loading = false;
    },
    async search(page = 1) {
      this.loading = true;
      this.page = page;
      const {
        country, id, schoolName, active,
      } = this.searchParams;
      const params = { ...this.defaultParams, active };
      if (country !== defaultSchoolFilter.country) {
        params.baseCountry_id = country;
      }
      if (id !== defaultSchoolFilter.id) {
        params.id = id;
      }
      if (schoolName !== defaultSchoolFilter.schoolName) {
        params.name = schoolName;
      }
      const response = await schoolService.findAllByParams(this.page, params)
        .catch((error) => console.error(error));

      if (response.data && response.status === 200) {
        this.schools = response.data.data;
        this.totalPages = response.data.total_page;
      }
      this.loading = false;
    },
    openMassiveLoadModal() {
      this.isMassiveLoadModalOpen = true;
    },
    closeMassiveLoadModal() {
      this.isMassiveLoadModalOpen = false;
    },
    toggleCreateSchoolModal() {
      this.isCreateSchoolModalOpen = !this.isCreateSchoolModalOpen;
    },
    toggleCreateSchoolGroupModal() {
      this.isCreateSchoolGroupModalOpen = !this.isCreateSchoolGroupModalOpen;
    },
  },
  watch: {
    page() {
      this.search(this.page);
    },
  },
};
</script>
