<template>
  <v-col cols="12" class="ma-0 pa-0">
    <v-card flat rounded :loading="loading" class="ma-0 pa-0" outlined color="transparent">
      <v-card-text class="ma-0 pa-0">
        <v-col cols="12">
          <v-row align="center" justify="start">
            <v-col cols="12" md="6" class="d-flex flex-column align-items-start">
              <span class="ma-0 pa-0">{{$t('students.grades')}} ({{grades.length}})</span>
              <v-select multiple dense outlined placeholder="Escoge un grado" :items="grades"
                        item-text="name" item-value="grade_id" @change="onInput('grades')"
                        v-model="value.grades"></v-select>
            </v-col>
            <v-col cols="12" md="6" class="d-flex flex-column align-items-start">
              <span class="ma-0 pa-0">{{$t('students.sections')}} ({{sections.length}})</span>
              <v-select multiple dense outlined placeholder="Escoge una seccion" :items="sections"
                        item-value="key" item-text="value" @change="onInput('sections')"
                        v-model="value.sections"></v-select>
            </v-col>
          </v-row>
          <!-- <v-row v-if="showButtons">
            <div class="ml-auto">
              <v-btn text color="deep-purple lighten-1" small @click.prevent="onDisable()">
                Deshabilitar Curso
              </v-btn>
              <v-btn rounded color="deep-purple lighten-1" class="white--text" small
                     @click.prevent="onSearch()"
                     :disabled="disableSearchButton">
                Buscar
              </v-btn>
            </div>
          </v-row> -->
        </v-col>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import { defaultCourseSchoolFilter } from '../../../constants/filters';
import courseGradeByCountry from '../../../services/courseGrade.service';
import sectionService from '../../../services/section.service';

export default {
  name: 'CollegeCourseFilter',
  props: {
    loading: { type: Boolean, default: false },
    countryId: { type: [Number, String], default: null },
    schoolCourses: { type: Array, default: () => [] },
  },
  data: () => ({
    grades: [],
    sections: [],
    value: { ...defaultCourseSchoolFilter },
  }),
  methods: {
    onDisable() {
      this.$emit('onDisable');
    },
    onSearch() {
      this.$emit('onSearch');
    },
    async getGrades() {
      if (this.countryId && this.schoolCourses.length > 0) {
        const { status, data } = await courseGradeByCountry.findAllByCountry(this.countryId);
        if (status === 200) {
          this.grades = data.filter((grade) => this.schoolCourses.map((sc) => sc.grade_id).includes(grade.grade_id));
        }
      }
    },
    async getSections() {
      if (this.countryId) {
        const { status, data } = await sectionService.findAll();
        if (status === 200) {
          this.sections = data.filter((section) => this.schoolCourses.map((sc) => sc.section).includes(section.key));
        }
      }
    },
    onInput(key = '') {
      const _value = { ...this.value, [key]: this.value[key] };
      this.$emit('input', _value);
    },
  },
  watch: {
    countryId: {
      immediate: true,
      async handler(newVal) {
        if (newVal) {
          await this.getGrades();
          await this.getSections();
        }
      },
    },
  },
};
</script>
