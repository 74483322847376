<template>
  <v-app>
    <router-view />
    <Snackbar></Snackbar>
    <SelectSchoolGroupModal
      v-if="showSchoolGroupDialog"
      :value="showSchoolGroupDialog"
      @onSelectSchoolGroup="closeModal"
    />
  </v-app>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Snackbar from './components/core/Snackbar.vue';
import SelectSchoolGroupModal from './components/colleges/modals/SelectSchoolGroupModal.vue';
import SnackbarMixin from './mixins/snackbar';
import USER_ROLES from './utils/user';

export default {
  name: 'App',
  components: {
    Snackbar,
    SelectSchoolGroupModal,
  },
  computed: {
    ...mapState({
      showSchoolGroupDialog: ({ showSchoolGroupDialog }) => showSchoolGroupDialog,
      currentUser: ({ currentUser }) => currentUser,
      userLogged: ({ userLogged }) => userLogged,
    }),
  },
  methods: {
    ...mapActions(['setShowSchoolGroupDialog', 'updateUser']),
    closeModal($event) {
      this.setShowSchoolGroupDialog(false);
      this.updateUser({ schoolGroupId: $event });
    },
  },
  mixins: [SnackbarMixin],
  watch: {
    currentUser: {
      handler(nValue) {
        if (this.userLogged && nValue && !nValue.email?.endsWith('@beereaders.com') && !nValue.schoolGroupId) {
          this.setShowSchoolGroupDialog(true);
        }

        if (
          this.userLogged
          && nValue
          && nValue.roles?.includes(USER_ROLES.SCHOOL_GROUP_ADMIN)
          && this.$route.path.startsWith('/login')
        ) {
          this.$router.push('/school');
          return;
        }

        if (
          this.userLogged
          && nValue
          && nValue.roles?.includes(USER_ROLES.ADMIN)
          && this.$route.path.startsWith('/login')
        ) {
          this.$router.push('/dashboard');
        }
      },
      immediate: true,
    },
  },
};
</script>
