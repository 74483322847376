import BaseService from './base.service';

const baseService = new BaseService('schoolGroup');

export default {
  findList: async () => {
    const { data, status } = await baseService.get('findList');
    if (status === 200) {
      return data;
    }
    throw new Error('Error fetching school groups');
  },
};
