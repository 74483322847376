const error = {
  ADMIN_ONLY_OPERATION: 'Admin restricted operation',
  ARGUMENT_ERROR: 'Argument error',
  APP_NOT_AUTHORIZED: 'App not authorized',
  APP_NOT_INSTALLED: 'App not installed',
  CAPTCHA_CHECK_FAILED: 'Captcha check failed',
  CODE_EXPIRED: 'Code expired',
  CORDOVA_NOT_READY: 'Cordova not ready',
  CORS_UNSUPPORTED: 'Cors unsupported',
  CREDENTIAL_ALREADY_IN_USE: 'Credential already in use',
  CREDENTIAL_MISMATCH: 'Custom token mismatch',
  CREDENTIAL_TOO_OLD_LOGIN_AGAIN: 'Requires recent login',
  DEPENDENT_SDK_INIT_BEFORE_AUTH: 'Dependent sdk initialized before auth',
  DYNAMIC_LINK_NOT_ACTIVATED: 'Dynamic link not activated',
  EMAIL_CHANGE_NEEDS_VERIFICATION: 'Email change needs verification',
  EMAIL_EXISTS: 'Email already in use, login instead',
  EMULATOR_CONFIG_FAILED: 'Emulator config failed',
  EXPIRED_OOB_CODE: 'Expired action code',
  EXPIRED_POPUP_REQUEST: 'Cancelled popup request',
  INTERNAL_ERROR: 'Internal error',
  INVALID_API_KEY: 'Invalid api key',
  INVALID_APP_CREDENTIAL: 'Invalid app credential',
  INVALID_APP_ID: 'Invalid app id',
  INVALID_AUTH: 'Invalid user token',
  INVALID_AUTH_EVENT: 'Invalid auth event',
  INVALID_CERT_HASH: 'Invalid cert hash',
  INVALID_CODE: 'Invalid verification code',
  INVALID_CONTINUE_URI: 'Invalid continue uri',
  INVALID_CORDOVA_CONFIGURATION: 'Invalid cordova configuration',
  INVALID_CUSTOM_TOKEN: 'Invalid custom token',
  INVALID_DYNAMIC_LINK_DOMAIN: 'Invalid dynamic link domain',
  INVALID_EMAIL: 'Invalid email',
  INVALID_EMULATOR_SCHEME: 'Invalid emulator scheme',
  INVALID_IDP_RESPONSE: 'Invalid credential',
  INVALID_MESSAGE_PAYLOAD: 'Invalid message payload',
  INVALID_MFA_SESSION: 'Invalid multi factor session',
  INVALID_OAUTH_CLIENT_ID: 'Invalid oauth client id',
  INVALID_OAUTH_PROVIDER: 'Invalid oauth provider',
  INVALID_OOB_CODE: 'Invalid action code',
  INVALID_ORIGIN: 'Unauthorized domain',
  INVALID_PASSWORD: 'Wrong password',
  INVALID_PERSISTENCE: 'Invalid persistence type',
  INVALID_PHONE_NUMBER: 'Invalid phone number',
  INVALID_PROVIDER_ID: 'Invalid provider id',
  INVALID_RECIPIENT_EMAIL: 'Invalid recipient email',
  INVALID_SENDER: 'Invalid sender',
  INVALID_SESSION_INFO: 'Invalid verification id',
  INVALID_TENANT_ID: 'Invalid tenant id',
  MFA_INFO_NOT_FOUND: 'Multi factor info not found',
  MFA_REQUIRED: 'Multi factor auth required',
  MISSING_ANDROID_PACKAGE_NAME: 'Missing android pkg name',
  MISSING_APP_CREDENTIAL: 'Missing app credential',
  MISSING_AUTH_DOMAIN: 'Auth domain config required',
  MISSING_CODE: 'Missing verification code',
  MISSING_CONTINUE_URI: 'Missing continue uri',
  MISSING_IFRAME_START: 'Missing iframe start',
  MISSING_IOS_BUNDLE_ID: 'Missing ios bundle id',
  MISSING_OR_INVALID_NONCE: 'Missing or invalid nonce',
  MISSING_MFA_INFO: 'Missing multi factor info',
  MISSING_MFA_SESSION: 'Missing multi factor session',
  MISSING_PHONE_NUMBER: 'Missing phone number',
  MISSING_SESSION_INFO: 'Missing verification id',
  MODULE_DESTROYED: 'App deleted',
  NEED_CONFIRMATION: 'Account exists with different credential',
  NETWORK_REQUEST_FAILED: 'Network request failed',
  NULL_USER: 'Null user',
  NO_AUTH_EVENT: 'No auth event',
  NO_SUCH_PROVIDER: 'No such provider',
  OPERATION_NOT_ALLOWED: 'Operation not allowed',
  OPERATION_NOT_SUPPORTED: 'Operation not supported in this environment',
  POPUP_BLOCKED: 'Popup blocked',
  POPUP_CLOSED_BY_USER: 'Popup closed by user',
  PROVIDER_ALREADY_LINKED: 'Provider already linked',
  QUOTA_EXCEEDED: 'Quota exceeded',
  REDIRECT_CANCELLED_BY_USER: 'Redirect cancelled by user',
  REDIRECT_OPERATION_PENDING: 'Redirect operation pending',
  REJECTED_CREDENTIAL: 'Rejected credential',
  SECOND_FACTOR_ALREADY_ENROLLED: 'Second factor already in use',
  SECOND_FACTOR_LIMIT_EXCEEDED: 'Maximum second factor count exceeded',
  TENANT_ID_MISMATCH: 'Tenant id mismatch',
  TIMEOUT: 'Timeout',
  TOKEN_EXPIRED: 'User token expired',
  TOO_MANY_ATTEMPTS_TRY_LATER: 'Too many requests',
  UNAUTHORIZED_DOMAIN: 'Unauthorized continue uri',
  UNSUPPORTED_FIRST_FACTOR: 'Unsupported first factor',
  UNSUPPORTED_PERSISTENCE: 'Unsupported persistence type',
  UNSUPPORTED_TENANT_OPERATION: 'Unsupported tenant operation',
  UNVERIFIED_EMAIL: 'Unverified email',
  USER_CANCELLED: 'User cancelled',
  USER_DELETED: 'User not found',
  USER_DISABLED: 'User disabled',
  USER_MISMATCH: 'User mismatch',
  USER_SIGNED_OUT: 'User signed out',
  WEAK_PASSWORD: 'Weak password',
  WEB_STORAGE_UNSUPPORTED: 'Web storage unsupported',
  ALREADY_INITIALIZED: 'Already initialized',
  USER_NOT_FOUND: 'User not found',
  NOT_IN_ORGANIZATION: 'User does not belong to the organization',
};

export default error;
