<template>
  <v-dialog
    v-model="value"
    persistent
    max-width="800px"
    :scrollable="errorsList !== null"
  >
    <v-card :loading="loading" :disabled="loading">
      <v-card-title>
        <v-row justify="center" class="ma-4" v-if="!errorResponse">
          <span class="text-h5" v-if="errorsList === null">{{ $t('colleges.massive_load') }}</span>
          <span class="text-h5" v-else>
            {{ `${$t('colleges.error_list')} [${evaluateErrors()}]` }}
          </span>
        </v-row>
        <v-row justify="center" class="ma-4" v-else>
          <span class="text-h5">{{ $t('common.support_text') }}</span>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-container v-if="!errorResponse">
          <v-row v-if="errorsList === null">
            <v-col cols="12" class="py-3" v-if="school.id">
              <v-text-field
                :label="$t('colleges.school_id')"
                disabled
                readonly
                outlined
                hide-details
                v-model="school.id"
              />
            </v-col>
            <v-col cols="12" class="py-3" v-if="school.id">
              <v-select
                :label="$t('colleges.payment_identifier')"
                outlined
                hide-details
                v-model="paymentIdentifier"
                :items="formatPaymentByTypeIdentifierOptions"
                :disabled="!!school.payment_by_type_id"
              />
            </v-col>
            <v-col cols="12" class="py-3">
              <v-select
                :label="$t('colleges.excel_format')"
                outlined
                hide-details
                :items="formatOptions"
                v-model="excelFormat"
                :disabled="!school.id"
              />
                <a :href="formatOptionsExamples[excelFormat]" target="_blank">
                  {{ $t('colleges.see_an_example') }}
                </a>
            </v-col>
            <!-- Do not erase, ask Chain or Ronny for context. 03-03-2022.-->
            <!-- <v-col cols="12" class="py-3">
              <v-select
                :label="$t('colleges.users_have_DNI_label')"
                outlined
                hide-details
                v-model="usersHaveDNI"
                :items="usersHaveDNIOptions"
              />
            </v-col> -->
            <v-col cols="12" class="py-3">
              <v-select
                :label="$t('colleges.dissable_old_data_label')"
                outlined
                hide-details
                v-model="disableOldData"
                :items="disableOldDataOptions"
              />
              <span class="red--text" v-if="disableOldData">
                {{ $t('colleges.massive_load_warning') }}
              </span>
            </v-col>
            <v-col cols="12" class="py-3">
              <v-file-input
                :label="$t('colleges.file')"
                :placeholder="$t('colleges.file_placeholder')"
                outlined
                hide-details
                prepend-icon
                prepend-inner-icon="$file"
                show-size
                accept=".xls, .xlsx"
                v-model="file"
              />
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col cols="12">
              <p v-for="(err, index) in errorsList" :key="index" v-html="(index + 1) + ': ' + err ">
              </p>
              <p v-if="evaluateErrors() === 0" class="text-center">
                {{ $t('colleges.successful_upload') }}
              </p>
            </v-col>
          </v-row>
        </v-container>
        <v-container v-else>
          <v-row>
            <v-col cols="12" class="py-3">
              <p class="text-center">
                {{ $t('common.support_desc') }}
              </p>
              <p class="text-center">
                {{ errorResponse }}
              </p>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-row
          justify="space-around"
          class="my-6"
          v-if="errorsList === null && errorResponse === false"
        >
          <v-btn
            rounded
            text
            color="deep-purple lighten-2"
            @click.prevent="$emit('onCancel')"
          >
            {{$t('common.cancel')}}

          </v-btn>
          <v-btn
            rounded
            outlined
            color="deep-purple lighten-2"
            :disabled="file === null"
            @click.prevent="sendForm"
          >
            {{$t('common.send')}}
          </v-btn>
        </v-row>
        <v-row justify="space-around" class="my-6" v-else>
          <v-btn
            rounded
            text
            color="deep-purple lighten-2"
            @click.prevent="close"
          >
            {{$t('common.close')}}

          </v-btn>
          <v-btn
            rounded
            outlined
            color="deep-purple lighten-2"
            @click.prevent="saveTxt"
            v-if="evaluateErrors() !== 0"
          >
            {{$t('colleges.save_as_txt')}}
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { saveAs } from 'file-saver';
import {
  formatInput,
  formatOptions,
  formatOptionsExamples,
  formatPaymentByTypeIdentifierOptions,
} from '@/constants/data/FormatInputMassiveLoad.json';
import massiveUserLoadService from '@/services/massiveUserLoad.service';

export default {
  name: 'MassiveLoadModal',
  props: {
    school: { type: Object, default: () => ({ id: 0, payment_by_type_id: null }) },
    value: { type: Boolean, default: false },
  },
  data: () => ({
    formatInput,
    formatOptions,
    formatOptionsExamples,
    formatPaymentByTypeIdentifierOptions,
    paymentIdentifier: {},
    excelFormat: 'BeeReaders',
    file: null,
    usersHaveDNI: true,
    disableOldData: false,
    loading: false,
    errorsList: null,
    errorResponse: false,
  }),
  computed: {
    usersHaveDNIOptions() {
      return [
        { value: true, text: this.$t('colleges.users_have_DNI_yes') },
        { value: false, text: this.$t('colleges.users_have_DNI_no') },
      ];
    },
    disableOldDataOptions() {
      return [
        { value: true, text: this.$t('colleges.dissable_old_data_yes') },
        { value: false, text: this.$t('colleges.dissable_old_data_no') },
      ];
    },
  },
  methods: {
    close() {
      this.errorResponse = false;
      this.file = null;
      this.errorsList = null;
      this.$emit('onCancel');
    },
    evaluateErrors() {
      let result = 0;
      if (this.errorsList !== true) {
        result = this.errorsList ? this.errorsList.length : 0;
      }
      return result;
    },
    findPaymentIdentifier(currentPaymentIdentifier) {
      if (currentPaymentIdentifier) {
        return this.formatPaymentByTypeIdentifierOptions.find(
          (item) => item.value === currentPaymentIdentifier,
        );
      }
      return null;
    },
    saveTxt() {
      const toSave = [];
      let counter = 1;
      this.errorsList.forEach((error) => {
        if (counter > 1) {
          toSave.push(`\n${counter}: ${error}`);
        } else {
          toSave.push(`${counter}: ${error}`);
        }
        counter += 1;
      });
      const blob = new Blob([toSave], { type: 'text/plain;charset=utf-8' });
      saveAs(blob, `errors_${(counter - 1)}.txt`);
    },
    async sendForm() {
      this.loading = true;
      const response = await massiveUserLoadService.loadDirectivesTeachersAndStudents(
        this.school.id,
        this.formatInput[this.excelFormat],
        true,
        this.file,
        this.usersHaveDNI,
        this.disableOldData,
      )
        .catch((error) => {
          this.errorResponse = error;
        })
        .finally(() => {
          this.loading = false;
        });
      if (!this.errorResponse) {
        let data = response.data.content;
        if (!Array.isArray(data) && data !== true) {
          data = [data];
        }
        this.errorsList = data;
      }
    },
  },
  watch: {
    value() {
      this.paymentIdentifier = this.findPaymentIdentifier(
        this.school.payment_by_type_id,
      ) || {};
      this.excelFormat = this.school.id ? 'BeeReaders' : 'Innova';
    },
  },
};
</script>
