<template>
  <v-card
    flat
    rounded
    elevation="0"
    class="pa-6 ma-3"
    max-width="420"
    color="grey lighten-5"
  >
    <v-card-title>
      <v-col cols="12">
        <v-row align="center" justify="center">
          <h2 class="h6 font-weight-bold grey--text ligthten-2">
            Deshabilitar Curso
          </h2>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-row align="center" justify="center">
          <v-icon size="60" color="deep-purple lighten-1">
            mdi-alert-outline
          </v-icon>
        </v-row>
      </v-col>
    </v-card-title>

    <v-card-text>
      <v-col cols="12">
        <v-row align="center" justify="center" class="flex-md-nowrap">
          <p>
            Al realizar esta acción podrías perder información importante del
            colegio. ¿Estás seguro de querer deshabilitar el curso?
          </p>
        </v-row>
      </v-col>

      <v-row align="center" justify="center" class="mt-3">
        <v-btn text small color="deep-purple lighten-2" @click="onCancel()">
          Cancelar
        </v-btn>
        <v-btn
          rounded
          outlined
          small
          color="deep-purple lighten-2"
          @click="onDisable()"
        >
          Deshabilitar
        </v-btn>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'DisableCourseModal',
  methods: {
    onCancel() {
      this.$emit('onCancel');
    },
    onDisable() {
      this.$emit('onDisable');
    },
  },
};
</script>
