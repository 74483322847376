import courseGradeByCountry from '../services/courseGrade.service';
import sectionService from '../services/section.service';

const courseSectionMixin = {
  data: () => ({
    grades: [],
    sections: [],
  }),
  async mounted() {
    // @ts-ignore
    await this.getGrades();
    // @ts-ignore
    await this.getSections();
  },
  methods: {
    async getGrades() {
      // @ts-ignore
      if (this.countryId) {
        // @ts-ignore
        const { status, data } = await courseGradeByCountry.findAllByCountry(this.countryId);
        if (status === 200) { // @ts-ignore
          this.grades = [...data];
        }
      }
    },
    async getSections() {
      // @ts-ignore
      if (this.countryId) {
        const { status, data } = await sectionService.findAll();
        if (status === 200) { // @ts-ignore
          this.sections = [...data];
        }
      }
    },
  },
};

export default courseSectionMixin;
