<template>
  <v-dialog v-model="value" max-width="70vw">
    <v-card :loading="loading" :disabled="loading">
      <v-card-title class="justify-center">
        <span class="text-h5 sp">{{ modalTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
         <iframe :src="retoolURL + getDetailQueryParams()" width="100%" height="500px"></iframe>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

const RETOOL_CREATE_STUDENT = 'https://beereaders.retool.com/embedded/public/d3f5c3c5-be18-4b5c-8728-7596e3bc748b';
const RETOOL_CREATE_TEACHER = 'https://beereaders.retool.com/embedded/public/31e327bb-df0c-4d5d-9198-b9bd748ea122';

export default {
  name: 'CreateStudentModal',
  props: {
    value: { type: Boolean, default: false },
    schoolId: { type: String },
    countryId: { type: String },
    isStudent: { type: Boolean, default: false },
  },
  data() {
    return {
      loading: false,
      retoolURL: this.isStudent ? RETOOL_CREATE_STUDENT : RETOOL_CREATE_TEACHER,
      modalTitle: this.isStudent ? this.$t('students.create') : this.$t('teachers.create'),
    };
  },
  methods: {
    close() {
      this.errorResponse = false;
      this.file = null;
      this.errorsList = null;
      this.$emit('onCancel');
    },
    getDetailQueryParams() {
      return this.schoolId && this.countryId
        ? `?countryId=${this.countryId}&schoolId=${this.schoolId}&onlyRead=1`
        : '';
    },
  },
};
</script>

<style scoped>
span.sp{
    font-family: "Space Grotesk" !important;
}
</style>
