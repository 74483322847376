import BaseService from './base.service'

const baseService = new BaseService('enrollment/schoolArrangement', process.env.VUE_APP_BEEREADERS_API_URL)

const params = {
  headers: {
    'Content-Type': 'application/json',
  },
  transformRequest: (data : any, headers: { common: { Authorization: any } }) => {
    // @ts-ignore
    delete headers.common.Authorization
    return data
  },
}

export default {
  findSchoolArrangementBySchoolId: (schoolId: number | string) => baseService.get(`getBySchoolId?schoolId=${schoolId}`, params),
  findSchoolArrangementBySchoolGroupId: (schoolGroupId: number | string) => baseService.get(`getBySchoolGroupId?schoolGroupId=${schoolGroupId}`, params),
  createSchoolArrangement: (data = {}) => baseService.bpost(`createSchoolArrangement`, JSON.stringify(data), params),
  updateSchoolArrangement: (data = {}) => baseService.bput(`updateSchoolArrangement`, JSON.stringify(data), params),
  upsertSchoolArrangement: (data = {}) => baseService.bpost(`upsertSchoolArrangement`, JSON.stringify(data), params)
}
