<template>
  <div style="height: 100%;" class="d-flex flex-column justify-center align-center">
    <v-progress-circular
      v-if="loading"
      indeterminate
      color="orange darken-3"
      size="100"
    ></v-progress-circular>
    <iframe
      v-show="!loading"
      @load="onLoad"
      src="https://beereaders.retool.com/embedded/public/8f6556b6-4f4b-4483-b9ed-daf95f60c8b9"
      class="retool-iframe"
    >
    </iframe>
  </div>
</template>

<script>

export default {
  name: 'AnalyticsSchoolAdminModal',
  props: {
    value: { type: Boolean, default: false },
  },
  data: () => ({
    loading: false,
    showTooltip: false,
  }),
  mounted() {
    this.loading = true;
  },
  methods: {
    onLoad() {
      setTimeout(() => {
        this.loading = false;
      }, 0);
    },
  },
};
</script>

<style scoped>
span.sp{
  font-family: "Space Grotesk" !important;
  font-weight: bolder;
}

.retool-iframe {
  border: none;
  height: 100vh;
  width: 100%;
}
</style>
