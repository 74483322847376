<template>
  <v-card flat rounded elevation="0" class="pa-6 ma-3" max-width="420" color="grey lighten-5"
          :loading="loading">
    <v-form lazy-validation v-model="valid" ref="form">
      <v-card-title>
        <v-col cols="12">
          <v-row align="center" justify="center">
            <h2 class="h6 font-weight-bold grey--text ligthten-2">
              Transferencia de curso
            </h2>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-row align="center" justify="center">
            <p class="body-2 font-weight-light grey--text ligthten-2">
              Selecciona el nuevo grado y sección destino.
            </p>
          </v-row>
        </v-col>
      </v-card-title>

      <v-card-text>
        <v-col cols="12">
          <v-row align="center" justify="center" class="flex-md-nowrap">
            <v-col cols="12" md="3">
              <span class="pa-0 ma-0">{{$t('students.grade')}}</span>
            </v-col>
            <v-autocomplete
              :placeholder="$t('common.to_select')"
              solo
              outlined
              flat
              dense
              color="white"
              light
              class="pa-0 ma-0 ml-3"
              :items="grades"
              item-text="name" item-value="grade_id"
              @input="onInput('grade')"
              v-model="value.grade"
              :rules="fieldRequired"
              hide-details="auto"
            ></v-autocomplete>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-row align="center" justify="center" class="pt-5 flex-md-nowrap">
            <v-col cols="12" md="3">
              <span class="pa-0 ma-0">{{$t('students.section')}}</span>
            </v-col>
            <v-autocomplete
              :placeholder="$t('common.to_select')"
              solo
              outlined
              flat
              dense
              color="white"
              light
              class="pa-0 ma-0 ml-3"
              :items="sections"
              @input="onInput('section')"
              v-model="value.section"
              item-value="key" item-text="value"
              :rules="sectionRequired"
              hide-details="auto"
            />
          </v-row>
        </v-col>

        <v-row align="center" justify="center" class="mt-9">
          <v-btn text small color="deep-purple lighten-2" @click="onCancel()">
            {{$t('common.cancel')}}
          </v-btn>
          <v-btn
            rounded
            outlined
            small
            color="deep-purple lighten-2"
            @click="save"
          >
            {{$t('common.to_save')}}
          </v-btn>
        </v-row>
      </v-card-text>
    </v-form>

  </v-card>

</template>

<script>
import courseSectionMixin from '../../../mixins/grade&Course';

export default {
  name: 'CourseTransferenceModal',
  props: {
    countryId: { type: [Number, String], default: '' },
    schoolId: { type: [Number, String], default: '' },
    value: { type: Object, default: () => ({}) },
    loading: { type: Boolean, default: false },
  },
  data: () => ({
    valid: true,
  }),
  computed: {
    fieldRequired() {
      return [(v) => !!v || this.$t('common.field_required')];
    },
    sectionRequired() {
      return [(v) => v !== null || this.$t('common.field_required')];
    },
  },
  methods: {
    onCancel() {
      this.$emit('onCancel');
    },
    save() {
      if (this.$refs.form.validate()) this.$emit('onTransfer');
    },
    onInput(key = '') {
      const _value = { ...this.value, [key]: this.value[key] };
      this.$emit('input', _value);
    },
  },
  mixins: [courseSectionMixin],
};
</script>
