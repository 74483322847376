var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.schools,"single-select":_vm.singleSelect,"item-key":"id","show-select":"","checkbox-color":"deep-purple","hide-default-footer":"","loading":_vm.loading,"items-per-page":_vm.schoolsPerPage},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"mx-1",attrs:{"to":("/detail/colleges/" + (item.id))}},[_c('span',[_vm._v(_vm._s(item.name))])])]}},{key:"item.baseCountryId",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticClass:"d-inline-flex mx-2",attrs:{"src":("" + _vm.flagsPath + (item.country.iso_code) + ".png"),"alt":"country flag","width":"30"}}),_c('span',[_vm._v(_vm._s(item.country.name))])]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [(item.active)?_c('span',{staticClass:"green--text font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('colleges.active'))+" ")]):_c('span',{staticClass:"orange--text darken-2 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('colleges.inactive'))+" ")])]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"mx-1",attrs:{"to":("/edit/colleges/" + (item.id))}},[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1),_c('router-link',{staticClass:"mx-1",attrs:{"to":("/detail/colleges/" + (item.id))}},[_c('span',{staticClass:"text-decoration-underline"},[_vm._v(_vm._s(_vm.$t('colleges.detail')))])])]}}]),model:{value:(_vm.collegesSelected),callback:function ($$v) {_vm.collegesSelected=$$v},expression:"collegesSelected"}}),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.length,"value":_vm.value,"circle":"","disabled":_vm.length<1,"total-visible":7},on:{"input":function($event){return _vm.setValue($event)},"next":_vm.next,"previous":_vm.previous}})],1),(_vm.collegesSelected.length)?_c('v-row',{staticClass:"pt-6",attrs:{"align":"center","justify":"center"}},[_c('v-btn',{staticClass:"mx-3",attrs:{"color":"deep-purple lighten-1","dark":"","rounded":"","elevation":"0"},on:{"click":function($event){$event.preventDefault();return _vm.migrateCollegesOfPeriod()}}},[_vm._v(" "+_vm._s(_vm.$t('colleges.migrate_period'))+" ")]),_c('v-btn',{staticClass:"mx-3",attrs:{"color":"orange","dark":"","rounded":"","elevation":"0"},on:{"click":function($event){$event.preventDefault();return _vm.disableColleges()}}},[_vm._v(" "+_vm._s(_vm.$t('colleges.disable_college'))+" ")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }