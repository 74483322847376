<template>
  <v-container fluid class="ma-0 pa-0">
    <v-col cols="12" class="ma-0 pa-0">
      <v-row align="start" justify="start" justify-md="center" class="ma-0 pa-0" v-if="schoolData.id">
        <v-col cols="12" md="8">
          <college-course-card
            :loading="loading"
            :school="schoolData"
            :counts="schoolCounts"
            :resource-type="$t('teachers.label')"
            :country-name="_country.name"
            :country-id="_country.id"
            :country-flag="`${flagsPath}${_country.iso_code}.png`"
          />
        </v-col>
        <v-col cols="12" md="4">
          <college-course-actions
            @onCreate="openCreateNewCourseModal()"
            @onUpload="openMassiveLoadModal()"
            :schoolId="schoolData.id"
            :countryId="_country.id.toString()"
            :dataForExport="teacherListAsExport"
            :csvTitle="csvTitle"
            :csvHeaders="csvHeaders"
          />
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" class="px-3">
      <v-row v-if="schoolData.id">
        <CollegeTeachersTable
          :list="teacherList"
          v-model="page"
          :courses="courses"
          :page-count="totalPage"
          :country-id="_country.id"
          :school-id="schoolData.id"
          @reSearch="reSearch"
        />
      </v-row>
    </v-col>

    <!------- ACTIONS MODALS ------->
    <!-- Create new course modal -->
    <general-modal v-if="isCreateNewCourseModalOpen">
      <college-create-new-course-modal
        @onSave="onCreateNewCourse()"
        @onCancel="closeCreateNewCourseModal()"
      />
    </general-modal>

    <!-- Disable course modal -->
    <general-modal v-if="isDisableCourseModalOpen">
      <college-disable-course-modal
        @onDisable="onDisableCourse()"
        @onCancel="closeDisableCourseModal()"
      />
    </general-modal>
  </v-container>
</template>

<script>
import CollegeCourseCard from '../../../components/colleges/courses/CollegeCourseCard.vue';
import CollegeCourseActions
  from '../../../components/colleges/courses/CollegeCourseActions.vue';
import CollegeTeachersTable
  from '../../../components/colleges/courses/CollegeTeachersTable.vue';
import GeneralModal from '../../../components/core/modals/GeneralModalContainer.vue';
import CollegeDisableCourseModal
  from '../../../components/colleges/modals/DisableCourseModal.vue';
import CollegeCreateNewCourseModal
  from '../../../components/colleges/modals/CreateNewCourseModal.vue';

import LoadingMixin from '../../../mixins/loading';
import schoolService from '../../../services/school.service';
import userService from '../../../services/user.service';
import courseService from '../../../services/course.service';
import { FLAGS_URL } from '../../../constants/data';
import { defaultCourseSchoolFilter } from '../../../constants/filters';

export default {
  name: 'CollegeTeachersDetail',
  components: {
    CollegeCourseCard,
    CollegeCourseActions,
    CollegeTeachersTable,
    GeneralModal,
    CollegeDisableCourseModal,
    CollegeCreateNewCourseModal,
  },
  async mounted() {
    this.loader();
    const { collegeId } = this.$route.params;
    this.school = collegeId;
    const { status, data } = await schoolService.findOne(collegeId);
    if (status === 200) {
      this.schoolData = { ...data };
      this.country = this.schoolData.baseCountry_id;
      this.params = { ...this.params, school: this.schoolData.id };
      this.search();
    }

    this.loader();
  },
  data: () => ({
    isDisableModalOpen: false,
    isCreateNewCourseModalOpen: false,
    isDisableCourseModalOpen: false,
    country: 0,
    school: 0,
    section: 0,
    grade: 0,
    schoolData: {},
    flagsPath: `${FLAGS_URL}countries_flags/`,
    params: { ...defaultCourseSchoolFilter },
    teacherList: [],
    teacherListAsExport: [],
    courses: [],
    csvTitle: '',
    csvHeaders: {
      id: { title: 'ID' },
      dni: { title: 'DNI' },
      firstname: { title: 'Nombre' },
      lastname: { title: 'Apellidos' },
      active: { title: 'Estado' },
      password: { title: 'Contraseña ' },
      email: { title: 'Correo' },
      username: { title: 'Nombre de usuario' },
      courses: { title: 'Cursos' },
      creationDate: { title: 'Fecha de creación' },
      birthDay: { title: 'Fecha de nacimiento' },
      lastAccessDate: { title: 'Fecha último ingreso' },
    },
    page: 1,
    totalPage: 0,
    itemsLength: 0,
  }),
  computed: {
    _country() {
      if (this.schoolData.country) return this.schoolData.country;
      return {};
    },
    schoolCounts() {
      return {
        active: this.teacherList.filter((teacher) => teacher.active == true).length,
        total: this.teacherList.length,
        totalCourses: this.courses.length,
      };
    },
  },
  methods: {
    // Create new course
    onCreateNewCourse() {
      console.log('TODO');
    },
    openCreateNewCourseModal() {
      this.isCreateNewCourseModalOpen = true;
    },
    closeCreateNewCourseModal() {
      this.isCreateNewCourseModalOpen = false;
    },
    // Disable course
    onDisableCourse() {
      console.log('TODO');
    },
    openDisableCourseModal() {
      this.isDisableCourseModalOpen = true;
    },
    closeDisableCourseModal() {
      this.isDisableCourseModalOpen = false;
    },
    async reSearch() {
      await this.search();
    },
    async search(reset = false) {
      this.loader();
      if (this.schoolData.id) {
        if (reset) this.page = 1;
        const {
          data,
          status,
        } = await userService.findAllTeachersBySchool(this.schoolData.id, this.page, true);
        if (status === 200) {
          this.teacherList = [...data];
          this.csvTitle = `${this.schoolData.id} ${this.schoolData.name} - Listado de profesores`;
          this.teacherListAsExport = this.teacherList.map((st) => {
            const teacherToExport = {};
            Object.keys(this.csvHeaders).forEach((header) => {
              teacherToExport[header] = st[header];
            });
            return {
              ...teacherToExport,
              password: st.passwordTmp,
              active: st.active ? 'Activo' : 'Inactivo',
              lastname: `${st.lastname} ${st.lastname2nd}`,
              courses: st.courseteachers.map((course) => course.name).join(', '),
            };
          });
          this.getCourses(this.schoolData.id);
        }
      }
      this.loader();
    },
    async getCourses(schoolId) {
      try {
        const { data, status } = await courseService.findAllBySchool(schoolId);
        if (status === 200) this.courses = [...data];
        else this.courses = [];
      } catch (e) {
        console.log(e);
      }
    },
  },
  mixins: [LoadingMixin],
};
</script>
