<template>
  <v-container fluid>
    <h1>Hello form Parents</h1>
  </v-container>
</template>

<script>
export default {
  name: 'Parents',
};
</script>
