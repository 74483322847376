var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"dense":"","headers":_vm.headers,"items":_vm.items,"single-select":_vm.singleSelect,"item-key":"id","loading":_vm.tableLoading,"show-select":"","checkbox-color":"deep-purple","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.school",fn:function(ref){
var item = ref.item;
return [(item.school)?_c('div',{staticClass:"d-flex justify-start align-center"},[(item.country)?_c('img',{staticClass:"mr-2",attrs:{"src":("" + _vm.flagsPath + (item.country.iso_code) + ".png"),"alt":"country flag","width":"35vh","height":"25vh"}}):_vm._e(),_c('router-link',{staticClass:"mx-1",attrs:{"to":("/detail/colleges/" + (item.school.id))}},[_c('span',[_vm._v(_vm._s(item.school.name))])])],1):_vm._e()]}},{key:"item.register_date",fn:function(ref){
var item = ref.item;
return [(item.creationDate)?_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm._f("moment")(item.creationDate,'MM/D/YYYY')))]):_c('span',{staticClass:"orange--text darken-2 font-weight-bold"},[_vm._v("Vacio")])]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [(item.active)?_c('span',{staticClass:"green--text font-weight-bold"},[_vm._v(_vm._s(_vm.$t('students.active')))]):_c('span',{staticClass:"orange--text darken-2 font-weight-bold"},[_vm._v(_vm._s(_vm.$t('students.inactive')))])]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/edit/teachers/" + (item.id))}},[_c('v-icon',[_vm._v(" mdi-pencil-outline ")])],1)]}}]),model:{value:(_vm.teachersSelected),callback:function ($$v) {_vm.teachersSelected=$$v},expression:"teachersSelected"}}),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.length,"value":_vm.value,"circle":"","disabled":_vm.length<1,"total-visible":7},on:{"input":function($event){return _vm.setValue($event)},"next":_vm.next,"previous":_vm.previous}})],1),(_vm.teachersSelected.length > 0)?_c('v-row',{staticClass:"w-100 mx-auto mt-6",attrs:{"align":"center","justify":"space-around"}},[_c('v-btn',{attrs:{"color":"success","dark":"","rounded":"","elevation":"0","loading":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.disableOrEnableStudents(true)}}},[_vm._v(" "+_vm._s(_vm.$t('students.enable_user_btn'))+" ")]),_c('v-btn',{attrs:{"color":"orange","dark":"","rounded":"","elevation":"0","loading":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.disableOrEnableStudents(false)}}},[_vm._v(" "+_vm._s(_vm.$t('students.disable_user_btn'))+" ")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }