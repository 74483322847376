<template>
  <v-col cols="12" class="ma-0 pa-0">
    <v-card flat rounded outlined :loading="loading" class="course-card">
      <v-card-text>
        <v-col cols="12">
          <v-row align="center" justify="start" class="px-3">
            <v-col cols="12" md="8">
              <v-row align="center" justify="start">
                <span>{{$t('students.school')}}:</span>
                <h3 class="ml-1 deep-purple--text font-weight-bold">
                  {{ school.name }}
                </h3>
              </v-row>
            </v-col>
            <v-col cols="12" md="4">
              <v-row align="center" justify="start">
                <span>{{$t('students.country')}}:</span>
                <img :src="countryFlag" alt="country-flag" class="flag"/>
                <span class="ml-1 deep-purple--text font-weight-bold">
                  {{ countryName }}
                </span>
              </v-row>
            </v-col>
          </v-row>
          <v-row align="center" justify="start" class="px-3">
            <v-col cols="12" md="2">
              <v-row align="center" justify="start">
                <span>ID:</span>
                <span class="ml-1 deep-purple&#45;&#45;text font-weight-bold">
                  {{school.id}}
                </span>
              </v-row>
            </v-col>
            <v-col cols="12" md="3">
              <v-row align="center" justify="start">
                <span>Suscripción:</span>
                <span class="ml-1 deep-purple&#45;&#45;text font-weight-bold">
                  {{findPaymentIdentifier(school.payment_by_type_id)}}
                </span>
              </v-row>
            </v-col>
            <v-col cols="12" md="3">
              <v-row align="center" justify="start">
                <span>SSO Google:</span>
                <span class="ml-1 deep-purple&#45;&#45;text font-weight-bold">
                  {{school.sso_enabled ? 'Activado' :'Desactivado'}}
                </span>
              </v-row>
            </v-col>
            <v-col cols="12" md="3">
              <v-row align="center" justify="start">
                <span>Año de Operación:</span>
                <span class="ml-1 deep-purple&#45;&#45;text font-weight-bold">
                  {{school.operationYear}}
                </span>
              </v-row>
            </v-col>
          </v-row>
          <v-row align="center" justify="start" class="px-3">
            <v-col cols="12" md="8" v-if="counts.active">
              <v-row align="center" justify="start">
                <span>Total de {{resourceType}}: </span>
                <span class="ml-1 deep-purple&#45;&#45;text">
                  <span class="font-weight-bold">{{counts.active}}</span> activos de
                  <span class="font-weight-bold">{{counts.total}}</span>
                </span>
              </v-row>
            </v-col>
            <v-col cols="12" md="4" v-if="counts.totalCourses">
              <v-row align="center" justify="start">
                <span>Total de cursos:</span>
                <span class="ml-1 deep-purple&#45;&#45;text font-weight-bold">
                  {{counts.totalCourses}}
                </span>
              </v-row>
            </v-col>
          </v-row>
          <v-row align="center" justify="start" class="px-3">
            <v-col cols="12" md="8" v-if="counts.active">
              <v-row align="center" justify="start">
                <a @click="toggleAnalyticsModal()">Panel de estadisticas 📊</a>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-card-text>
      <analytics-school-modal
        v-model="isAnalyticsModalOpen"
        :school="school"
      />
    </v-card>
  </v-col>
</template>

<script>
import { formatPaymentByTypeIdentifierOptions } from '@/constants/data/FormatInputMassiveLoad.json';
import AnalyticsSchoolModal from '@/components/colleges/modals/AnalyticsSchoolModal.vue';

export default {
  name: 'CollegeCourseCard',
  props: {
    countryName: { type: String, default: '' },
    countryId: { type: [Number, String], default: '' },
    countryFlag: { type: String, default: '' },
    school: { type: Object },
    loading: { type: Boolean, default: false },
    counts: { type: Object, default() { return { active: null, total: null, totalCourses: null }; } },
    resourceType: { type: String, default: '' },
  },
  data: () => ({
    isAnalyticsModalOpen: false,
  }),
  methods: {
    findPaymentIdentifier(currentPaymentIdentifier) {
      if (currentPaymentIdentifier) {
        const currentIdentifier = formatPaymentByTypeIdentifierOptions.find(
          (item) => item.value === currentPaymentIdentifier,
        );
        return currentIdentifier.text;
      }
      return null;
    },
    toggleAnalyticsModal() {
      this.isAnalyticsModalOpen = !this.isAnalyticsModalOpen;
    },
  },
  components: {
    AnalyticsSchoolModal,
  },
};
</script>
<style scoped>

.course-card{
  height: 160px;;
}

  .flag {
    max-height: 5vh;
    max-width: 7.5vw;
  }
</style>
