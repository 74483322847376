<template>
  <v-dialog
    v-model="value"
    max-width="800px"
  >
    <v-card :loading="loading" :disabled="loading">
      <v-card-title class="justify-center">
          <span class="text-h5 sp">{{ $t('colleges.create') }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
         <iframe src="https://beereaders.retool.com/embedded/public/7857eca2-6d60-45e9-a13d-870e3c23615b" width="100%" height="500px"></iframe>
        </v-container>

      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'CreateSchoolModal',
  props: {
    value: { type: Boolean, default: false },
  },
  data: () => ({
    loading: false,
  }),
  methods: {
    close() {
      this.errorResponse = false;
      this.file = null;
      this.errorsList = null;
      this.$emit('onCancel');
    },
  },
};
</script>

<style scoped>
span.sp{
    font-family: "Space Grotesk" !important;
}
</style>
